<template>
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-10"
    >
      <div class="bg-white w-full max-w-md p-6 rounded-lg shadow-lg" @click.stop>
        <div class="mb-4">
          <label class="block text-gray-900 text-lg mb-1 font-semibold">
            Does your FI loan only to individuals, or does it also loan to companies?
          </label>
          <label class="block text-gray-900 text-base mb-3 italic">
            Depending on your response, you are required to meet or commit to different Criteria.
          </label>
          <ul class="text-gray-900">
            <li
              v-for="option in options"
              :key="option"
              class="flex items-center mb-2 gap-2"
            >
              <input
                type="radio"
                v-model="selectedValue"
                :value="option"
                :id="option"
                class="h-5 w-5 text-blue-600 disabled:opacity-50 disabled:bg-gray-300 disabled:cursor-not-allowed"
              />
              <span>
                {{ option }}
              </span>
            </li>
          </ul>
        </div>
        <div class="flex justify-end items-center mt-4 gap-4">
          <button v-if="!hideCancelButtonFiIndividualCompanyModal"
            @click="closeModal"
            class="bg-[#FF3A21] text-white py-2 px-4 rounded-md hover:bg-[#FF3A21]"
          >
            Cancel
          </button>
          <button
            @click="submitSelectedModules"
            class="bg-[#201747] text-white py-2 px-4 rounded-md hover:bg-[#191b45]"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name:"TwoxModuleSelectionModal",
    props: {
      visible: Boolean,
      entityType: [String, null],
      hideCancelButtonFiIndividualCompanyModal: Boolean,
    },
    data() {
      return {
        errorMessage: "",
        selectedValue: "",
        options: ['Only individuals', 'Only companies', 'Both individuals and companies']
      };
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
      submitSelectedModules() {
        this.$emit("submit", this.selectedValue);
      },
    },
  };
  </script>
  