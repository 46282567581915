<template>
  <button
    class="group"
    @click="$emit('click', dislike === null ? true : !dislike)"
  >
    <svg
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h48v48h-48z" fill="none" />
      <path
        class="fill-[rgba(0,0,0,0)] stroke-2 stroke-[#201747] group-hover:stroke-[#0077c8]"
        :class="{
          'fill-[#201747]': dislike,
          'group-hover:stroke-[#201747]': dislike,
        }"
        d="M30
      6h-18c-1.66 0-3.08 1.01-3.68 2.44l-6.03 14.1c-.18.46-.29.95-.29
      1.46v3.83l.02.02-.02.15c0 2.21 1.79 4 4 4h12.63l-1.91
      9.14c-.04.2-.07.41-.07.63 0 .83.34 1.58.88 2.12l2.13 2.11
      13.17-13.17c.72-.73 1.17-1.73 1.17-2.83v-20c0-2.21-1.79-4-4-4zm8
      0v24h8v-24h-8z"
      />
    </svg>
  </button>
</template>
<script>
export default {
  name: "DislikeButton",
  props: {
    dislike: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  emits: ["click"],
};
</script>
<style scoped>
svg {
  width: 16px;
  height: 16px;
}
</style>
