import { render, staticRenderFns } from "./my2xThresholdsGlobal.vue?vue&type=template&id=571497ce&scoped=true"
import script from "./my2xThresholdsGlobal.vue?vue&type=script&lang=js"
export * from "./my2xThresholdsGlobal.vue?vue&type=script&lang=js"
import style0 from "./my2xThresholdsGlobal.vue?vue&type=style&index=0&id=571497ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "571497ce",
  null
  
)

export default component.exports