<template>
  <div class="flex flex-col max-h-[20%]">
    <span v-if="!clustered" class="font-bold text-2xl">{{ info?.title }}</span>
    <barChartVertical
      :chartLabels="[String(assessmentYear)]"
      style="margin-left: -33px"
      :label1="info?.surveyQuestion"
      :totalValue="[100]"
      :filledValue="[info?.value]"
    >
    </barChartVertical>
    <span class="w-[400px]">{{ info?.surveyQuestion }}</span>
  </div>
</template>

<script>
import barChartVertical from "./baseBar.vue";

export default {
  name: "barChart",
  props: {
    info: {
      type: Object,
      default: null,
    },
    certificationScoreData: {
      type: [Object, Array],
      default: null,
    },
    clustered: {
      type: Boolean,
      default: false,
    },
    assessmentYear: {
      type: [String, null],
      default: null,
    },
  },
  components: {
    barChartVertical,
  },
  data() {
    return {};
  },
};
</script>
