var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pl-1 fixed top-0 bg-[#f4f6f9]"},[_c('div',{staticClass:"chatbot w-full relative h-full"},[_c('div',{staticClass:"header bg-[#201747] text-white flex px-4 py-4"},[_vm._m(0),_c('div',{staticClass:"header-right w-3/12 flex justify-end"},[_c('chatOptions',{on:{"option-selected":_vm.handleOptionSelection}}),_c('svg',{staticClass:"cursor-pointer w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"3","stroke":"currentColor"},on:{"click":_vm.closeChat}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"m8.25 4.5 7.5 7.5-7.5 7.5"}})])],1)]),(!_vm.viewChatHistory && !_vm.viewHistory)?_c('div',{staticClass:"bg-white p-2"}):_vm._e(),_c('div',{staticClass:"chatbody sm:h-[calc(100vh-13.5rem)] h-[calc(100vh-16rem)]",class:{
        '!h-[calc(100vh-12.5rem)]': _vm.viewChatHistory,
        '!h-[calc(100vh-10rem)]': _vm.viewHistory,
      }},[(_vm.viewHistory)?_c('chatHistoryList',{attrs:{"messages":_vm.messages,"isLoadingChatResponse":_vm.isLoadingChatResponse,"expandedMessageIndex":_vm.expandedMessageIndex},on:{"toggle-sources":_vm.toggleSources,"view-history-chat":_vm.handleViewChatHistory}}):(_vm.viewChatHistory)?_c('chatHistory',{attrs:{"messages":_vm.messages,"isLoadingChatResponse":_vm.isLoadingChatResponse,"expandedMessageIndex":_vm.expandedMessageIndex,"historyDescription":_vm.chatHistoryDescription},on:{"toggle-sources":_vm.toggleSources,"back-to-history":_vm.handleBackToHistory}}):_c('div',{staticClass:"flex justify-center h-full mt-1"},[_c('div',{staticClass:"flex flex-col justify-end w-full grow shrink-0 basis-0"},[_c('div',{staticClass:"flex flex-col overflow-auto px-4"},[_c('messageList',{attrs:{"messages":_vm.messages,"isLoadingChatResponse":_vm.isLoadingChatResponse,"expandedMessageIndex":_vm.expandedMessageIndex,"show-suggestions":true},on:{"toggle-sources":_vm.toggleSources,"selected-chat-suggestion":_vm.handleChatSuggestion,"feedback":_vm.updateFeedback}})],1)])])],1),_c('div',{staticClass:"absolute bottom-0 bg-white w-full"},[_c('div',{staticClass:"flex flex-col items-center"},[_c('form',{staticClass:"w-full flex items-center",on:{"submit":function($event){$event.preventDefault();return _vm.addMessage(true)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"flex-1 p-3 rounded-md w-[85%] focus:outline-none focus:ring-0 text-sm",attrs:{"disabled":_vm.isLoadingChatResponse,"type":"text","placeholder":_vm.viewChatHistory
                ? 'Continue chatting...'
                : 'Type your question here...'},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value}}}),_c('button',{staticClass:"ml-2 p-2 text-[#201747] rounded-md",attrs:{"type":"submit"}},[_c('svg',{staticClass:"w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","fill":"currentColor"}},[_c('path',{attrs:{"d":"M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z"}})])])]),(_vm.viewChatHistory)?_c('div',{staticClass:"bg-[#201747] text-white flex justify-center py-3 w-full",on:{"click":_vm.openNewChat}},[_c('button',{staticClass:"font-semibold flex items-center"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"4.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M12 4.5v15m7.5-7.5h-15"}})]),_c('span',{staticClass:"pl-2"},[_vm._v("New Conversation")])])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-left w-[75%] px-2"},[_c('h3',{staticClass:"text-white font-bold text-md"},[_vm._v("Chat with Jessie")]),_c('p',{staticClass:"text-sm"},[_vm._v(" Ask me anything I'll always respond with a gender lens. ")])])
}]

export { render, staticRenderFns }