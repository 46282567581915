<template>
  <div class="static">
    <div class="pb-20 mx-20">
      <BreadCrumbs></BreadCrumbs>
      <div class="flex justify-between mt-6">
        <!-- TODO: Add skeleton loader-->
        <div
          class="font-montserrat text-4xl font-bold leading-[45px] text-[#2c2e35]"
        >
          {{ pageTitle }}
        </div>
        <div
          v-if="user?.investor && selectedPortfolio && tabView === 'portfolio'"
          class="cursor-pointer mt-5 mb-[5px] text-sm font-arial text-[#717686]"
          @click="showPortfolioDetails = !showPortfolioDetails"
        >
          <img src="@/assets/images/IMM/setting-grey.svg" />
        </div>
      </div>

      <!--Menu-->
      <div class="mt-4" v-if="!isPortfolioCompanyView">
        <div class="flex justify-between">
          <div class="flex gap-4 h-10">
            <router-link
              :to="
                user?.membership2xData
                  ? `/2X-Ecosystem/2XC-Membership/${user.organization._id}`
                  : '/impact-compass/create-investor-profile'
              "
            >
              <button
                class="flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#2177c1] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center"
              >
                {{
                  user?.membership2xData
                    ? "View my investor profile"
                    : "Create investor profile"
                }}
              </button>
            </router-link>
<!--            <a-->
<!--              class="flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#2177c1] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center"-->
<!--              :href="getExportUrl()"-->
<!--            >-->
<!--              Download-->
<!--              <img src="@/assets/images/download-white.svg" class="w-7 h-7" />-->
<!--            </a>-->
          </div>
        </div>
      </div>

      <!--COMPANIES-->
      <div v-if="isPortfolioCompanyView">
        <!--Menu-->
        <div class="mt-8">
          <FrequencyFilter v-model="selectedPeriod" :periods="periodsByFrequency" />
        </div>
        <div class="mt-6">
          <local-loader v-if="loading"></local-loader>
          <div v-if="user && !loading" class="flex flex-col gap-6">
            <div v-if="error" class="text-red-500">
              {{ error.message }}
            </div>

            <!--Company Cards-->
            <div
              class="grid grid-cols-1 gap-6 text-[#2c2e35]"
              v-if="investeeId"
            >
              <div class="bg-white rounded-xl shadow-md overflow-hidden">
                <div class="p-6">
                  <div class="text-2xl font-bold mb-4">
                    About the Organization
                  </div>
                  <!--About the Organization Section-->
                  <div>
                    <div class="flex gap-5 items-center">
                      <div v-if="companyProfile?.logo">
                        <img :src="companyProfile?.logo?.[0]?.content" style="width: 150px; max-width: none;" />
                      </div>
                      <div>
                        <div class="flex justify-between items-center mb-4">
                        <h3 class="text-xl font-bold">
                          {{ companyProfile?.name }}
                        </h3>
                        </div>
                        <div
                          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6"
                        >
                          <div v-if="companyProfile?.entityType">
                            <div class="text-gray-500 text-sm mb-1">
                              Entity Type
                            </div>
                            <div class="font-medium">
                              {{ companyProfile?.entityType }}
                            </div>
                          </div>

                          <div v-if="companyProfile?.sectors">
                            <div class="text-gray-500 text-sm mb-1">Sector</div>
                            <div class="font-medium">
                              {{ companyProfile?.sectors }}
                            </div>
                          </div>

                          <div v-if="companyProfile?.subSectors">
                            <div class="text-gray-500 text-sm mb-1">Sub-Sector</div>
                            <div class="font-medium">
                              {{ companyProfile?.subSectors }}
                            </div>
                          </div>

                          <div v-if="companyProfile?.countriesOfOperation">
                            <div class="text-gray-500 text-sm mb-1">
                              Countries of Operation
                            </div>
                            <div class="font-medium">
                              {{ typeof companyProfile?.countriesOfOperation === 'object' ? companyProfile?.countriesOfOperation.join(', ') : companyProfile?.countriesOfOperation }}
                            </div>
                          </div>

                          <div v-if="companyProfile?.businessOverview">
                            <div class="text-gray-500 text-sm mb-1">
                              Business Overview
                            </div>
                            <div class="font-medium" ref="businessOverviewText" :class="{'business-overview' : !isShowMore}">
                              {{ companyProfile?.businessOverview }}
                            </div>
                            <div class="flex" style="float: right" v-if="showMoreLessButton">
                              <a
                                class="font-bold"
                                style="color: #2177c1"
                                href="javascript:void(0)"
                                @click="isShowMore = !isShowMore"
                                >Read {{ isShowMore ? 'less' : 'more' }}</a
                              >
                              <img
                                src="@/assets/images/2x_certify/long-arrow-right.svg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--More about the entity-->
                    <div class="flex justify-end mt-4">
                      <button
                        class="text-blue-500 hover:text-blue-700 text-sm font-medium"
                        @click="showEntityDetails = !showEntityDetails"
                      >
                        {{
                          showEntityDetails
                            ? "Show less"
                            : "More about the entity"
                        }}
                      </button>
                    </div>

                    <!--Expanded Details-->
                    <div v-if="showEntityDetails" class="mt-4 border-t pt-4">
                      <div class="">
                        <div class="mb-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                          <div v-if="companyProfile?.website">
                            <div class="text-gray-500 text-sm mb-1">
                              Website
                            </div>
                            <div class="font-medium">
                              {{ companyProfile?.website }}
                            </div>
                          </div>

                          <div v-if="companyProfile?.linkedin">
                            <div class="text-gray-500 text-sm mb-1">
                              Linkedin
                            </div>
                            <div class="font-medium">
                              {{ companyProfile?.linkedin }}
                            </div>
                          </div>

                          <div v-if="companyProfile?.twitter">
                            <div class="text-gray-500 text-sm mb-1">X (formerly Twitter) handle</div>
                            <div class="font-medium">
                              {{ companyProfile?.twitter }}
                            </div>
                          </div>
                        </div>

                        <div class="mb-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                          <div v-if="companyProfile?.contactName">
                            <div class="text-gray-500 text-sm mb-1">
                              Contact Name
                            </div>
                            <div class="font-medium">
                              {{ companyProfile?.contactName }}
                            </div>
                          </div>

                          <div v-if="companyProfile?.contactTitle">
                            <div class="text-gray-500 text-sm mb-1">
                              Contact Title
                            </div>
                            <div class="font-medium">
                              {{ companyProfile?.contactTitle }}
                            </div>
                          </div>

                          <div v-if="companyProfile?.contactEmail">
                            <div class="text-gray-500 text-sm mb-1">
                              Contact Email
                            </div>
                            <div class="font-medium">
                              {{ companyProfile?.contactEmail }}
                            </div>
                          </div>
                        </div>

                        <div v-if="companyProfile?.theoryOfChange">
                          <div class="text-gray-500 text-sm mb-1">
                            Theory of change
                          </div>
                          <div class="font-medium">
                            {{ companyProfile?.theoryOfChange }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--From Prefs-->
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 items-stretch">
              <ModuleCard
                v-for="module in filteredModules.modules"
                :key="module"
                :user="user"
                :investeeId="investeeId ? user._id : null"
                :title="module"
                :description="getDescription(module)"
                :surveyId="getSurveyId(module)"
                :status2xCertification="status2xCertification"
                :frequency="getFrequency(module)"
                :period="selectedPeriod.period"
              ></ModuleCard>
            </div>
            <!--Due Dilligence-->
            <div v-if="user.featureFlags.includes('imm-sweef')">
              <span class="text-2xl font-bold text-[#2c2e35]"
                >Due Dilligence:</span
              >
              <div
                class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-6 my-5"
              >
                <ModuleCard
                  v-for="module in filteredModules.dueDilligenceModules"
                  :key="module"
                  :user="user"
                  :investeeId="investeeId ? user._id : null"
                  :title="module"
                  :description="getDescription(module)"
                  :surveyId="getSurveyId(module)"
                  :status2xCertification="status2xCertification"
                  :frequency="getFrequency(module)"
                  :period="selectedPeriod.period"
                ></ModuleCard>
              </div>
            </div>
            <!-- Not prefs-->
            <div class="my-5" v-if="extraModules.length > 0">
              <span class="text-2xl font-bold text-[#2c2e35]"
                >You may also be interested in:</span
              >
              <div
                class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-6 my-10"
              >
                <ModuleCard
                  v-for="module in filteredModules.extraModules"
                  :key="module"
                  :user="user"
                  :investeeId="investeeId ? user._id : null"
                  :title="module"
                  :description="getDescription(module)"
                  :surveyId="getSurveyId(module)"
                  :status2xCertification="status2xCertification"
                  :frequency="getFrequency(module)"
                  :period="selectedPeriod.period"
                ></ModuleCard>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--INVESTOR-->
      <div v-else>
        <!--Tab selector between portfolio and deals-->
        <div class="flex gap-4 border-b border-gray-200 mt-4">
          <TabView
            :tab-view="tabView"
            tab="portfolio"
            label="Portfolio"
            @click="tabView = 'portfolio'"
          />
          <TabView
            :tab-view="tabView"
            tab="deals"
            label="Deals"
            @click="tabView = 'deals'"
          />
          <TabView
            :tab-view="tabView"
            tab="surveys"
            label="Due Diligence"
            @click="tabView = 'surveys'"
          />
        </div>
        <!--Portfolio-->
        <div v-if="tabView === 'portfolio'">
          <!--Portfolios Dropdown-->
          <!--We need to refactor this into a new component-->
          <div
            class="mt-10 w-1/3 sticky top-16 backdrop-blur-sm bg-white/30 z-50"
          >
            <div class="flex flex-col space-y-4">
              <label for="portfolio" class="text-3xl font-bold text-[#21191D]"
                >Select Portfolio or Fund</label
              >
              <select
                name="portfolio"
                id="portfolio"
                v-model="selectedPortfolio"
                class="focus:outline-none cursor-pointer p-4 border border-[#E1E3E9] text-[#21191D] font-medium rounded-lg"
                @change="fetchByPortfolio"
              >
                <option value="" disabled selected>
                  Select Portfolio or Fund
                </option>
                <option
                  v-for="portfolio in investorPreferences"
                  :key="portfolio._id"
                  :value="portfolio"
                >
                  {{ portfolio.portfolioName }}
                </option>
              </select>
            </div>
          </div>

          <!--Aggregation Results-->
          <div v-if="selectedPortfolio" class="mt-10 py-4 rounded-xl">
            <h3 class="text-2xl font-bold mb-4 mt-2 text-[#21191D]">
              Aggregated Portfolio Performance
            </h3>
            <AggregatedPortfolio
              :portfolio-name="selectedPortfolio.portfolioName"
              :investor-preferences="investorPreferences"
              :status2xCertification="status2xCertification"
            />
          </div>

          <!--No Investee Details-->
          <div
            v-if="selectedPortfolio && !portfolioDetails.length"
            class="mt-10 flex flex-col w-1/4 py-3 bg-red-100 rounded-xl px-5"
          >
            There's no data available for this portfolio yet. Invite a company
            to your portfolio to get started.
            <button
              class="bg-[#191B45] text-white p-2 rounded-lg mt-5 font-semibold"
              @click="showAddInvesteesModal = true"
            >
              Invite Company
            </button>
          </div>

          <!--Add investees modal-->
          <div
            v-if="showAddInvesteesModal"
            class="fixed inset-0 flex items-center justify-center z-50"
          >
            <div class="bg-white p-6 rounded-xl shadow-lg w-1/3 z-50">
              <h2 class="text-xl font-semibold mb-4">Add New Investee</h2>
              <div class="flex flex-col gap-4">
                <div>
                  <label for="company" class="my-2 block">Company Name</label>
                  <input
                    v-model="newInvesteeCompany"
                    class="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label for="email" class="my-2 block">Email</label>
                  <input
                    v-model="newInvesteeEmail"
                    class="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label for="portfolioType" class="my-2 block"
                    >Portfolio Type</label
                  >
                  <select
                    v-model="newInvesteePortfolioType"
                    class="w-full p-2 border border-gray-300 rounded-md"
                  >
                    <option value="" disabled selected>
                      Select a portfolio type
                    </option>
                    <option value="Portfolio">Portfolio</option>
                    <option value="Alumni">Alumni</option>
                    <option value="Pipeline">Pipeline</option>
                  </select>
                </div>
              </div>
              <div class="flex justify-end mt-5">
                <button
                  @click="showAddInvesteesModal = false"
                  class="bg-gray-500 text-white px-3 py-2 rounded-md mr-2 font-semibold"
                >
                  Cancel
                </button>
                <button
                  @click="addInvestee"
                  class="bg-blue-500 text-white px-3 py-2 rounded-md font-semibold"
                >
                  Send invite
                </button>
              </div>
            </div>
            <div class="fixed inset-0 bg-black opacity-50 z-40"></div>
          </div>
          <!-- TODO: Refactor this into a new component-->
          <!--Portfolio Details modal-->
          <div
            v-if="showPortfolioDetails && selectedPortfolio"
            class="fixed inset-0 flex items-center justify-center z-50"
          >
            <div class="bg-white p-6 rounded-xl shadow-lg w-3/4 z-50 relative">
              <div
                class="absolute right-10 cursor-pointer"
                @click="showPortfolioDetails = false"
              >
                <img
                  src="@/assets/images/close-icon.svg"
                  alt="Close"
                  width="20px"
                  height="20px"
                />
              </div>
              <div class="bg-gray-100 rounded-xl mt-4 px-5">
                <div class="mt-10 py-5">
                  <div class="flex gap-20">
                    <h3 class="text-xl font-semibold mb-4">
                      Portfolio Details
                    </h3>
                  </div>
                  <!--Portfolio Name and Level-->
                  <div
                    class="flex flex-col justify-center gap-4 bg-gray-200 rounded-lg px-3 w-2/6 h-44 mb-4 shadow-md"
                  >
                    <div
                      v-if="!editPorftolioDetails"
                      class="flex flex-col justify-center gap-4"
                    >
                      <h2 class="text-lg font-semibold">
                        Name:
                        <span class="font-normal">{{
                          selectedPortfolio.portfolioName
                        }}</span>
                      </h2>
                      <h2 class="text-lg font-semibold">
                        Level:
                        <span class="font-normal">{{
                          selectedPortfolio.level
                        }}</span>
                      </h2>
                    </div>
                    <div v-else class="flex flex-col justify-center gap-2">
                      <input
                        v-model="selectedPortfolio.portfolioName"
                        class="p-2 border border-gray-300 rounded-md"
                        placeholder="Portfolio Name"
                      />
                      <select
                        v-model="selectedPortfolio.level"
                        class="p-2 border border-gray-300 rounded-md"
                      >
                        <option value="Compliance">Compliance</option>
                        <option value="Risk">Risk</option>
                        <option value="Performance">Performance</option>
                        <option value="Impact">Impact</option>
                      </select>
                    </div>
                    <!--Edit Button-->
                    <div class="flex gap-4">
                      <button
                        v-if="!editPorftolioDetails"
                        @click="editPorftolioDetails = true"
                        class="bg-[#2177c1] text-white p-2 rounded-lg mb-2 font-semibold"
                      >
                        Edit details
                      </button>
                      <button
                        v-else
                        @click="updatePortfolio"
                        class="bg-green-500 text-white p-2 rounded-lg mb-2 font-semibold"
                      >
                        Save details
                      </button>
                      <button
                        v-if="editPorftolioDetails"
                        @click="editPorftolioDetails = false"
                        class="bg-red-500 text-white p-2 rounded-lg mb-2 font-semibold"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                  <table
                    class="min-w-full bg-white border rounded-xl shadow-lg"
                  >
                    <thead class="bg-gray-200">
                      <tr>
                        <th class="py-3 px-5 border-b">Module</th>
                        <th class="py-3 px-5 border-b">Frequency</th>
                        <!--  <th class="py-3 px-5 border-b">Delete?</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="portfolio in selectedPortfolio.preferences"
                        :key="portfolio._id"
                        class="hover:bg-gray-100"
                      >
                        <td class="py-3 px-5 border-b">
                          {{ portfolio.title }}
                        </td>
                        <td class="py-3 px-5 border-b">
                          {{ portfolio.frequency }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    v-if="computeVariable().length"
                    @click="showAddModulesModal = true"
                    class="mt-4 bg-[#2177c1] text-white p-2 rounded-lg font-semibold"
                  >
                    Add new module
                  </button>
                  <!--Modules Modal-->
                  <div
                    v-if="showAddModulesModal"
                    class="fixed inset-0 flex items-center justify-center z-50"
                  >
                    <div class="bg-white p-6 rounded-xl shadow-lg w-1/3 z-50">
                      <h2 class="text-xl font-semibold mb-4">Add New Module</h2>
                      <div class="mb-4">
                        <label class="block text-gray-700">Module Name</label>
                        <select
                          v-model="newModuleName"
                          class="w-full p-2 border border-gray-300 rounded-md"
                        >
                          <option value="" disabled selected>
                            Select a module
                          </option>
                          <option
                            v-for="variable in computeVariable()"
                            :key="variable"
                            :value="variable"
                          >
                            {{ variable }}
                          </option>
                        </select>
                      </div>
                      <div class="mb-4">
                        <label class="block text-gray-700">Frequency</label>
                        <select
                          v-model="newModuleFrequency"
                          class="w-full p-2 border border-gray-300 rounded-md"
                        >
                          <option value="" disabled selected>
                            Select a frequency
                          </option>
                          <option value="Monthly">Monthly</option>
                          <option value="Quarterly">Quarterly</option>
                          <option value="Annually">Annually</option>
                        </select>
                      </div>
                      <div class="flex justify-end">
                        <button
                          @click="showAddModulesModal = false"
                          class="bg-gray-500 text-white p-2 rounded-md mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          @click="addModule"
                          class="bg-blue-500 text-white p-2 rounded-md"
                        >
                          Add Module
                        </button>
                      </div>
                    </div>
                    <div class="fixed inset-0 bg-black opacity-50 z-40"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="fixed inset-0 bg-black opacity-50 z-40"
              @click="showPortfolioDetails = false"
            ></div>
          </div>

          <!-- Portfolio Companies Table-->
          <div class="mt-4">
            <ImmPortfolioCompaniesTable
              :user="user"
              :companies="portfolioCompanies"
              @invite-company="showAddInvesteesModal = true"
            />
          </div>
        </div>

        <!--Deals-->
        <div v-if="tabView === 'deals'">
          <h3 class="text-2xl font-bold mb-4 mt-2 text-[#21191D]">
            Aggregated Deals Performance
          </h3>
          <AggregatedDealsCharts/>
          <DealsTable
            :companies="portfolioCompanies"
            @invite-company="showAddInvesteesModal = true"
          />
        </div>

        <!--Surveys-->
        <div v-if="tabView === 'surveys'">
          <div
            class="mt-10 w-full sticky top-16 backdrop-blur-sm bg-white/30 z-50"
          >
            <label for="portfolio" class="text-3xl font-bold text-[#21191D]">Set Up Your Portfolio Companies for Success</label>
            <p>
              The Due Diligence section allows you to input key data for potential investments before inviting portfolio
              companies to the platform. This helps you analyze opportunities early and set consistent reporting expectations.
            </p>
            <div class="flex flex-col space-y-4 mt-4">
              <label for="portfolio" class="text-3xl font-bold text-[#21191D]">Select Portfolio or Fund</label>
              <select
                name="portfolio"
                id="portfolio"
                v-model="selectedPortfolio"
                class="focus:outline-none cursor-pointer p-4 border border-[#E1E3E9] text-[#21191D] font-medium rounded-lg w-1/3"
                @change="fetchByPortfolio"
              >
                <option value="" disabled selected>
                  Select Portfolio or Fund
                </option>
                <option
                  v-for="portfolio in investorPreferences"
                  :key="portfolio._id"
                  :value="portfolio"
                >
                  {{ portfolio.portfolioName }}
                </option>
              </select>
            </div>
          </div>
          <PortfolioSurveys v-if="selectedPortfolio?._id" :portfolio-id="selectedPortfolio._id" :portfolio-companies="portfolioCompanies" />
        </div>

        <!--TOAST-->
        <div
          v-if="showToast"
          class="fixed top-20 right-10 bg-green-500 text-white p-4 rounded-md shadow-md transition ease-in-out"
        >
          {{ toastMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import localLoader from "@/components/local-loader.vue";
import ModuleCard from "@/components/imm-components/dashboard/ModuleCard.vue";
import {
  moduleDescriptions,
  surveyIds,
} from "@/components/imm-components/IMMhelpers";

import apiService from "@/helpers/apiServices.js";
import ImmPortfolioCompaniesTable from "@/components/imm-components/ImmPortfolioCompaniesTable.vue";
import { checkWhiteLabel } from "@/components/imm-components/white-label";
import { useUserStore } from "@/stores/user";
import AggregatedPortfolio from "@/components/imm-components/dashboard/aggregated-portfolio/AggregatedPortfolio.vue";
import DealsTable from "@/components/imm-components/dashboard/DealsTable.vue";
import PortfolioSurveys from "@/components/imm-components/dashboard/PortfolioSurveys.vue";
import TabView from "@/components/imm-components/dashboard/TabView.vue";
import AggregatedDealsCharts from "@/components/imm-components/dashboard/AggregatedDealsCharts.vue";
import { getDashboardDataExportUrl } from "@/components/imm-components/IMMhelpers.js";
import FrequencyFilter from "@/components/imm-components/dashboard/FrequencyFilter.vue";
import { apiExecute } from "@/helpers/api";

export default {
  name: "IMMDashboardPage",
  components: {
    AggregatedDealsCharts,
    ImmPortfolioCompaniesTable,
    BreadCrumbs,
    ModuleCard,
    localLoader,
    AggregatedPortfolio,
    DealsTable,
    PortfolioSurveys,
    TabView,
    FrequencyFilter,
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      user: {},
      entityType: "",
      immInvestorPreferences: [],
      allowedLevels: [],
      modulesFromPreferences: [],
      extraModules: [],
      loading: true,
      totalModules: [
        "E&S",
        "ESG",
        "Finance",
        "Climate",
        "Impact",
        "2X Certification",
        "Gender ROI™",
      ],
      selectedPortfolio: "",
      investorPreferences: [],
      portfolioDetails: [],
      portfolioCompanies: [],
      showToast: false,
      toastMessage: "",
      showAddModulesModal: false,
      newModuleName: "",
      newModuleFrequency: "",
      editPorftolioDetails: false,
      showAddInvesteesModal: false,
      newInvesteeEmail: "",
      newInvesteePortfolioType: "",
      newInvesteeCompany: "",
      showPortfolioDetails: false,
      investeeId: null,
      error: null,
      status2xCertification: "",
      title: "",
      selectedPortfolioType: "",
      selectedRows: Array(0).fill(false),
      showEntityDetails: false,
      companyProfile: null,
      teamId: null,
      tabView: "portfolio",
      selectedPeriod: {
        frequency: "All",
        period: null,
      },
      periodsByFrequency: null,
      cachedPortfolioCompanies: [],
      isShowMore: false,
      showMoreLessButton: false,
    };
  },
  async mounted() {
    // query.id is the investeeInvite.id
    const investeeId = this.$route.query.id;

    // query.teamId is ID of organization of the user with the same email as the investeeInvite
    this.teamId = this.$route.query?.teamId;

    this.updatePeriodsByFrequency();

    // InvesteeId exists, visit the page as an investor
    if (investeeId) {
      this.investeeId = investeeId;
      // User with the email address matching the investeeInvite email
      this.user = await this.fetchInvesteeUser();
      this.companyProfile = await this.fetchInvesteeCompanyProfile();
    } else {
      this.user = this.userStore.user;
      if (!this.user) {
        await this.userStore.fetchUser();
        this.user = this.userStore.user;
      }
    }
    try {
      this.teamId = this.$route.query?.teamId ?? this.user?.organization?._id;
      const response = await apiService.fetch2xCertRequest(this.teamId, 'Certification');
      this.status2xCertification = response?.data?.data?.status;
    } catch (error) {
      console.error("Error fetching 2x certification status:", error);
    }

    this.title = this.getWhiteLabel().title;

    // PREFERENCES
    if (this.isPortfolioCompanyView) {
      await this.fetchCompanyPreferences();
    } else {
      await this.fetchInvestorPreferences();
    }

    this.$store.commit("setNewBreadcrumbs", [
      { name: this.title, url: "/impact-compass/landing-page" },
      { name: "Dashboard", url: "/impact-compass/dashboard" },
    ]);
    const textContainer = this.$refs.businessOverviewText;
    this.showMoreLessButton = textContainer?.scrollHeight || 0 > textContainer?.clientHeight || 0;
  },
  methods: {
    getExportUrl(portfolioName = undefined) {
      return getDashboardDataExportUrl({
        portfolioName,
        aggregation: "company",
      });
    },
    getCompanyExportUrl(teamId) {
      return getDashboardDataExportUrl({
        teamId,
        aggregation: "survey",
      });
    },
    async fetchCompanyPreferences() {
      try {
        const url = this.investeeId
          ? `${env.apiUrl}/imm-preferences/investee-preferences?email=${this.user.email}`
          : `${env.apiUrl}/imm-preferences/investee-preferences`;

        const {
          data: {
            data: { immInvestorPreferences, allowedLevels },
          },
        } = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });

        const climateFrequency = immInvestorPreferences[0]?.preferences?.find(
          (pref) => pref.title === "Climate"
        )?.frequency;

        this.totalModules = !this.user.featureFlags?.includes("imm-sweef")
          ? this.totalModules.filter((module) => module !== "Energy")
          : climateFrequency === "Quarterly"
          ? this.totalModules.filter(
              (module) =>
                module !== "Climate" && module !== "E&S" && module !== "ESG"
            )
          : this.totalModules.filter(
              (module) =>
                module !== "Climate" &&
                module !== "E&S" &&
                module !== "ESG" &&
                module !== "Energy"
            );

        // remove 2X from sweef
        if (this.user.featureFlags.includes("imm-sweef")) {
          this.totalModules = this.totalModules.filter(
            (module) => module !== "2X Certification"
          );
        }

        this.immInvestorPreferences =
          immInvestorPreferences[0].preferences || [];

        this.modulesFromPreferences = this.totalModules.filter((module) =>
          this.immInvestorPreferences.map((pref) => pref.title).includes(module)
        );
        this.allowedLevels = allowedLevels;
        this.extraModules = this.totalModules.filter(
          (module) => !this.modulesFromPreferences.includes(module)
        );

        // add 2X and due diligence modules for sweef
        if (this.user.featureFlags.includes("imm-sweef")) {
          this.extraModules.push("2X Certification");
          this.dueDilligenceModules = [
            "Climate",
            "Environmental and Social Risks and Impacts",
            "Environmental & Social Exclusions",
          ];
        }
      } catch (error) {
        console.error("Error fetching company preferences:", error);
        this.error = {
          message: "We couldn't find your preferences. Please contact support.",
          error: true,
        };
        this.modulesFromPreferences = this.totalModules;
      } finally {
        this.loading = false;
      }
    },
    getDescription(module) {
      return moduleDescriptions[module] || "";
    },
    getSurveyId(module) {
      return surveyIds[module] || "";
    },
    getFrequency(module) {
      return (
        this.immInvestorPreferences.find((pref) => pref.title === module)
          ?.frequency || ""
      );
    },

    async fetchInvestorPreferences() {
      try {
        const {
          data: { data },
        } = await axios.get(`${env.apiUrl}/imm-preferences/`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });
        this.investorPreferences = data;
      } catch (error) {
        console.error("Error fetching investor preferences:", error);
        this.error = {
          message:
            "We couldn't fetch investor preferences. Please try again later.",
          error: true,
        };
      } finally {
        this.loading = false;
      }
    },
    async fetchByPortfolio() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `${env.apiUrl}/imm-preferences/portfolio/${this.selectedPortfolio.portfolioName}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        this.portfolioDetails = data;
        // reset the portfolio type filter when changing portfolios
        this.selectedPortfolioType = "";

        // Getting unique companies based on sent invitations
        const companies = [];
        for (let i = 0; i < data.length; i++) {
          const invitation = data[i];
          if (companies.length === 0) {
            companies.push({
              userId: invitation.userId,
              name: invitation.companyName,
              teamId: invitation.userOrgId,
              email: invitation.email,
              inviterId: invitation.inviter,
              inviterOrgId: invitation.organization,
              portfolioType: invitation.portfolioType,
              _id: invitation._id,
            });
            continue;
          }

          if (
            !companies.some(
              (company) => company.name === invitation.companyName
            )
          ) {
            companies.push({
              userId: invitation.userId,
              name: invitation.companyName,
              teamId: invitation.userOrgId,
              email: invitation.email,
              inviterId: invitation.inviter,
              inviterOrgId: invitation.organization,
              portfolioType: invitation.portfolioType,
              _id: invitation._id,
            });
          }
        }

        // update both the main and cached arrays
        this.portfolioCompanies = companies;
        this.cachedPortfolioCompanies = companies;
      } catch (error) {
        console.error("Error fetching portfolio preferences:", error);
        this.error = {
          message:
            "We couldn't fetch portfolio preferences. Please try again later.",
          error: true,
        };
      }
    },
    async saveCompanyChanges(investee) {
      try {
        await axios.put(
          `${env.apiUrl}/imm-preferences/investee-details/${investee._id}`,
          {
            portfolioType: investee.portfolioType,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        this.showToast = true;
        this.toastMessage = "Changes saved successfully";
        setTimeout(() => {
          this.showToast = false;
        }, 2000);
      } catch (error) {
        console.error("Error saving investee changes:", error);
        this.error = {
          message: "We couldn't save your changes. Please try again later.",
          error: true,
        };
      }
    },
    computeVariable() {
      // define the list of variables
      const variables = [
        "2X Certification",
        "Gender ROI™",
        "Impact",
        "ESG",
        "Climate",
        "Finance",
        "E&S",
        "EnvExclusions",
      ];
      // filter out the variables present in selectedPortfolio preferences
      const filteredVariables = variables.filter(
        (variable) =>
          !this.selectedPortfolio.preferences.some(
            (pref) => pref.title === variable
          )
      );
      // return the new array with the filtered values
      return filteredVariables;
    },
    async addModule() {
      try {
        const {
          data: { data },
        } = await axios.put(
          `${env.apiUrl}/imm-preferences/${this.selectedPortfolio._id}`,
          {
            portfolioName: this.selectedPortfolio.portfolioName,
            preferences: [
              {
                title: this.newModuleName,
                frequency: this.newModuleFrequency,
              },
            ],
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );

        this.selectedPortfolio = data;
        this.showAddModulesModal = false;
      } catch (error) {
        console.error("Error adding module:", error);
      }
    },
    async updatePortfolio() {
      try {
        const {
          data: { data },
        } = await axios.put(
          `${env.apiUrl}/imm-preferences/${this.selectedPortfolio._id}`,
          {
            portfolioName: this.selectedPortfolio.portfolioName,
            level: this.selectedPortfolio.level,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        this.selectedPortfolio = data;
        await this.fetchInvestorPreferences();
        this.editPorftolioDetails = false;
      } catch (error) {
        console.error("Error updating portfolio:", error);
      }
    },
    async addInvestee() {
      const payload = {
        emails: [this.newInvesteeEmail],
        companyName: this.newInvesteeCompany,
        portfolioName: this.selectedPortfolio.portfolioName,
      };
      if (this.newInvesteePortfolioType) {
        payload.portfolioType = this.newInvesteePortfolioType;
      }
      await axios.post(
        `${env.apiUrl}/imm-preferences/investee-invites`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        }
      );

      await this.fetchByPortfolio();
      this.showAddInvesteesModal = false;
    },
    async fetchInvesteeUser() {
      try {
        const {
          data: { user },
        } = await axios.get(
          `${env.apiUrl}/imm-preferences/user/${this.investeeId}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        return user;
      } catch (error) {
        console.error("Error fetching investee user:", error);
        this.error = {
          message:
            "We couldn't fetch the investee user. Please contact support.",
          error: true,
        };
        return null;
      }
    },
    getWhiteLabel() {
      return checkWhiteLabel(this.user.featureFlags);
    },
    async fetchInvesteeCompanyProfile() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `${env.apiUrl}/imm-preferences/company-profile-survey/${this.teamId}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        return data;
      } catch (error) {
        console.error("Error fetching company profile:", error);
        this.error = {
          message:
            "We couldn't fetch the company profile. Please contact support.",
          error: true,
        };
        return null;
      }
    },
    async updatePeriodsByFrequency() {
      try {
        this.periodsByFrequency = null;
        const data = await apiExecute("get", "/imm/dashboard/periods", {
          teamId: this.teamId,
        });
        this.periodsByFrequency = data.periods;
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    isPortfolioCompanyView() {
      return !this.user?.investor || this.investeeId;
    },
    pageTitle() {
      if (this.loading) {
        return "Loading...";
      }

      let title = `My ${this.title} Dashboard`;

      // Checking the page as an investor
      if (this.investeeId) {
        if (this.companyProfile?.name) {
          title = `${this.companyProfile?.name}'s Dashboard`;
        } else {
          title = "Portfolio Company Dashboard";
        }
      }

      return title;
    },
    filteredPortfolioDetails() {
      if (!this.selectedPortfolioType) {
        return this.portfolioDetails;
      }
      return this.portfolioDetails.filter(
        (investee) => investee.portfolioType === this.selectedPortfolioType
      );
    },
    filteredModules() {
      if (this.selectedPeriod.frequency === "All") {
        return {
          modules: this.modulesFromPreferences || [],
          extraModules: this.extraModules || [],
          dueDilligenceModules: this.dueDilligenceModules || [],
        };
      }
      return {
        modules: (this.modulesFromPreferences || []).filter((module) => {
          const moduleFrequency = this.immInvestorPreferences.find(
            (pref) => pref.title === module
          )?.frequency;
          return moduleFrequency === this.selectedPeriod.frequency;
        }),
        extraModules: (this.extraModules || []).filter((module) => {
          const moduleFrequency = this.immInvestorPreferences.find(
            (pref) => pref.title === module
          )?.frequency;
          return moduleFrequency === this.selectedPeriod.frequency;
        }),
        dueDilligenceModules: (this.dueDilligenceModules || []).filter((module) => {
          const moduleFrequency = this.immInvestorPreferences.find(
            (pref) => pref.title === module
          )?.frequency;
          return moduleFrequency === this.selectedPeriod.frequency;
        }),
      };
    },
  },
  watch: {
    // restore cached data when switching tabs
    tabView: {
      handler() {
        if (this.cachedPortfolioCompanies.length > 0) {
          this.portfolioCompanies = this.cachedPortfolioCompanies;
        }
      },
      immediate: true,
    },
    selectedPeriod: {
      handler(newVal) {
        console.log("Selected period changed", newVal);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.business-overview {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
table {
  th,
  td {
    white-space: nowrap;
    text-align: center;
  }

  td {
    vertical-align: middle;
  }

  a {
    display: inline-block;
    width: 100%;
    text-align: center;

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
.main-container {
  color: #2c2e35;

  .page-title-section {
    height: 45px;

    .page-title {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left;
      color: #2c2e35;
    }

    .page-figures {
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #717686;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }

  .time-filter-section {
    .filter-options-section {
      gap: 32px;

      .filter-options {
        height: 32px;
        background: #f4f6f9;
        border-radius: 8px;
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #9ea4ba;

        div {
          padding: 6px 12px 6px 12px;
        }

        .active {
          background: #2177c1;
          border-radius: 8px;
          color: #ffffff;
        }
      }
    }

    .download-button {
      button {
        padding: 8px 16px 8px 16px;
        border-radius: 8px;
        background-color: #2177c1;
        gap: 8px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
      }
    }
  }
}
</style>
