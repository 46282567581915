<template>
  <div class="static ecosystem-landing" ref="annotatable">
    <div id="notLoaded" v-if="!endofcreated" key="loading">
      <page-loader></page-loader>
    </div>
    <div
      class="container mx-auto pt-20 px-4 lg:px-0 pb-1"
      v-if="endofcreated && certificationScoreData"
    >
      <BreadCrumbs style="margin-top: 0"></BreadCrumbs>

      <div class="ContentWrapper w-12/12 mt-8">
        <div class="container mx-auto">
          <div
            class="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-4"
          >
            <div class="flex w-full items-center">
              <button
                class="focus:outline-none cursor-pointer p-4 rounded bg-[#F4F6F9] text-sm font-normal leading-none text-[#9EA4BA] flex items-center justify-between border border-[#E1E3E9] rounded-l-lg transition-all duration-300 outline-none"
                :class="{
                  'bg-[#2177C1] !text-white !font-semibold':
                    activeTab === 'Funds',
                }"
                @click="
                  activeTab === 'Funds'
                    ? activateTab('Company')
                    : activateTab('Funds')
                "
              >
                Funds/FIs
              </button>
              <button
                class="focus:outline-none cursor-pointer p-4 bg-[#F4F6F9] text-sm font-normal leading-none text-[#9EA4BA] flex items-center justify-between border border-[#E1E3E9] rounded-r-lg transition-all duration-300 -ml-4 outline-none"
                :class="{
                  'bg-[#2177C1] !text-white !font-semibold':
                    activeTab === 'Company',
                }"
                @click="
                  activeTab === 'Company'
                    ? activateTab('Funds')
                    : activateTab('Company')
                "
              >
                Company
              </button>
            </div>
            <div>
              <select
                class="focus:outline-none cursor-pointer w-full p-4 rounded bg-white text-sm font-medium leading-none text-[#21191D] flex items-center justify-between border border-[#E1E3E9] rounded-lg"
                v-model="selectedOption"
              >
                <option disabled value="">Please select an option</option>
                <option>Portfolio</option>
                <option>Pipeline</option>
                <option>Alumni</option>
              </select>
            </div>
            <div>
              <div class="relative flex items-center w-full">
                <input
                  type="text"
                  name="search"
                  id="search"
                  :placeholder="
                    activeTab === 'Company'
                      ? 'Search by company name'
                      : 'Search by fund name'
                  "
                  class="shadow-sm block w-full sm:text-sm border h-12 rounded-lg outline-none px-12 placeholder-[#9EA4BA]"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 absolute left-0 ml-4 text-[#9EA4BA]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
              </div>
            </div>

            <div>
              <!-- <div v-show="isOpen" class="w-72 mt-2 p-4 bg-white shadow rounded" id="dropdown">
                    </div> -->
            </div>
          </div>

          <div class="mt-8 flex">
            <TwoxTimefilterVue />
            <div class="DownloadBtn ml-auto">
              <div class="tooltip">
                <div @click="onExport()">
                  <p style="cursor: pointer; margin-top: 3px">
                    <img src="../../assets/images/DownloadBtn.png" alt="" />
                    Download XLSX
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            class="chart-box mt-8 p-8"
            v-if="
              spiderData && Array.isArray(spiderData) && spiderData.length > 0
            "
          >
            <div class="items-center">
              <div>
                <h3 class="text-2xl tracking-tighter font-bold">
                  2X Assessment Results
                </h3>
              </div>
              <div class="mt-8 flex gap-6">
                <div class="flex gap-1 text-sm font-normal items-center">
                  <div
                    style="width: 24px; height: 4px; background: #e8ad17"
                  ></div>
                  2024
                </div>
              </div>
            </div>
            <div class="spiderchartEligibleWrepper mt-8">
              <!-- spiderchart -->
              <div class="spiderchartContent" id="chart">
                <div class="z-20 relative">
                  <spiderChart
                    :iso="comapnyDetails[0]?.countryHq?.iso"
                    :paddingL="40"
                    :paddingR="40"
                    :paddingT="40"
                    :paddingB="40"
                    :details="comapnyDetails[0]"
                    :countData="countData"
                    :country="country"
                    class="z-10 overflow-visible"
                    style="position: relative; height: 100%; width: 100%"
                    :info="spiderData"
                  />
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[0]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[0]?.color +
                      '; position: absolute; left: 50%; top: 0%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[0]?.icon)"
                    />
                    <span style="font-weight: bold">{{
                      spiderSpaces[0]?.domain
                    }}</span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[1]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[1]?.color +
                      '; position: absolute; left: 80%; top: 8%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[1]?.icon)"
                    />
                    <span style="font-weight: bold">{{
                      spiderSpaces[1]?.domain
                    }}</span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[2]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[2]?.color +
                      '; position: absolute; right: 8%; top: 18%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[2]?.icon)"
                    />
                    <span style="font-weight: bold">{{
                      spiderSpaces[2]?.domain
                    }}</span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[3]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[3]?.color +
                      '; position: absolute; right: 5%; top: 30%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[3]?.icon)"
                    />
                    <span style="font-weight: bold"
                      >{{ spiderSpaces[3]?.domain.split("&")[0] }}<br />
                      <span v-if="spiderSpaces[3]?.domain?.split('&')[1]">
                        {{ " & " + spiderSpaces[3]?.domain.split("&")[1] }}
                      </span>
                    </span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[4]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[4]?.color +
                      '; position: absolute; right: 12%; top:44%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[4]?.icon)"
                    />
                    <span style="font-weight: bold">
                      {{ spiderSpaces[4]?.domain.substring(0, 17) }}<br />
                      {{ spiderSpaces[4]?.domain.substring(17) }}
                    </span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[5]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[5]?.color +
                      '; position: absolute; right: 10%; top: 63%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[5]?.icon)"
                    />
                    <span
                      style="
                        font-weight: bold;
                        display: block;
                        width: 150px;
                        word-wrap: break-word;
                      "
                      >{{ spiderSpaces[5]?.domain }}</span
                    >
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[6]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[6]?.color +
                      '; position: absolute; right: 8%; bottom: 15%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[6]?.icon)"
                    />
                    <span style="font-weight: bold">{{
                      spiderSpaces[6]?.domain
                    }}</span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[7]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[7]?.color +
                      '; position: absolute; right: 28%; bottom: 2%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[7]?.icon)"
                    />
                    <span style="font-weight: bold">{{
                      spiderSpaces[7]?.domain
                    }}</span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[8]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[8]?.color +
                      '; position: absolute; left: 35%; bottom: 0;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[8]?.icon)"
                    />
                    <span style="font-weight: bold">{{
                      spiderSpaces[8]?.domain
                    }}</span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[9]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[9]?.color +
                      '; position: absolute; left: 5%; bottom: 0%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[9]?.icon)"
                    />
                    <span style="font-weight: bold">{{
                      spiderSpaces[9]?.domain
                    }}</span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[10]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[10]?.color +
                      '; position: absolute; left: 0; bottom: 30%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[10]?.icon)"
                    />
                    <span style="font-weight: bold">{{
                      spiderSpaces[10]?.domain
                    }}</span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[11]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[11]?.color +
                      '; position: absolute; left: 0; top: 58%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[11]?.icon)"
                    />
                    <span style="font-weight: bold"
                      >{{ spiderSpaces[11]?.domain.split("&")[0] }} <br />
                      <span v-if="spiderSpaces[3]?.domain?.split('&')[1]">
                        {{ " & " + spiderSpaces[3]?.domain.split("&")[1] }}
                      </span>
                    </span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[12]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[12]?.color +
                      '; position: absolute; left: 0; top: 30%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[12]?.icon)"
                    />
                    <span style="font-weight: bold">{{
                      spiderSpaces[12]?.domain
                    }}</span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[13]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[13]?.color +
                      '; position: absolute; left: 8%; top: 17%;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[13]?.icon)"
                    />
                    <span style="font-weight: bold">{{
                      spiderSpaces[13]?.domain
                    }}</span>
                  </div>
                  <div
                    v-if="
                      spiderSpaces &&
                      Array.isArray(spiderSpaces) &&
                      spiderSpaces.length > 0 &&
                      spiderSpaces[14]
                    "
                    class="text-center"
                    :style="
                      'color: ' +
                      spiderSpaces[14]?.color +
                      '; position: absolute; left: 28%; top: 0;'
                    "
                  >
                    <img
                      class="m-auto"
                      :src="getImgUrl(spiderSpaces[14]?.icon)"
                    />
                    <span style="font-weight: bold">{{
                      spiderSpaces[14]?.domain
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <div
            class="chart-box mt-8 py-8"
            v-for="(dimension, index) in structuredData"
            :key="index"
            :style="{ display: dimension.show ? 'block' : 'none' }"
          >
            <div class="flex justify-between px-8">
              <h3 class="text-2xl tracking-tighter font-bold">
                {{ dimension.title }}
              </h3>
              <img
                v-if="dimension.isActive"
                @click="dimension.isActive = !dimension.isActive"
                src="@/assets/images/arrow-up.svg"
                class="cursor-pointer"
              />
              <img
                v-if="!dimension.isActive"
                @click="dimension.isActive = !dimension.isActive"
                src="@/assets/images/arrow-down.svg"
                class="cursor-pointer"
              />
            </div>

            <div class="flex flex-col" v-if="dimension.isActive">
              <div
                class="CriteriaTitleText p-8 flex flex-col justify-between px-8"
                style="border-bottom: 1px solid #ccc"
                v-for="(indicator, j) in dimension.indicators"
                :key="j"
                :style="{ display: indicator.hasValues ? 'block' : 'none' }"
              >
                <div
                  class="flex"
                  v-if="
                    dimension?.triangle &&
                    Array.isArray(dimension?.triangle) &&
                    dimension?.triangle.length
                  "
                >
                  <triangle-chart
                    :iso="comapnyDetails[0]?.countryHq?.iso"
                    :paddingL="40"
                    :paddingR="40"
                    :paddingT="40"
                    :paddingB="40"
                    :details="comapnyDetails[0]"
                    :countData="countData"
                    :country="country"
                    class="z-10 overflow-visible"
                    style="height: 100%; width: 100%"
                    :info="dimension?.triangle"
                  />
                </div>
                <div
                  class="text-black-90 font-arial tracking-normal text-[20px] font-bold leading-[28px] flex justify-between w-full"
                >
                  <p>{{ indicator.title }}</p>
                  <img
                    v-if="indicator.isActive"
                    @click="indicator.isActive = !indicator.isActive"
                    src="@/assets/images/arrow-up.svg"
                    class="cursor-pointer"
                  />
                  <img
                    v-if="!indicator.isActive"
                    @click="indicator.isActive = !indicator.isActive"
                    src="@/assets/images/arrow-down.svg"
                    class="cursor-pointer"
                  />
                </div>
                <div class="flex flex-col" v-if="!indicator.isActive">
                  <div class="flex w-full items-center justify-end my-[.8rem]">
                    <img
                      class="cursor-pointer"
                      src="@/assets/images/2x_certify/Button.svg"
                      @click="
                        $router.push({
                          path: `/2X-Ecosystem/2X-Certification/sub-dimension?q=${encodeURIComponent(
                            indicator.title
                          )}`,
                        })
                      "
                    />
                  </div>
                  <div class="flex flex-wrap gap-4 grid-cols-2">
                    <dynamic-render
                      v-for="(item, k) in indicator?.values"
                      :key="k"
                      :type="item.chartType"
                      :info="item"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <twox-portfolio-grid-table :investees="investeesData" />
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
const axios = require("axios");
import moment from 'moment';
import Cookies from "@/helpers/cookies";
import env from "@/../env";
import pageLoader from "@/components/page-loader.vue";
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import html2pdf from "html2pdf.js";
import apiService from "@/helpers/apiServices.js";
import dynamicRender from "./my2xCertificationFullAssessmentResult/dynamic-render.vue";

// import item from "@/components/text.vue";
import { Recogito } from "@recogito/recogito-js";
import "@recogito/recogito-js/dist/recogito.min.css";
import spiderChart from "./my2xCertificationFullAssessmentResult/spiderChart.vue";
// import UploadDocumentsTable from "@/components/2x-certification/upload-documents-table.vue";
import triangleChart from "./my2xCertificationFullAssessmentResult/triangleChart.vue";
import TwoxPortfolioGridTable from "../../components/2xPortfolio/twox-portfolio-grid-table.vue";
import TwoxTimefilterVue from "../../components/2xPortfolio/twox-timefilter.vue";
import { useUserStore } from "@/stores/user";
import { SUBSCRIPTION_TYPE } from "@/security/subscriptionTypes";
import * as XLSX from "xlsx-js-style";

export default {
  name: "my2xNewPortfolio",
  data: function () {
    return {
      selectedOption: "",
      twoXCertified: false,
      twoXScoreOverall: 0,
      triangle: [],
      spiderSpaces: [],
      spiderData: [],
      entityCode: "",
      sectorData: null,
      countries: [],
      q: "",
      countryData: null,
      profile: null,
      selectedCountries: [],
      countrySearchPlaceholder: "",
      filteredCountries: [],
      assessmentYear: [],
      openClosCountryDropdawn: false,
      languagePref: "&language=en",
      organizationDetail: [],
      displayNewSharedPopup: false,
      requestAdminModal: false,
      requestportfoliopermissionModal: false,
      sharedAssesmentData: [],
      current_step: 1,
      certification_result: {
        name: "Best in Class",
        darkColor: "#4DC9BE",
        lightColor: "#E5FAFA",
      },
      cert_categories: {
        Good: {
          name: "Good",
          darkColor: "#FF501C",
          lightColor: "#FFECE8",
        },
        Advanced: {
          name: "Advanced",
          darkColor: "#E8AD17",
          lightColor: "#FFF8E0",
        },
        "Best in Class": {
          name: "Best in Class",
          darkColor: "#4DC9BE",
          lightColor: "#E5FAFA",
        },
      },
      dimensions: {
        Entrepreneurship: {
          color: "#2574BB",
          icon: "@/assets/images/2x_certify/entrepreneurship.svg",
          score: 0,
          threshold: 100,
        },
        Leadership: {
          color: "#4A742C",
          icon: "@/assets/images/2x_certify/leadership.svg",
          score: 0,
          threshold: 100,
        },
        Employment: {
          color: "#F05323",
          icon: "@/assets/images/2x_certify/employment.svg",
          score: 0,
          threshold: 100,
        },
        Products: {
          color: "#9A2B15",
          icon: "@/assets/images/2x_certify/products.svg",
          score: 0,
          threshold: 100,
        },
        SupplyChain: {
          color: "#201747",
          icon: "@/assets/images/2x_certify/supplychain.svg",
          score: 0,
          threshold: 100,
        },
        Governance: {
          color: "#E5B449",
          icon: "@/assets/images/2x_certify/governance.svg",
          score: 0,
          threshold: 100,
        },
        Portfolio: {
          color: "#7C7C7C",
          icon: "@/assets/images/2x_certify/portfolio.svg",
          score: 0,
          threshold: 100,
        },
      },
      colors: [
        {
          code: "Entrepreneurship",
          color: "#2574BB",
          icon: "@/assets/images/2x_certify/entrepreneurship.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Leadership",
          color: "#4A742C",
          icon: "@/assets/images/2x_certify/leadership.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Employment",
          color: "#F05323",
          icon: "@/assets/images/2x_certify/employment.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Products",
          color: "#9A2B15",
          icon: "@/assets/images/2x_certify/products.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Supply Chain",
          color: "#201747",
          icon: "@/assets/images/2x_certify/supplychain.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Governance",
          color: "#E5B449",
          icon: "@/assets/images/2x_certify/governance.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Portfolio",
          color: "#7C7C7C",
          icon: "@/assets/images/2x_certify/portfolio.svg",
          score: 0,
          threshold: 100,
        },
      ],
      governanceColors: {
        strategicAction: "#4A742C",
        managementSystems: "#2574BB",
        data: "#F05323",
      },
      domainChartData: [],
      entrepreneurshipPercent: "",
      leadershipPercent: "",
      employmentPercent: "",
      productServicePercent: "",
      supplyChainPercent: "",
      portfolioPercent: "",
      govStrategicAction: "",
      govManagementSystem: "",
      govData: "",
      govChartData: [],
      srManagerThreshold: "",
      boardThreshold: "",
      workforceThreshold: "",
      thresholdsData: [],
      thresholds: [],
      industrySearchPlaceholder: "",
      selectedIndustries: [],
      selectedCountriesForThreshold: [],
      strategicActionTableData: {
        heading: ["", "2022", "2023"],
        data: [
          {
            rowHeading: "Public commitment related to gender equity",
            col1: "right",
            col2: "right",
          },
          {
            rowHeading: "Clear, measurable & time-bound gender targets",
            col1: "right",
            col2: "wrong",
          },
          {
            rowHeading:
              "Action items or action plan linked to gender targets with clear roles/responsibilities and timelines",
            col1: "wrong",
            col2: "wrong",
          },
          {
            rowHeading:
              "Financial resources specifically allocated to gender and/or to implement gender action plans",
            col1: "wrong",
            col2: "right",
          },
          {
            rowHeading:
              "Standalone gender strategy and/or gender explicitly integrated into strategic / business plan",
            col1: "right",
            col2: "right",
          },
        ],
      },
      isResultByDimesnion: true,
      isESGScreenSection: true,
      isGovAcctSection: true,
      isEntreAndOwnerSection: true,
      isLeadershipSection: true,
      isEmpDiversitySection: true,
      isSupplyChainSection: true,
      isPortfolioSection: true,
      entityChosen: "SME",
      certificationSurveyData: [],
      certificationScoreData: [],
      isSupplyChainEligible: "",
      isProductsEligible: "",
      isEmploymentEligible: "",
      isLeadershipEligible: "",
      isEntOwnEligible: "",
      isAllMinReqSurveysCompleted: false,
      isAllFullSurveysCompleted: false,
      status2xCertification: "",
      csvData: [],

      //FROM my2xassement PAGE
      invitedUserEmail: "",
      indicatorBlurb: [],
      comapnyDetails: [],
      active: false,
      endofcreated: false,
      country: {
        income: "",
      },
      theme: {},
      gbvrisk: {},
      sector: { name: "" },
      env,
      risk_level: "",
      openPanels: [],

      isReadMore: false,
      readMoreInfo: null,
      title: null,
      favouriteOrgData: [],
      user: [],
      consentsData: [],
      dealConsentData: [],
      selectedOrganization: "",
      showData: false,
      orgModalTitle: "",
      amountFundedData: [],
      fundedFundingAmount: 0,
      consideringFundingAmount: 0,
      committedFundingAmount: 0,
      fundingAmountValue: [],
      enteredFundedAmount: "",
      selectedFundType: "Considering",
      defaultFundTypes: ["Considering", "Committed", "Funded"],
      selectedCountryDetails: [],
      fixedAssessmentsAPIBody: [],
      selectedOrganizations: [],
      isDownloadPDF: false,
      downloadCSV: false,
      initialCompanyData: [],
      countData: [],
      countOf2xAssess: 0,
      structuredData: {},
      options_eligible: {
        text: {
          color: "#2BD4C4",
          fontSize: 12,
          fontFamily: "Montserrat",
          fontWeight: "bold",
          shadowEnable: false,
          shadowColor: "#000000",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2BD4C4",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 60,
          width: 60,
          verticalTextAlign: 30,
          horizontalTextAlign: 12,
          zeroOffset: 0,
          strokeWidth: 12,
          progressPadding: 2,
          type: "circle",
        },
      },
      options_notEligible: {
        text: {
          color: "#FE5000",
          fontSize: 12,
          fontFamily: "Montserrat",
          fontWeight: "bold",
          shadowEnable: false,
          shadowColor: "#000000",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#FE5000",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 60,
          width: 60,
          verticalTextAlign: 30,
          horizontalTextAlign: 12,
          zeroOffset: 0,
          strokeWidth: 12,
          progressPadding: 2,
          type: "circle",
        },
      },
      recogito: null,
      annotationsData: [],
      commentsData: [],

      baseColors: [
        "#201747",
        "#F05323",
        "#9A2B15",
        "#7C7C7C",
        "#2574BB",
        "#4A742C",
        "#883535",
        "#5d212e",
        "#4e4a62",
        "#234681",
        "#35463a",
        "#c53f1c",
        "#b66850",
        "#8a646f",
        "#9d6428",
        "#8b5448",
        "#605068",
        "#725020",
        "#50789b",
        "#637854",
        "#387473",
        "#883535",
      ],
      spiderColor: {},
      filelist: [],
      documentIsActive: false,
      activeTab: "Funds",
      applyTermsPreloader: false,
      investeesData: [],
      isPortfolioVisible: true,
    };
  },
  components: {
    BreadCrumbs,
    pageLoader,
    spiderChart,
    "dynamic-render": dynamicRender,
    "triangle-chart": triangleChart,
    TwoxPortfolioGridTable,
    TwoxTimefilterVue,
  },

  filters: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    numberWithCommas(x) {
      var numFormat = new Intl.NumberFormat("en-US");
      return numFormat.format(x);
    },
  },
  async created() {},
  methods: {
    getImgUrl(icon) {
      if (icon) {
        try {
          return require(`@/assets/images/2x_certify/2x_challenge/${icon}.svg`);
        } catch (e) {
          console.error(`Failed to load icon: ${icon}`, e);
        }
      }
    },
    async onExport() {
      this.endofcreated = false;
      let response = {};
      try {
        response = await apiService.fetch2xPortfolioCsvData(this.user._id);
      } catch (error) {
        this.endofcreated = true;
        console.log(error);
        return;
      }
      this.endofcreated = true;

      const wb = XLSX.utils.book_new();
      let aggregatedSheet = [];
      let rawSheet = [];

      let inviteesAttribs = {
        email: "Email",
        companyName: "Company Name",
      };

      for (const key in inviteesAttribs) {
        let rawRow = [{ v: inviteesAttribs[key] }];
        response.data.rawResults.forEach((raw) => {
          rawRow.push({ v: raw.user[key] });
        });
        rawSheet.push(rawRow);
      }

      let generalInfo = [
        { key: "GEN_General_17", defaultTitle: "Latest Reporting Quarter" },
        { key: "GEN_General_1", defaultTitle: "Contact name" },
        { key: "GEN_General_2", defaultTitle: "Contact Title" },
        { key: "GEN_General_3", defaultTitle: "Contact Email" },
        { key: "GEN_General_4", defaultTitle: "Contact Phone Number" },
        { key: "GEN_General_10", defaultTitle: "Entity Name" },
        { key: "GEN_2X_6", defaultTitle: "Website" },
        { key: "GEN_2X_7", defaultTitle: "LinkedIn Profile" },
        { key: "GEN_2X_8", defaultTitle: "Twitter" },
      ];

      for (let index in generalInfo) {
        let general = generalInfo[index];
        let rawRow = [];
        if (response.data.rawResults[0].hasOwnProperty(general.key)) {
          let title = general.defaultTitle;
          let first = response.data.rawResults[0][general.key];
          if (first.title) {
            title = first.title;
          } else if (first.surveyQuestion) {
            title = first.surveyQuestion;
          }
          let rawRow = [{ v: title }];
          response.data.rawResults.forEach((raw) => {
            if (raw.extraData[general.key]) {
              rawRow.push({ v: raw.extraData[general.key] });
            }
          });
        } else {
          rawRow.push({ v: general.defaultTitle });
        }
        rawSheet.push(rawRow);
      }

      response.data.aggregatedResults.forEach((result) => {
        aggregatedSheet.push([
          { v: result.surveyQuestion },
          { v: result.value },
        ]);

        let rawRow = [{ v: result.surveyQuestion }];
        response.data.rawResults.forEach((raw) => {
          rawRow.push({ v: raw.results[result.key] });
        });
        rawSheet.push(rawRow);
      });

      const aggregatedWS = XLSX.utils.aoa_to_sheet(aggregatedSheet);
      const rawWS = XLSX.utils.aoa_to_sheet(rawSheet);
      XLSX.utils.book_append_sheet(
        wb,
        aggregatedWS,
        "Aggregate Fund Portfolio"
      );
      XLSX.utils.book_append_sheet(wb, rawWS, "Fund Portfolio Survey");

      XLSX.writeFile(
        wb,
        `${response.data.inviter.organizationName} 2X portfolio.xlsx`
      );
    },
    getAllDocuments() {
      apiService
        .fetchUploadDocumentsData(
          this.$route?.query?.teamId
            ? this.$route.query?.teamId
            : this.user?.organization?._id
        )
        .then((response) => {
          this.filelist = response.data.data;
        });
    },
    async updateRecogito() {
      this.recogito = await new Recogito({
        content: this.$refs.annotatable,
        readOnly: this.$route.path == "/admin/full-assessment" ? false : true,
        // readOnly: true, uncomment this if you don't want to edit annotations, user can only see annotations.
        widgets: [
          {
            widget: "COMMENT",
            args: { placeholder: "Add a comment" },
          },
        ],
      });

      await this.loadAnnotations();
      this.recogito.on("createAnnotation", async (annotation) => {
        await this.annotationsData.push(annotation);
        await this.saveVerifiersComment(annotation);
        await this.getVerifiersComments();
      });

      this.recogito.on("updateAnnotation", async (annotation, previous) => {
        this.annotationsData = this.annotationsData.filter(
          (data) => data.id !== previous.id
        );
        await this.annotationsData.push(annotation);
        await this.updateVerifiersComment(annotation);
        await this.getVerifiersComments();
      });

      this.recogito.on("deleteAnnotation", async (annotation) => {
        this.annotationsData = this.annotationsData.filter(
          (data) => data.id !== annotation.id
        );
        await this.deleteVerifiersComment(annotation);
        await this.getVerifiersComments();
      });
    },
    async getVerifiersComments() {
      await apiService
        .fetchVerifiersComments(this.$route?.query?.teamId)
        .then(async (response) => {
          this.commentsData = response.data.data;
          this.commentsData.forEach((data, i) => {
            data["index"] = i + 1;
          });
          this.$emit("commentsData", this.commentsData);
          this.commentsData.forEach((data) => {
            if (data?.comment?.id && data?.comment?.type == "Annotation") {
              this.annotationsData.push(data.comment);
            }
          });
        });
    },
    async saveVerifiersComment(comment) {
      await apiService
        .postVerifiersComments(comment, this.user?.organization?._id)
        .then(async (response) => {
          console.log("response", response);
        });
    },
    async updateVerifiersComment(comment) {
      let commentId;
      this.commentsData.forEach((data) => {
        if (comment?.id == data?.comment?.id) {
          commentId = data?._id;
        }
      });
      await apiService
        .putVerifiersComments(comment, commentId)
        .then(async (response) => {
          console.log("response", response);
        });
    },
    async deleteVerifiersComment(comment) {
      let commentId;
      this.commentsData.forEach((data) => {
        if (comment?.id == data?.comment?.id) {
          commentId = data?._id;
        }
      });
      await apiService
        .removeVerifiersComments(commentId)
        .then(async (response) => {
          console.log("response", response);
        });
    },
    loadAnnotations() {
      if (this.annotationsData?.length > 0) {
        this.annotationsData.forEach((annotation) => {
          this.recogito.addAnnotation(annotation);
        });
      }
    },
    getColors(dimension) {
      if (this.spiderColor[dimension]) {
        return this.spiderColor[dimension];
      } else {
        let color = this.baseColors.pop();
        this.spiderColor[dimension] = color;
        return color;
      }
    },
    fetchCountryData(iso) {
      return axios.get(`${env.apiUrl}/countries/${iso}/sectors/country`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      });
    },
    async fetchCertificationData() {
      await apiService
        .fetch2xCertificationData(
          this.entityChosen,
          this.$route?.query?.teamId,
          this.$store.state.certPageAccess.isFullSurveyFill ? "Certification" : "Challenge"
        )
        .then(async (res) => {
          this.certificationScoreData = await res.data.data?.latest;
          if (this.certificationScoreData?.ISO) {
            await this.getCountryData();
            await this.getSectorData();
          }
          await this.calculateCertLevel();
          await this.getCompanyData();
          await this.getStructuredData();
          await this.calculateThreshold();
          if (this.certificationScoreData.level == "Good") {
            this.certification_result = this.cert_categories.Good;
          } else if (
            this.certificationScoreData.level == "Best in class" ||
            this.certificationScoreData.level == "Best in Class"
          ) {
            this.certification_result = this.cert_categories.BestInClass;
          } else if (this.certificationScoreData.level == "Advanced") {
            this.certification_result = this.cert_categories.Advanced;
          }
          if (this.entityChosen == "fund-manager") {
            this.entrepreneurshipPercent =
              this.certificationScoreData?.ENTOWN_2X_104 == "NA"
                ? 0
                : this.certificationScoreData?.ENTOWN_2X_104;
            this.leadershipPercent = this.certificationScoreData?.LEAD_2X_104;
            this.employmentPercent = this.certificationScoreData?.EMPLOY_2X_104;
            this.productServicePercent =
              this.certificationScoreData?.PROD_2X_104 == "NA"
                ? 0
                : this.certificationScoreData?.PROD_2X_104;
            this.supplyChainPercent =
              this.certificationScoreData?.SUPCH_2X_104 == "NA"
                ? 0
                : this.certificationScoreData?.SUPCH_2X_104;
            this.portfolioPercent =
              this.certificationScoreData?.PORT_Portfolio_1000 == "NA"
                ? 0
                : this.certificationScoreData?.PORT_Portfolio_1000;

            this.govStrategicAction =
              this.certificationScoreData?.GOVACC_Strategic_action_103;
            this.govManagementSystem =
              this.certificationScoreData?.GOVACC_Management_systems_re_gender_103;
            this.govData =
              this.certificationScoreData?.GOVACC_Gender_disaggregated_data_114;
          } else {
            this.entrepreneurshipPercent =
              this.certificationScoreData?.ENTOWN_2X_101 == "NA"
                ? 0
                : this.certificationScoreData?.ENTOWN_2X_101;
            this.leadershipPercent = this.certificationScoreData?.LEAD_2X_101;
            this.employmentPercent = this.certificationScoreData?.EMPLOY_2X_101;
            this.productServicePercent =
              this.certificationScoreData?.PROD_2X_101 == "NA"
                ? 0
                : this.certificationScoreData?.PROD_2X_101;
            this.supplyChainPercent =
              this.certificationScoreData?.SUPCH_2X_101 == "NA"
                ? 0
                : this.certificationScoreData?.SUPCH_2X_101;
            this.portfolioPercent =
              this.certificationScoreData?.PORT_Portfolio_1000 == "NA"
                ? 0
                : this.certificationScoreData?.PORT_Portfolio_1000;

            this.govStrategicAction =
              this.certificationScoreData?.GOVACC_Strategic_action_102;
            this.govManagementSystem =
              this.certificationScoreData?.GOVACC_Management_systems_re_gender_102;
            this.govData =
              this.certificationScoreData?.GOVACC_Gender_disaggregated_data_113;
          }
          if (this.isPortfolioVisible &&
            (this.entityChosen == "fund-manager" ||
            this.entityChosen == "financial-institution")
          ) {
            this.domainChartData = [
              this.entrepreneurshipPercent,
              this.leadershipPercent,
              this.employmentPercent,
              this.productServicePercent,
              this.supplyChainPercent,
              this.portfolioPercent,
            ];
          } else {
            this.domainChartData = [
              this.entrepreneurshipPercent,
              this.leadershipPercent,
              this.employmentPercent,
              this.productServicePercent,
              this.supplyChainPercent,
            ];
          }
          this.govChartData = [
            this.govStrategicAction,
            this.govManagementSystem,
            this.govData,
          ];
          if (this.entityChosen == "fund-manager") {
            this.isSupplyChainEligible =
              this.certificationScoreData["SUPCH_2X_304"];
            this.isProductsEligible =
              this.certificationScoreData["PROD_2X_304"];
            this.isEmploymentEligible =
              this.certificationScoreData["EMPLOY_2X_304"];
            this.isLeadershipEligible =
              this.certificationScoreData["LEAD_2X_304"];
            this.isEntOwnEligible =
              this.certificationScoreData["ENTOWN_2X_304"];
          } else {
            this.isSupplyChainEligible =
              this.certificationScoreData["SUPCH_2X_300"];
            this.isProductsEligible =
              this.certificationScoreData["PROD_2X_300"];
            this.isEmploymentEligible =
              this.certificationScoreData["EMPLOY_2X_300"];
            this.isLeadershipEligible =
              this.certificationScoreData["LEAD_2X_300"];
            this.isEntOwnEligible =
              this.certificationScoreData["ENTOWN_2X_300"];
          }
        })
        .catch(() => {
          this.endofcreated = true;
          this.$emit("endofcreated", this.endofcreated);
        });
    },
    calculateCertLevel() {
      if (this.entityChosen == "start-up") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "sme") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "corporate") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (
        this.entityChosen == "Fund Manager" ||
        this.entityChosen == "fund-manager"
      ) {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_403"];
      } else if (this.entityChosen == "financial-institution") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_404"];
      }
    },
    async exportToPDF() {
      this.isDownloadPDF = true;
      var pagebreak = { before: ".before_page" };
      setTimeout(async () => {
        let element = document.querySelector(".ContentWrapper");
        await html2pdf()
          .set({
            margin: 1,
            filename: "Full-Assessment.pdf",
            pagebreak: pagebreak,
            html2canvas: {
              logging: false,
              dpi: 192,
              letterRendering: true,
              useCORS: true,
              allowTaint: true,
            },
            jsPDF: {
              orientation: "portrait",
              unit: "cm",
              format: [55, 35],
            },
          })
          .from(element)
          .save();
        this.isDownloadPDF = false;
        return;
      }, 1000);
    },
    async calculateThreshold() {
      const thresholdsUrl = env.apiUrl + "/thresholds2x/all";
      const res = await axios.get(thresholdsUrl, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      });
      this.thresholds = res.data.data.filter((item) => item.threshold != "");
      if (
        this?.certificationScoreData?.ISO &&
        this?.certificationScoreData?.GEN_General_13
      ) {
        await this.getThresholdsData(
          this.certificationScoreData.ISO,
          this.certificationScoreData?.GEN_General_13
        );
      }
      this.industrySearchPlaceholder =
        this.certificationScoreData?.GEN_General_13;
      const workforce = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData?.GEN_General_11) &&
          item.indicator == "Employees" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const srManager = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Senior Manager" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const board = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Board Member" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );

      this.workforceThreshold =
        workforce.length == 1
          ? workforce?.[0]?.threshold
          : workforce?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.srManagerThreshold =
        srManager.length == 1
          ? srManager?.[0]?.threshold
          : srManager?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.boardThreshold =
        board.length == 1
          ? board?.[0]?.threshold
          : board?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;
    },
    async getThresholdsData(recordId, sector) {
      await apiService
        .fetchThresholdsData(recordId, sector)
        .then(async (response) => {
          this.thresholdsData = await response.data.data;
        });
    },
    async getCompanyDetails() {
      const { id, email, dealId, recordId, type } = this.$route.query;
      let assessmentsAPIBody = { email: this.user.email };

      if (id) {
        this.sharedOrgRoute = true;
        assessmentsAPIBody = { isShared: true, userId: id };
      } else if (email) {
        this.sharedOrgRoute = true;
        assessmentsAPIBody = { email: email };
      }

      if (dealId) assessmentsAPIBody.dealId = dealId;
      if (recordId) {
        assessmentsAPIBody.recordId = recordId;
        assessmentsAPIBody.tableName = type;
      }

      const url = "/assesment2x/all-assesment";
      try {
        const response = await axios.post(
          env.apiUrl + url,
          assessmentsAPIBody,
          {
            headers: { Authorization: `Bearer ${Cookies.get("session")}` },
          }
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching company details:", error);
        throw error;
      }
    },
    async getCountryData() {
      await apiService
        .fetchCountryData(this.certificationScoreData?.ISO)
        .then(async (response) => {
          this.countryData = response.data.data;
        });
    },
    async getSectorData() {
      await apiService
        .fetchSectorData(
          this.certificationScoreData?.ISO,
          this.certificationScoreData?.GEN_General_13
        )
        .then(async (response) => {
          this.sectorData = response.data.data;
        });
    },
    async getCompanyData() {
      let companyData = await this.getCompanyDetails();
      this.initialCompanyData = companyData;
      this.comapnyDetails = companyData?.assesments;
      if (
        Array.isArray(this.comapnyDetails) &&
        this.comapnyDetails.length > 0
      ) {
        this.profile = this.comapnyDetails[0];
      }
    },
    readMore(title, data) {
      this.isReadMore = true;
      this.readMoreTitle = title;
      this.readMoreInfo = data;
    },
    async getStructuredData() {
      const entityMap = {
        "financial-institution": "Financial Institution",
        "fund-manager": "Fund Manager",
        "start-up": "Start-up",
        sme: "SME",
        corporate: "Corporate",
      };

      const entity = entityMap[this.entityChosen] || this.entityChosen;

      try {
        const res = await apiService.fetch2xPortfolioData(this.user._id);

        this.investeesData = res.data.data.investees.map((investee) => {
          return {
            ...investee,
            certificationImage: "@/assets/images/2x_certification_tier_good.svg",
            certificationStatus: "2X Certified",
            certificationDate: moment(new Date()).format('DD.MM.YYYY'),
            verificationStatus: "Pending verification",
            certificationRequest:
              "Request to repeat 2X Certification Self-Assessment",
          }
        });
        
        const twoXData = await apiService.fetch2xCertDataPageWise(
          "Full Assessment Summary Results",
          entity,
          this.$route?.query?.teamId || ""
        );
        if (twoXData.status == 200) {
          twoXData.twoXScoreOverall = twoXData.latest["2X"]["2X Score Overall"][0].value;
        }

        switch (this.twoXScoreOverall) {
          case "Good":
            this.twoXScoreOverall = 37;
            break;
          case "Advanced":
            this.twoXScoreOverall = 67;
            break;
          case "Best in class":
            this.twoXScoreOverall = 100;
            break;
          default:
            this.twoXScoreOverall = 0;
        }

        const spiderData = [];
        const twoxSpider = "2X Spider Chart Score Value";
        const triangleType = "2X Governance Triangle Chart Score Value";
        const ALLOWED = ["Yes/No", "Donut chart", "List", "Bar graph"];

        const data = Object.entries(twoXData.latest)
          .filter(([key]) => key !== "2X")
          .map(([key, value]) => {
            const triangleData = [];
            const result = {
              isActive: false,
              title: key,
              indicators: [],
              triangle: [],
              show: false,
            };

            result.indicators = Object.entries(value).map(
              ([keyInternal, values]) => {
                const full = values.filter(
                  (item) =>
                    item.value !== "NA" &&
                    item.value !== null &&
                    ALLOWED.includes(item.chartType) &&
                    item.value
                );

                const spider = values
                  .filter(
                    (item) =>
                      item.value !== null && item.chartType === twoxSpider
                  )
                  .map((item) => ({
                    ...item,
                    sub: keyInternal.trim(),
                    domain: key.trim(),
                    color:
                      this.colors.find((color) =>
                        key.toLowerCase().includes(color.code.toLowerCase())
                      )?.color || this.getColors(key),
                  }));

                if (spider.length) {
                  spiderData.push(...spider);
                }

                const triangle = values
                  .filter(
                    (item) =>
                      item.value !== null && item.chartType === triangleType
                  )
                  .map((item) => ({
                    ...item,
                    sub: keyInternal.trim(),
                    domain: key.trim(),
                    color:
                      this.colors.find((color) =>
                        key.toLowerCase().includes(color.code.toLowerCase())
                      )?.color || this.getColors(key),
                  }));

                if (triangle.length) {
                  triangleData.push(...triangle);
                }

                return {
                  isActive: false,
                  title: keyInternal,
                  values: full,
                  hasValues: full.length > 0,
                };
              }
            );

            result.triangle = triangleData;
            result.show = result.indicators.some(
              (indicator) => indicator.hasValues
            );
            return result;
          });
          this.spiderData = spiderData.filter(data => !(data.domain == 'Portfolio' && !this.isPortfolioVisible))
        // this.spiderData = spiderData;

        const domainCounts = spiderData.reduce((acc, item) => {
          if (!acc[item.domain]) {
            acc[item.domain] = { count: 0, color: item.color };
          }
          acc[item.domain].count++;
          return acc;
        }, {});

        const targetLength = 15;
        const diff = targetLength - spiderData.length;
        const totalCount = spiderData.length + Math.abs(diff);

        let spiderSpaces = new Array(totalCount).fill(null);

        let index = 0;
        for (const domain in domainCounts) {
          if (index >= spiderSpaces.length) break;
          spiderSpaces[index] = {
            domain,
            count: domainCounts[domain].count,
            color: domainCounts[domain].color,
          };
          index += domainCounts[domain].count;
        }

        if (diff !== 0) {
          const nullSpaces = new Array(Math.abs(diff)).fill(null);
          const step = Math.ceil(spiderSpaces.length / nullSpaces.length);
          for (let i = 0; i < nullSpaces.length; i++) {
            spiderSpaces.splice(i * step, 0, nullSpaces[i]);
          }
        }
        this.spiderSpaces = spiderSpaces.filter(data => !(data?.domain == 'Portfolio' && !this.isPortfolioVisible))
        // this.spiderSpaces = spiderSpaces;

        data.forEach((domain) =>
          domain?.indicators?.forEach((indicator) => {
            const yn = indicator.values.filter(
              (item) => item.chartType === "Yes/No"
            );
            indicator.values = indicator.values.filter(
              (item) => item.chartType !== "Yes/No"
            );
            if (yn.length) {
              indicator.values.push({
                chartType: "CustomTable",
                questions: yn,
              });
            }
          })
        );

        this.structuredData = data;
        this.structuredData.forEach(data => {
          if(data.title == 'Portfolio' && !this.isPortfolioVisible) {
            data.show = false;
          }
        });
      } catch (error) {
        console.error("Error fetching structured data:", error);
      }
    },
    activateTab(tab) {
      this.activeTab = tab;
    },
  },
  beforeDestroy() {
    if (this.recogito) {
      this.recogito.destroy();
    }
  },
  computed: {
    blueChartStrokeValue() {
      let value;
      const percent = 80;
      const stroke = 408.2;
      value = (stroke / 100) * (100 - percent);
      return value;
    },
    yellowChartStrokeValue() {
      let value;
      const percent = 80;
      const stroke = 722.2;
      value = (stroke / 100) * (100 - percent);
      return value;
    },
    sectorDisplay() {
      if (Array.isArray(this.profile?.sector)) {
        return this.profile.sector.join(", ");
      }
      return this.profile?.sector;
    },
  },
  async mounted() {
    const userStore = useUserStore();
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });

    // This page (path) should be behind the Subscription Paywall if the user does not have
    // "Equilo Premium" package
    if (
      this.$route.path === "/2X-Ecosystem/2X-Certification/2X-Portfolio" &&
      userStore.subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM
    ) {
      await this.$router.push({ path: "/" });
    }

    this.assessmentYear.push(
      new Date("2021-12-06T04:25:58.000Z").getFullYear() +
        " / " +
        new Date("2021-12-06T04:25:58.000Z").toLocaleString("en-US", {
          month: "short",
        })
    );
    await apiService
    .fetch2xCertRequest(this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id, 'Certification')
    .then(async (response) => {
      this.status2xCertification = response?.data?.data?.status;
    });

    let entity = "Financial Institution";
    if (this.entityChosen == "financial-institution") {
      entity = "Financial Institution";
    }
    if (this.entityChosen == "fund-manager") {
      entity = "Fund Manager";
    }
    if (this.entityChosen == "start-up") {
      entity = "Start-up";
    }
    if (this.entityChosen == "sme") {
      entity = "SME";
    }
    if (this.entityChosen == "corporate") {
      entity = "Corporate";
    }
    this.entityCode = entity;
    await this.$store.commit("setCertPageStatus", this.status2xCertification);
    await this.fetchCertificationData();
    this.isPortfolioVisible = !this.certificationScoreData?.['PORT_Portfolio_900']?.includes('none');
    if (this.$route.path == "/admin/full-assessment") {
      await this.getVerifiersComments();
    }
    await this.getAllDocuments();
    this.endofcreated = true;
    this.$emit("endofcreated", this.endofcreated);
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setNewBreadcrumbs", [
      {
        name: "2X Certification",
        url: "/2X-Ecosystem/2X-Certification",
      },
      {
        name: "2X-Portfolio",
        url: "/2X-Ecosystem/2X-Certification/new-2X-Portfolio",
      },
    ]);
    if (this.$route.path == "/admin/full-assessment") {
      await setTimeout(() => {
        this.updateRecogito();
      }, 4000);
    }
  },
};
</script>

<style lang="scss" scoped>
.esg-pre-screening {
  padding: 40px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 15px;

  .esg-pre-screening-results-section {
    p {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
    }
  }

  .OverviewTitle {
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #1b1b1b;
      margin-right: 17px;
      position: relative;
    }
  }

  .GBVdetailsContent {
    justify-content: space-between;

    ul {
      margin-top: 1.5rem;
    }

    ul li {
      display: flex;
      margin: 0;
    }

    .overOverviewDeitail {
      width: 100%;

      span {
        font-weight: bold;
        font-size: 17px;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 5px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
      }
    }

    .GbvRiskContent {
      max-width: 670px;
      widows: 100%;

      .step_gbvRisk {
        flex-wrap: wrap;
      }

      .GbvRiskTitleWrepper {
        margin-bottom: 40px;

        .GbvRiskTitle {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 60px;
            height: 40px;
            object-fit: contain;
            margin-right: 20px;
          }

          h1 {
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            color: #1b1b1b;
          }
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          color: #9d9d9c;
        }
      }

      .SevereRiskWrepper {
        h1 {
          margin-top: 60px;
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #fe5000;

          img {
            width: 40px;
            height: 70px;
            object-fit: contain;
            margin-right: 30px;
          }

          span {
            margin-right: 5px;
          }
        }

        .UbiquitousTextIcon {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            width: 16px;
            height: 30px;
            object-fit: contain;
            margin-right: 10px !important;
          }

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #9d9d9c;
            margin-right: 20px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

.GBVdetailsBlock {
  border-radius: 10px;
  padding: 40px;

  .GBVdetailsContent {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .overOverviewDeitail {
      width: 100%;

      span {
        font-weight: bold;
        font-size: 17px;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 5px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
      }
    }

    .GbvRiskContent {
      max-width: 670px;
      widows: 100%;

      .step_gbvRisk {
        flex-wrap: wrap;
      }

      .GbvRiskTitleWrepper {
        margin-bottom: 40px;

        .GbvRiskTitle {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 60px;
            height: 40px;
            object-fit: contain;
            margin-right: 20px;
          }

          h1 {
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            color: #1b1b1b;
          }
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          color: #9d9d9c;
        }
      }

      .SevereRiskWrepper {
        h1 {
          margin-top: 60px;
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #fe5000;

          img {
            width: 40px;
            height: 70px;
            object-fit: contain;
            margin-right: 30px;
          }

          span {
            margin-right: 5px;
          }
        }

        .UbiquitousTextIcon {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            width: 16px;
            height: 30px;
            object-fit: contain;
            margin-right: 10px !important;
          }

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #9d9d9c;
            margin-right: 20px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

.font-arial {
  font-family: Arial;
}

.barchart2xCriteria_Wrepper {
  .CriteriaTitleText {
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
      width: 30px;
      // height: 20px;
      object-fit: contain;
    }

    p {
      font-family: Arial;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      color: #2c2e35;
    }
  }

  .ChartBarContentWrepper {
    gap: 60px;
    border-bottom: 1px solid #e1e3e9;

    .multiple_employment_results_section {
      p {
        margin-bottom: 20px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #1b1b1b;
      }
    }

    .ChartBarContent {
      .Founded_WomanText {
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #2c2e35;
        margin-bottom: 15px;
        line-height: 24px;
      }

      .Founded_WomanImg {
        display: flex;
        align-items: center;

        img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
      }

      .ChartBarPercentageText {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #2c2e35;
        margin-bottom: 10px;
      }
    }

    .ChartBarContent_flex {
      display: flex;
      width: 100%;
      max-width: 310px;
      margin-right: 100px;

      .Founded_WomanText {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        color: #1b1b1b;
        margin-bottom: 15px;
      }

      .Founded_WomanImg {
        display: flex;
        align-items: center;

        img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
      }

      .ChartBarPercentageText {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 10px;
      }
    }
  }

  .Consumption_ListText {
    p {
      margin-bottom: 15px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
        list-style: none;
        margin-bottom: 10px;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: contain;
          margin-right: 10px;
        }
      }
    }
  }

  .Quality_EmploymentText_left {
    .assessment_year_one_results {
      width: 50%;
      justify-content: space-between;

      p {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
      }
    }

    .assessment_year_second_results {
      width: 50%;

      p {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
      }
    }

    p {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
        list-style: none;
        margin-bottom: 10px;
        margin-left: 0;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: contain;
          margin-right: 10px;
        }
      }
    }
  }

  .Quality_EmploymentText_right {
    .assessment_year_one_results {
      width: 50%;

      p {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
      }
    }

    .assessment_year_second_results {
      width: 50%;

      p {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
      }
    }

    p {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
        list-style: none;
        margin-bottom: 10px;
        margin-left: 0;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: contain;
          margin-right: 10px;
        }
      }
    }
  }

  .IndirectInvestmentListContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .IndirectContent {
      max-width: 301px;
      width: 100%;

      .IndirectInvWrepper {
        margin-bottom: 45px;

        .IndirectInvText {
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #1b1b1b;
          margin-bottom: 16px;
        }

        .IndirectInvImg {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit: contain;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.ContentWrapper {
  .input-select {
    border: 1px solid #e1e3e9;
    padding: 12px 24px 12px 64px;
  }

  .next-button {
    font-weight: bold;
    color: white;
    background: #191b45;
  }

  .standard-button {
    font-weight: bold;
    font-size: 16px;
    color: #191b45;
    border-style: solid;
    border-color: #191b45;
    border-width: 1px;
  }

  .full-assessment-button {
    font-weight: bold;
    color: white;
    background: #2177c1;
    padding: 12px 24px 12px 24px;
    font-size: 16px;
  }

  .certification-button {
    font-weight: bold;
    color: white;
    background: #191b45;
  }

  .congrats-box {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding-right: 200px;
    padding-left: 200px;
  }

  .theme-box {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
  }

  .theme-badge {
    border: 2px solid;
    border-radius: 10px;
    margin: 20px 5px 0px;
    padding: 10px;
    height: 100%;
    font-size: 14px;
  }

  .overview-box {
    border: 1px solid #ccc;
    border-radius: 15px;

    //padding: 20px;
    /* FROM my2xassement PAGE */
    .OverviewTitle {
      display: flex;
      align-items: center;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 17px;
        position: relative;
      }

      // span {
      //   display: inline-block;
      //   border-bottom: 2px solid #1b1b1b;
      //   width: 100%;
      //   height: 2px;
      // }
    }

    hr {
      margin: 20px 0;
      width: 103.5%;
      text-align: left;
      margin-left: -1.75%;
      border-color: #ccc;
    }

    .spiderchartEligibleWrepper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .spiderchartContent {
        width: 100%;
      }
    }
  }

  .chart-box {
    border: 1px solid #ccc;
    border-radius: 15px;

    .heading-section {
      border-bottom: 1px solid #e1e3e9;
    }

    .inner-main-heading {
      .inner-section {
        width: -webkit-fill-available;
      }

      p {
        font-family: Arial;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    /* FROM my2xassement PAGE */
    .OverviewTitle {
      display: flex;
      align-items: center;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 17px;
        position: relative;
      }

      // span {
      //   display: inline-block;
      //   border-bottom: 2px solid #1b1b1b;
      //   width: 100%;
      //   height: 2px;
      // }
    }

    hr {
      margin: 20px 0;
      width: 103.5%;
      text-align: left;
      margin-left: -1.75%;
      border-color: #ccc;
    }

    .spiderchartEligibleWrepper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .spiderchartContent {
        width: 100%;
      }

      .Xeligibleconten {
        width: 50%;

        .EligibleListWrepper {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          // margin-bottom: 15px;
          .EligibleListImg {
            position: relative;
            z-index: 1;
            margin-right: 30px;
            // &::after {
            //   content: "";
            //   display: inline-block;
            //   border: 1px solid #d0d0d0;
            //   position: absolute;
            //   height: 20px;
            //   top: 60px;
            //   left: 30px;
            //   z-index: -1;
            // }

            img {
              // width: 24px !important;
              // height: 24px;
              border-radius: 50%;
              object-fit: contain;
              margin: 0 !important;
              margin-right: 0 !important;
            }
          }

          .bigImg {
            &::after {
              top: 40px;
              left: 18px;
              height: 20px;
            }

            img {
              width: 40px !important;
              height: 40px;
            }
          }

          .lastAffter {
            &::after {
              content: none;
            }
          }

          .EligibleTitle {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              margin-right: 10px;
              object-fit: contain;
            }

            h4 {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              color: #1b1b1b;
            }

            h3 {
              font-size: 25px;
              font-style: normal;
              font-weight: bold;
              line-height: 30px;
              color: #1b1b1b;
            }
          }
        }

        .BorderLine {
          border: 1px solid #d0d0d0;
          max-width: 375px;
          margin: 30px 0;
        }

        .PartyVerifiedToolTip {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .PartyTooltipImg {
            img {
              // width: 20px;
              // height: 20px;
              border-radius: 50%;
            }
          }

          .TooltipImgText {
            display: flex;
            align-items: center;

            img {
              width: 15px;
              height: 15px;
              margin-left: 5px;
            }

            p {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              color: #1b1b1b;
            }

            .tooltiptext {
              max-width: 400px;
              top: 20px;
            }
          }
        }

        .DownloadBtn {
          p {
            display: flex;
            align-items: center;
            border: 1px solid #1b1b1b;
            border-radius: 20px;
            color: #1b1b1b;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            padding: 11px 17px;
            transition: 0.3s ease;

            img {
              margin-right: 5px;
              width: 15px;
              height: 15px;
            }

            &:hover {
              background: rgb(32, 23, 71);
              color: #fff;
              border-color: rgb(32, 23, 71);
            }
          }

          a {
            display: flex;
            align-items: center;
            border: 1px solid #1b1b1b;
            border-radius: 20px;
            color: #1b1b1b;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            padding: 11px 10px;
            transition: 0.3s ease;

            img {
              margin-right: 8px;
              width: 15px;
              height: 15px;
            }

            &:hover {
              background: rgb(32, 23, 71);
              color: #fff;
              border-color: rgb(32, 23, 71);
            }
          }
        }
      }
    }

    .strategic-plan-section {
    }

    .management-systems-section {
      .gender-policies-section {
      }
    }

    .management-systems-data-section {
      border-bottom: 1px solid #e1e3e9;

      .dimensions-section,
      .themes-section {
        p {
          font-size: 14px;
          font-weight: 400;
          color: #2c2e35;
        }
      }
    }
  }

  /* Tooltip container */
  .div_tooltip {
    position: relative;
    display: inline-block;
  }

  /* Tooltip text */
  .div_tooltip .div_tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .div_tooltip:hover .div_tooltiptext {
    visibility: visible;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .download-csv-tooltip {
    padding: 20px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 290px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: -86px;
    bottom: 100%;
    white-space: normal;
    word-wrap: break-word;
  }

  .tooltip .tooltiptext {
    padding: 20px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 600px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: 20px;
    top: -160px;
  }

  .tooltip .twoXEligibleHoverText {
    padding: 20px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 350px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 141px;
    top: -35px;
  }

  .tooltip .text-style {
    font-size: 14px;
    letter-spacing: normal;
    line-height: 1 !important;
  }

  .tooltip .header-style {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: normal;
    line-height: 1 !important;
  }

  .tooltip .entrepreneurshipHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 330px;
    top: -40px;
  }

  .tooltip .leadershipHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 139px;
    top: -40px;
  }

  .tooltip .employmentHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 160px;
    top: -40px;
  }

  .tooltip .consumptionHoverText {
    padding: 20px;
    visibility: hidden;
    width: 400px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 230px;
    top: -40px;
  }

  .tooltip .supplyChainHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 160px;
    top: -40px;
  }

  .tooltip .portfolioHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 120px;
    top: -40px;
  }

  .tooltip .commitmentHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 160px;
    top: -40px;
  }

  .tooltip .managementSystemsHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 250px;
    top: -40px;
  }

  .tooltip .dataHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 80px;
    top: -40px;
  }

  .tooltip:hover .download-csv-tooltip {
    visibility: visible;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltip:hover .remaining_companies_tooltiptext {
    visibility: visible;
  }

  .tooltip:hover .twoXEligibleHoverText {
    visibility: visible;
  }

  .tooltip:hover .entrepreneurshipHoverText {
    visibility: visible;
  }

  .tooltip:hover .leadershipHoverText {
    visibility: visible;
  }

  .tooltip:hover .employmentHoverText {
    visibility: visible;
  }

  .tooltip:hover .consumptionHoverText {
    visibility: visible;
  }

  .tooltip:hover .supplyChainHoverText {
    visibility: visible;
  }

  .tooltip:hover .portfolioHoverText {
    visibility: visible;
  }

  .tooltip:hover .commitmentHoverText {
    visibility: visible;
  }

  .tooltip:hover .managementSystemsHoverText {
    visibility: visible;
  }

  .tooltip:hover .dataHoverText {
    visibility: visible;
  }

  .tooltip:hover .indirectInvestmentHoverText {
    visibility: visible;
  }

  .tooltip:hover .indirect_tooltip_text {
    visibility: visible;
  }

  .tooltip:hover .tooltiptextIndirectInvestement {
    visibility: visible;
  }

  .tooltip .download-csv-tooltip::after {
    right: 95%;
  }

  .tooltip .tooltiptext::after {
    right: 95%;
  }

  .tooltip .remaining_companies_tooltiptext::after {
    right: 95%;
  }

  .tooltip .twoXEligibleHoverText::after {
    right: 95%;
  }

  .tooltip .entrepreneurshipHoverText::after {
    right: 95%;
  }

  .tooltip .leadershipHoverText::after {
    right: 95%;
  }

  .tooltip .employmentHoverText::after {
    right: 95%;
  }

  .tooltip .consumptionHoverText::after {
    right: 95%;
  }

  .tooltip .supplyChainHoverText::after {
    right: 95%;
  }

  .tooltip .portfolioHoverText::after {
    right: 95%;
  }

  .tooltip .commitmentHoverText::after {
    right: 95%;
  }

  .tooltip .managementSystemsHoverText::after {
    right: 95%;
  }

  .tooltip .dataHoverText::after {
    right: 95%;
  }

  .tooltip .indirectInvestmentHoverText::after {
    right: 95%;
  }

  .tooltip .indirect_tooltip_text::after {
    right: 95%;
  }

  .tooltip .tooltiptextIndirectInvestement::after {
    right: 95%;
  }

  .tooltip .tooltiptext::after {
    display: none;
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.learn_more_button {
  cursor: pointer;
}

.cross_Icon {
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
}

.grey-out {
  pointer-events: none;
  opacity: 0.7;
}

router-link {
  cursor: pointer;
}

.ecosystem-landing {
  background-color: #f4f1ed;
  font-family: Work Sans !important;

  .PoweredbyTitle_logo {
    width: 100%;
    // display: flex;
    // justify-content: space-between;
    padding-top: 170px;
    padding: 170px 0 0;
    margin-top: 20px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #2c2e35;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 20;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .eligible-level-section {
        margin: 28px 0;
        display: flex;
        padding: 16px;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: 12px;
        background: #f4f6f9;

        .eligible-level-text {
          color: var(--grey-grey-100, #2c2e35);
          text-align: center;
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }

        img {
          width: 80px;
        }
      }

      .certification-details {
        .inner-certification-details {
          display: flex;
          align-items: center;
          gap: 12px;
          align-self: stretch;

          .image-div {
            height: 20px;

            img {
              padding: 0;
              height: 36px;
              width: 50px;
            }
          }

          img {
            display: flex;
            width: 36px;
            height: 36px;
            padding: 8px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 6px;
          }

          .write-color {
            background: var(--secondary-10, #eaf6ff);
          }

          .layer-color {
            background: var(--red-light, #ffece8);
          }

          .letter-color {
            background: var(--yellow-light, #fff8e0);
          }

          .check-color {
            background: var(--turquoise-light, #e5fafa);
          }

          .calendar-color {
            background: var(--secondary-10, #eaf6ff);
          }

          .text {
            color: var(--grey-grey-100, #2c2e35);
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            height: 20px;
            line-height: 12px;
          }
        }
      }

      .amount-button-section {
        padding: 16px 0 0 0;
        gap: 16px;
        align-self: stretch;
        border-top: 1px solid var(--grey-grey-20-line, #e1e3e9);

        .amount {
          color: var(--grey-grey-100, #2c2e35);
          text-align: right;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .amount-bold {
          color: var(--grey-grey-100, #2c2e35);
          text-align: right;
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }

        .amount-details {
          color: var(--grey-grey-100, #2c2e35);
          text-align: right;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .button-section {
          button {
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1px solid var(--primary-100, #191b45);
            margin-right: 5px;
          }

          .cancel-button {
            color: var(--primary-100, #191b45);
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-left: 5px;
          }

          .save-button {
            color: var(--white, #fff);
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            background: var(--primary-100, #191b45);
          }
        }

        .privacy-notice-disclosure {
          color: var(--grey-grey-50, #717686);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-align: right;
        }

        .inner-data-privacy {
          color: var(--grey-grey-50, #717686);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-decoration-line: underline;
        }
      }
    }
  }

  .bottom-button-section {
    // height: 120px;
    padding: 36px 10%;
    border-top: 1px solid #e1e3e9;
    gap: 24px;
    box-shadow: 0px -2px 20px 0px #636e801a;

    button {
      height: 48px;
      gap: 20px;
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      border: 1px solid #191b45;
      color: #191b45;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }

    .add-note-button {
      color: #fff;
      background: #2177c1;
      border: none;
      gap: 8px;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

#SPAN_1:hover {
  color: #40a9ff;
}

.text-black-90 {
  color: #2c2e35;
}

.bottom-border {
  border-bottom: 1px solid #e1e3e9;
}

.CountryOverviewText {
  p,
  .leading-7 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #1b1b1b;
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.Modal_Readmorestyle {
  padding: 45px 0 45px 40px;
  position: relative;

  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;

    h3 {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      color: #1b1b1b;
    }
  }

  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}
.time-filter-section {
  .filter-options-section {
    gap: 32px;

    .filter-options {
      height: 32px;
      background: #f4f6f9;
      border-radius: 8px;
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #9ea4ba;

      div {
        padding: 6px 12px 6px 12px;
      }

      .active {
        background: #2177c1;
        border-radius: 8px;
        color: #ffffff;
      }
    }

    .select-year {
      select {
        height: 32px;
        padding: 0 12px;
        border-radius: 8px;
        border: 1px solid #e1e3e9;
        gap: 8px;
        color: #2c2e35;
      }
    }
  }
}
</style>
