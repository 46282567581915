<template>
  <div>
    <div
      class="flex flex-col"
      v-for="(message, index) in messages"
      :key="index"
    >
      <div v-if="message.author !== YOU" class="flex">
        <div class="mt-2 mr-1">
          <div class="w-8 h-8 p-1 rounded-lg bg-[#201747]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-full h-full chat-logo text-white"
            >
              <path
                fill-rule="evenodd"
                d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>

        <div class="max-w-[75%] my-[0.5rem]">
          <div
            class="flex overflow-wrap-anywhere p-2 text-sm font-medium"
            :class="{
              'bg-[#201747] text-white self-start sender': message.author !== YOU,
            }"
          >
            <div class="message-container flex flex-col">
              <MessageMarkdown :message="message.message" />
              <div
                v-if="message.author !== YOU"
                class="flex items-center justify-between"
              >
                <div class="flex-grow"></div>
                <div class="flex flex-grow justify-center items-center">
                  <button
                    v-if="message.sources && message.sources.length > 0"
                    @click="toggleSources(index)"
                    class="toggle-sources-button"
                  >
                    {{
                      expandedMessageIndex && expandedMessageIndex === index
                        ? "Hide Sources"
                        : "Show Sources"
                    }}
                  </button>
                </div>

                <div class="flex-grow"></div>

                <button
                  @click="copyMessage(message.message, index)"
                  v-if="!isCopied || (isCopied && copyMessageIndex !== index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="ml-2 w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                    />
                  </svg>
                </button>
                <button v-if="isCopied && copyMessageIndex === index">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="ml-2 w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                    />
                  </svg>
                </button>
              </div>
              <div
                v-if="message.sources && expandedMessageIndex === index"
                class="sources-content"
              >
                <div
                  v-for="(source, index) in message.sources"
                  :key="index"
                  class="source"
                >
                  [{{ source.sourceNumber }}]
                  <a
                    v-if="source.URL"
                    :href="source.URL"
                    target="_blank"
                    class="text-blue-400 underline active:text-[darkblue]"
                    >{{ source.name }}</a
                  >
                  <span v-else>{{ source.name }}</span>
                  <button
                    @click="copyCitation(source.name, source.sourceNumber)"
                    v-if="
                      !citationCopied ||
                      (citationCopied && citationIndex !== source.sourceNumber)
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 inline ml-2 cursor-pointer"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                      />
                    </svg>
                  </button>
                  <button
                    v-if="citationCopied && citationIndex === source.sourceNumber"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 inline ml-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                      />
                    </svg>
                  </button>
                  <!-- <div class="flex justify-end">
                          </div> -->
                </div>
              </div>
            </div>
          </div>
          <MessageSuggestions
            :suggestions="message?.suggestions"
            @selected-chat-suggestion="handleChatSuggestion"
            v-if="showSuggestions"
          />
          <MessageFeedback
            v-bind="message.feedback || {}"
            v-if="message.author === ASSISTANT && !message?.feedbackDisabled"
            @feedback="(feedback) => $emit('feedback', ({ feedback, index}))"/>
        </div>
      </div>
      <div v-else class="flex flex-col">
        <div
          class="flex overflow-wrap-anywhere max-w-[75%] p-2 text-sm my-[0.5rem] font-medium"
          :class="{
            'bg-[white] text-[#201747] self-end receiver':
              message.author === YOU && !isFullScreen,
            'bg-[#f4f6f9] text-[#201747] self-end receiver':
              message.author === YOU && isFullScreen,
          }"
        >
          <div class="message-container flex flex-col">
            <div class="whitespace-pre-wrap text-lg sm:text-sm">
              {{ message.message }}
            </div>
            <div
              v-if="message.author !== YOU"
              class="flex items-center justify-between"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoadingChatResponse" class="typing-animation text-[#201747]">
      <span>.</span><span>.</span><span>.</span>
    </div>
  </div>
</template>
<script>
import { ChatbotAuthorType } from '../../../helpers/commonVars';
import MessageFeedback from './messageFeedback.vue';
import MessageMarkdown from './messageMarkdown.vue';
import MessageSuggestions from './messageSuggestions.vue';

export default {
  name: "messageList",
  props: {
    messages: {
      type: Array,
      required: true,
    },
    isLoadingChatResponse: {
      type: Boolean,
      required: true,
    },
    expandedMessageIndex: {
      type: Number,
      required: true,
    },
    isFullScreen: {
      type: Boolean,
      required: false,
    },
    showSuggestions: {
      type: Boolean,
      required: false,
    }
  },
  components: {
    MessageMarkdown,
    MessageSuggestions,
    MessageFeedback,
  },
  emits: ["toggle-sources", "selected-chat-suggestion", "feedback"],
  data() {
    return {
      ...ChatbotAuthorType,
      isCopied: false,
      copyMessageIndex: 0,
      citationCopied: false,
      citationIndex: 0,
    };
  },
  methods: {
    handleChatSuggestion(suggestion) {
      this.$emit("selected-chat-suggestion", suggestion);
    },
    toggleSources(index) {
      this.$emit("toggle-sources", index);
    },
    copyMessage(message, index) {
      this.isCopied = true;
      this.copyMessageIndex = index;
      navigator.clipboard.writeText(message);
      setTimeout(() => {
        this.isCopied = false;
        this.copyMessageIndex = 0;
      }, 2000);
    },
    copyCitation(name, index) {
      this.citationCopied = true;
      this.citationIndex = index;
      navigator.clipboard.writeText(name);
      setTimeout(() => {
        this.citationCopied = false;
        this.citationIndex = 0;
      }, 2000);
    },
  },
};
</script>
<style scoped>
.message-container {
  max-width: 100%;
}
.sender {
  border-radius: 10px 0 10px 0;
}
.receiver {
  border-radius: 0 10px 0 10px;
}
.typing-animation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  font-size: 2rem;
}

.typing-animation span {
  animation: typing 1.5s infinite;
  padding: 0 0.2rem;
  opacity: 0; /* They start invisible */
}

.typing-animation span:nth-child(1) {
  animation-delay: 0s; /* First dot appears immediately */
}

.typing-animation span:nth-child(2) {
  animation-delay: 0.5s; /* Second dot appears after the first */
}

.typing-animation span:nth-child(3) {
  animation-delay: 1s; /* Third dot appears last */
}

@keyframes typing {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  } /* The dot is fully visible halfway through the animation */
}
</style>
