<template>
  <div class="mt-10">
    <h1 class="text-3xl font-bold text-[#21191D]">Select a Portfolio Company</h1>
    <div
      v-if="
        (!portfolioSurveys?.length || !portfolioCompanies?.length) && !loading
      "
      class="mt-5"
    >
      <span class="text-red-500"> No surveys or companies found </span>
    </div>
    <div class="flex flex-col space-y-4" v-else>
      <label for="company" class="text-xl font-semibold text-[#21191D] mt-4"
        >Select Company
      </label>
      <p>Click on any survey module to start entering key data across impact metrics, ESG, climate, and financial - this establishes a strong baseline for assessing your portfolio companies.</p>
      <div class="flex gap-4 items-center">
        <select
          v-if="portfolioCompanies?.length"
          v-model="company"
          class="focus:outline-none cursor-pointer p-4 border border-[#E1E3E9] text-[#21191D] font-medium rounded-lg w-1/6"
        >
          <option value="" disabled selected>Select Company</option>
          <option
            v-for="company in portfolioCompanies"
            :key="company._id"
            :value="company"
          >
            {{ company.name }}
          </option>
        </select>
        <div v-if="loading">Loading...</div>
        <div v-else>
          <a
            v-if="isIMMReportButtonVisible"
            class="flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#2177c1] text-white font-bold w-[140px] font-arial text-base leading-6 text-center"
            @click="downloadWordReport"
          >
            Download
            <img src="@/assets/images/download-white.svg" class="w-7 h-7" />
          </a>
          <span v-if="reportErrorMessage">{{ reportErrorMessage }}</span>
        </div>
      </div>
      <div v-if="company.userId">
        <div v-if="!loading">
          <span class="text-sm text-[#21191D]">
            Click on any survey to start
          </span>
          <div class="grid grid-cols-4 gap-10 mt-10">
            <div
              v-for="survey in portfolioSurveys"
              :key="survey.id"
              class="relative group"
            >
              <router-link
                class="px-4 py-4 relative flex items-center justify-center h-24"
                :class="{
                'bg-[#191B45] text-white rounded-lg':
                  survey.isEnabled && !survey.isCompleted,
                'bg-[#a5a5a5] text-gray-700 rounded-lg cursor-not-allowed opacity-50':
                  !survey.isEnabled,
                'opacity-50 bg-green-300 text-white rounded-lg cursor-not-allowed':
                  survey.isCompleted,
              }"
                :to="
                survey.isEnabled && !survey.isCompleted
                  ? survey.id === 'gender-roi'
                    ? '/roi'
                    : survey.id === '2x'
                    ? '/2X-Challenge'
                    : survey.id.includes('rating')
                    ? `/impact-compass/surveys/${survey.id}?id=${company._id}&surveyId=${survey.id}`
                    : `/impact-compass/surveys/${survey.id}?id=${company._id}&tag=${entityType}`
                  : ''
              "
                target="_blank"
              >
                <button
                  class="w-full h-full font-semibold"
                  :class="{
                  'text-white': survey.isEnabled && !survey.isCompleted,
                  'text-gray-700 cursor-not-allowed':
                    !survey.isEnabled || survey.isCompleted,
                }"
                  :disabled="!survey.isEnabled || survey.isCompleted"
                >
                  {{ survey.name }}
                </button>
                <div
                  v-if="survey.isCompleted"
                  class="absolute right-2 top-1/2 transform -translate-y-1/2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-green-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </router-link>
              <div
                v-if="!survey.isEnabled"
                class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-800 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap"
              >
                {{ getDisabledTooltip(survey.id) }}
              </div>
              <div
                v-if="survey.isCompleted"
                class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-800 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap"
              >
                Survey completed
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <span class="text-sm text-red-500">
          This company doesn't have a user id associated with it. Please contact
          support.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/user";
import {
  checkWhiteLabel,
  surveysMapping as mapping,
} from "@/components/imm-components/white-label";
import apiServices from "@/helpers/apiServices";
import { getEntity } from "@/helpers/utils";
import { getSurveyStatus } from "@/components/imm-components/IMMhelpers";
import { saveAs } from 'file-saver';
import ImmAPI from "@/api/ImmAPI";

export default {
  name: "PortfolioSurveys",
  setup() {
    const userStore = useUserStore();
    const immApi = new ImmAPI();
    return { userStore, immApi };
  },
  props: {
    portfolioId: {
      type: String,
      required: true,
    },
    portfolioCompanies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      portfolioSurveys: [],
      requiredSurveys: [],
      requiredSurveysNotCompleted: [],
      user: this.userStore.user,
      company: "",
      surveysMapping: mapping,
      companyProfile: null,
      entityType: null,
      loading: true,
      reportErrorMessage: "",
      isSweef: false,
    };
  },
  async mounted() {
    this.user = this.userStore.user;
    if (!this.user) {
      this.user = await this.userStore.fetchUser();
    }
    this.portfolioSurveys = this.getWhiteLabel().portfolioSurveys;
    this.requiredSurveys = this.getWhiteLabel().report.requiredSurveys;
    this.isSweef = this.user.featureFlags.includes('imm-sweef');
    this.loading = false;
  },
  computed: {
    isIMMReportButtonVisible() {
      return this.isSweef && this.company._id && !this.reportErrorMessage;
    },
    isIMMReportAvailable() {
      return this.requiredSurveysNotCompleted.length === 0;
    },
  },
  methods: {
    getWhiteLabel() {
      return checkWhiteLabel(this.user.featureFlags);
    },
    getDisabledTooltip(surveyId) {
      // handle rating surveys
      if (surveyId === "e&s-rating") {
        return !this.portfolioSurveys.find((s) => s.id === "e&s")?.isCompleted
          ? "Please complete E&S assessment first"
          : "";
      }

      if (surveyId === "climate-rating") {
        return !this.portfolioSurveys.find((s) => s.id === "climate-dd")
          ?.isCompleted
          ? "Please complete Climate assessment first"
          : "";
      }

      // handle company profile dependency
      if (!this.companyProfile && surveyId !== "company-profile") {
        return "Please complete company profile first";
      }

      return "This survey is currently disabled";
    },
    async downloadWordReport() {
      if (this.isIMMReportAvailable) {
        const response = await this.immApi.getIMMImpactDocument(this.portfolioId, this.company?._id);
        saveAs(response.data, 'sweef-impact-report.docx');
      } else {
        this.reportErrorMessage = "Downloading the report will be available once all required data has been submitted.";
        const surveysList = this.requiredSurveysNotCompleted.map((survey) => `<li class="mb-2">${survey.name}</li>`).join('');

        // Show modal error
        await this.$swal.fire({
          icon: "error",
          title: "Impact Due Diligence Report",
          html: `<div class="text-left">
            <p class="mb-2 text-center">You have not completed all the required surveys to download the report.</p>
            <ul class="list-disc ml-4">${surveysList}</ul>
          </div>`,
          showConfirmButton: true,
        });
      }
    },
  },
  watch: {
    portfolioCompanies() {
      this.company = "";
    },
    async company(newVal) {
      this.loading = true;

      try {
        const {
          data: { data },
        } = await apiServices.fetchCompanyProfile(newVal.teamId);
        this.companyProfile = data;
        this.entityType = getEntity(this.companyProfile?.entityType?.[0]);
      } catch (error) {
        console.error("error fetching company profile:", error);
        this.companyProfile = null;
        this.entityType = null;
      } finally {
        this.reportErrorMessage = "";
      }

      const whiteLabel = this.getWhiteLabel();
      const hasProfile = this.companyProfile !== null || this.entityType;

      this.portfolioSurveys = whiteLabel.portfolioSurveys.map((survey) => {
        return {
          id: survey,
          name: this.surveysMapping[survey],
          isEnabled:
            hasProfile &&
            (survey !== "e&s-rating" || survey !== "climate-rating"),
          isCompleted: false,
        };
      });

      // rebuild surveys list with completed status
      const surveyStatus = await getSurveyStatus(
        this.portfolioSurveys.map((survey) => survey.id),
        this.company.userId
      );

      surveyStatus.forEach((status) => {
        const survey = this.portfolioSurveys.find((s) => s.id === status.id);
        if (survey) {
          survey.isCompleted = status.isCompleted;
        }
      });

      // set enabled status for rating surveys & company profile
      this.portfolioSurveys.forEach((survey) => {
        switch (survey.id) {
          case "e&s-rating":
            survey.isEnabled = this.portfolioSurveys.find(
              (s) => s.id === "e&s"
            )?.isCompleted;
            break;
          case "climate-rating":
            survey.isEnabled = this.portfolioSurveys.find(
              (s) => s.id === "climate-dd"
            )?.isCompleted;
            break;
          case "company-profile":
            if (hasProfile) {
              survey.isCompleted = true;
            } else {
              survey.isEnabled = true;
            }
            break;
        }
      });

      // Required surveys not completed
      this.requiredSurveysNotCompleted = this.portfolioSurveys
        .filter((survey) => !survey.isCompleted && this.requiredSurveys.includes(survey.id));

      this.loading = false;
    },
  },
};
</script>
