<template>
  <div :key="'updateDonutIndicatorViewComponents' + updateKey">
    <div class="w-full pt-8" style="display: flex">
      <div v-for="(value, k) in values" :key="k">
        <div class="text-center max-w-[26rem]">
          <div
            class="max-h-[2rem] h-[2rem] w-[26rem] text-center font-arial font-medium"
          >
            {{ value?.title }}
          </div>
          <div class="max-w-[26rem] blue-circle mt-12 max-h-[250px]">
            <MultipleDonutChart
              v-if="oldCertificationScoreData && oldValues?.[k]?.value"
              :chartData="formatValue(value.value)"
              :OldchartData="formatValue(oldValues[k]?.value)"
              :created="[assessmentYear, oldAssessmentYear]"
              :color="['#19486A', '#FF3A21']"
            />
              <svg v-else
              class="m-auto"
              width="200"
              height="200"
              viewBox="-18.75 -18.75 187.5 187.5"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              style="transform: rotate(-90deg)"
            >
              <circle
                r="65"
                cx="75"
                cy="75"
                fill="transparent"
                stroke="#f4f6f9"
                stroke-width="27"
                stroke-dasharray="408.2px"
                stroke-dashoffset="0"
              ></circle>
              <circle
                r="65"
                cx="75"
                cy="75"
                stroke="#19486A"
                stroke-width="27"
                stroke-linecap="round"
                :stroke-dashoffset="
                  (408.2 / 100) * (100 - formatValue(value.value))
                "
                fill="transparent"
                stroke-dasharray="408.2px"
              ></circle>
              <text
                x="37%"
                y="38%"
                fill="#2c2e35"
                font-size="0.875rem"
                font-weight="bold"
                style="transform: rotate(90deg) translate(-4%, -77%)"
              >
                {{ formatValue(value.value) }}%
              </text>
            </svg>
          </div>

          <span
            v-if="value.textForChartInfo"
            class="w-fullrounded-md p-2 text-md text-center max-w-[25rem]"
            v-html="createInteractiveText(value)"
            @click="handleSpanClick"
          ></span>
          <div
            class="flex items-center gap-3 justify-end"
            v-if="
              $route.path.includes('/admin') &&
              value.surveyQType != 'Calculated'
            "
          >
            <img
              src="@/assets/images/edit-dark.svg"
              alt="edit-icon"
              class="h-6 w-6 cursor-pointer"
              @click="openEditModal(value)"
            />
            <verification-dropdown :value="value" />
            <div>
              <MLVerifierValue :value="value" :teamId="$route.query?.teamId" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MLVerifierValue from "./MLVerifierValue.vue";
import VerificationDropdown from "./verificationDropdown.vue";
import { createInteractiveText, handleSpanClick } from "./helpers";
import MultipleDonutChart from "./MultipleDonutChart.vue"

export default {
  name: "DonutIndicatorView",
  data: function () {
    return {
      updateKey: 0,
    };
  },
  components: { VerificationDropdown, MultipleDonutChart, MLVerifierValue },
  props: { values: Array, oldValues: [Array, Object], oldCertificationScoreData: [Array, Object], certificationScoreData: [Array, Object],
    assessmentYear: String,
    oldAssessmentYear: String,
  },
  methods: {
    formatValue(value) {
      let result;
      value = Number(value);
      if (typeof value === "number") {
        result = Math.floor(value * 100) / 100;
      } else {
        result = 0;
      }
      return Math.round(result);
    },
    openEditModal(item) {
      this.$emit("openEditModal", item);
    },
    createInteractiveText(value) {
      return createInteractiveText(value);
    },
    handleSpanClick(event) {
      handleSpanClick(event, this.openEditModal);
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.multi-pie-chart-container {
  width: fit-content;
  .square-legend {
    width: 16px;
    height: 16px;
    background: #19486a;
    border-radius: 2px;
  }
}
:deep(.data-point) {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}
</style>
