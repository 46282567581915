<script>
const _ = require("underscore");
import { Radar } from "vue-chartjs";
export default {
  extends: Radar,
  data: function () {
    return {
      axes: [],
      axeColors: [],
      max: [],
      one: [],
      hoverIndex: false,
      dimensionScores: [],
      oldDimensionScores: [],
      labels: [],
    };
  },
  methods: {
    processData() {
      if (this.info[0] && Array.isArray(this.info[0]) && this.info[0].length > 0) {
        this.labels = this.info[0].map((item) => {
          return item.surveyQuestion
            ?.replace("Sub-dimension score", "")
            .replace(
              "Certification Dimension Score (Percentage) Portfolio #1",
              ""
            )
            .replace(
              '% of portfolio companies that meet minimum "Good" 2X Certification requirements',
              "Portfolio"
            )
            .trim();
        });
        while (this.labels.length < 3) {
          this.labels.push("");
        }
        this.axes = this.info[0].map((item) =>
          item.surveyQuestion?.replace("Sub-dimension score", "").replace("% of portfolio companies that meet minimum \"Good\" 2X Certification requirements",
            "Portfolio"
          ).trim()
        );
        this.dimensionScores = this.info[0].map((item) => item.value);
        this.axeColors = this.info[0].map((item) => item.color);
      }
      // for old data
      if (this.info[1] && Array.isArray(this.info[1]) && this.info[1].length > 0) {
        this.labels = this.info[1].map((item) => {
          return item.surveyQuestion
            ?.replace("Sub-dimension score", "")
            .replace(
              "Certification Dimension Score (Percentage) Portfolio #1",
              ""
            )
            .replace(
              '% of portfolio companies that meet minimum "Good" 2X Certification requirements',
              "Portfolio"
            )
            .trim();
        });
        while (this.labels.length < 3) {
          this.labels.push("");
        }
        this.oldDimensionScores = this.info[1].map((item) => item.value);
      }
    },
    getLink(index, uri) {
      const result = this.info[index].filter(
        (item) =>
          item.surveyQuestion
            .replace("Sub-dimension score", "")
            .replace(
              "Certification Dimension Score (Percentage) Portfolio #1",
              ""
            )
            .replace("% of portfolio companies that meet minimum \"Good\" 2X Certification requirements",
              "Portfolio"
            )
            .trim() === uri
      );
      if (result.length > 0) {
        return encodeURIComponent(result[0].sub);
      }
    },
    formatLabel(str, maxwidth) {
      var sections = [];
      var words = str.split(" ");
      var temp = "";
      words.forEach(function (item, index) {
        if (temp.length > 0) {
          var concat = temp + " " + item;
          if (concat.length > maxwidth) {
            sections.push(temp);
            temp = "";
          } else {
            if (index == words.length - 1) {
              sections.push(concat);
              return;
            } else {
              temp = concat;
              return;
            }
          }
        }
        if (index == words.length - 1) {
          sections.push(item);
          return;
        }
        if (item.length < maxwidth) {
          temp = item;
        } else {
          sections.push(item);
        }
      });
      return sections;
    },
    opacity: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null;
      return "r>gba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    toTitleCase: function (phrase) {
      return (phrase || "")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    calc: function (input) {
      if (typeof input !== undefined && input !== "Missing" && !isNaN(input)) {
        return (Number(input) || 0).toFixed(3);
      } else {
        return null;
      }
    },
    abrev: function (input) {
      let abrevString = "";
      for (let i = 0; i < input.toString().split(" ").length; i++) {
        if (input.toString().split(" ")[i] !== "&") {
          abrevString += input.toString().split(" ")[i].substr(0, 1);
        }
      }
      return abrevString;
    },
  },
  props: [
    "paddingL",
    "paddingR",
    "paddingT",
    "paddingB",
    "info",
    "certificationScoreData",
    "assessmentYear"
  ],
  async mounted() {
    this.processData();
    const _self = this;
    this.max = Array(this.dimensionScores.length).fill(120);
    this.one = Array(this.dimensionScores.length).fill(100);
    this.dimensionScores = this.dimensionScores.map(data => {
      if (data == undefined || data == "NA") {
        return 'NA';
      }
      if (data == "Good" || data == "Advanced" || data == "Best in Class") {
        return 100;
      }
      return data;
    })
    this.oldDimensionScores = this.oldDimensionScores.map(data => {
      if (data == undefined || data == "NA") {
        return 'NA';
      }
      if (data == "Good" || data == "Advanced" || data == "Best in Class") {
        return 100;
      }
      return data;
    })
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            data: this.dimensionScores,
            label: [String(this.assessmentYear?.[0])],
            borderColor: "#DDA63A",
            pointBackgroundColor: this.axeColors,
            pointBorderColor: this.axeColors,
            fill: false,
            backgroundColor: this.opacity("#DDA63A"),
            pointHitRadius: 8,
            pointRadius: 6,
          },
          {
            data: this.oldDimensionScores,
            label: [String(this.assessmentYear?.[1])],
            borderColor: "#FF3A21",
            pointBackgroundColor: this.axeColors,
            pointBorderColor: this.axeColors,
            fill: false,
            backgroundColor: this.opacity("#FF3A21"),
            pointHitRadius: 8,
            pointRadius: 6,
          },
          {
            data: this.max,
            fill: false,
            label: "hide",
            pointBackgroundColor: this.axeColors,
            pointBorderColor: this.axeColors,
            pointHitRadius: 8,
            pointRadius: 6,
          },
        ],
      },
      {
        maintainAspectRatio: false,
        responsive: true,
        spanGaps: false,
        layout: {
          padding: {
            left: this.paddingL,
            right: this.paddingR,
            top: this.paddingT,
            bottom: this.paddingB,
          },
        },
        legend: {
        //   display: false,
          position: "bottom",
          labels: {
            filter: function (item) {
              // Logic to remove a particular legend item goes here
              return !item.text.includes("hide");
            },
            fontSize: 13,
            fontFamily: "basier",
            padding: 40,
          },
          onHover: function (e, legendItem) {
            // Function to only hide Perfect geneder equality
            if (legendItem.text.indexOf("Perfect") == -1) {
              e.target.style.cursor = "pointer";
            }
          },
          // Function to only hide Perfect geneder equality
          onClick: function (e, legendItem) {
            if (legendItem.text.indexOf("Perfect") > -1) {
              return false;
            }
            // code from https://github.com/chartjs/Chart.js/blob/master/src/core/core.legend.js
            // modified to prevent hiding all legend items
            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var meta = ci.getDatasetMeta(index);
            // See controller.isDatasetVisible comment
            var hidden =
              meta.hidden === null ? !ci.data.datasets[index].hidden : null;
            if (hidden) {
              var someShown = _.some(this.legendItems, function (item) {
                return item !== legendItem && !item.hidden;
              });
              if (!someShown) return;
            }
            meta.hidden = hidden;
            // We hid a dataset ... rerender the chart
            ci.update();
          },
        },
        scale: {
          ticks: {
            display: false,
            beginAtZero: true,
            max: 120,
            min: 0,
            stepSize: 20,
          },
          pointLabels: {
            fontColor: this.axeColors,
            fontSize: 14,
            fontFamily: "basier",
            lineHeight: 2,
          },
        },
        tooltips: {
          enabled: false,
        },
        hover: {
          onHover: function (e) {
            var point = this.getElementAtEvent(e);
            if (point && point.length > 0 && point[0]._datasetIndex < 4) {
              e.target.style.cursor = "pointer";
              // Prevent from flashing.
              if (
                point[0]._index !==
                _self.hoverIndex + "_" + point[0]._datasetIndex
              ) {
                _self.hoverIndex =
                  point[0]._index + "_" + point[0]._datasetIndex;
                document.getElementById("intersection-info").position = "fixed";
                document.getElementById("intersection-info").style.zIndex =
                  "99999";
                document.getElementById("intersection-info").style.top =
                  e.pageY + "px";
                document.getElementById("intersection-info").style.left =
                  e.pageX + "px";
                document.getElementById("intersection-info").style.display =
                  "block";
                document.getElementById("intersection-name").innerText =
                  _self.axes[point[0]._index].toString().replace(",", " ");
                document.getElementById("intersection-name").style =
                  "color: " +
                  _self.axeColors[point[0]._index] +
                  "; font-size: 16px;";
                document.getElementById("intersection-domain").style.display =
                  "none";
                document.getElementById("div-intersection-country").style =
                  "color: " + _self.axeColors[point[0]._index];
                document.getElementById("div-intersection-region").style =
                  "color: black";
                document.getElementById("div-intersection-income").style =
                  "color: gray; font-style: italic; font-size: 12px;";
                document.getElementById("lmic-avg").style.display = "none";
                document.getElementById(
                  "div-intersection-income"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-country-four"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-country-five"
                ).style.display = "none";
                document.getElementById(
                  "intersection-lmic-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-domain-link"
                ).style.display = "none";
                document.getElementById(
                  "intersection-country-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-region-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-income-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-theme-link"
                ).style.display = "flex";
                document.getElementById(
                  "intersection-theme-link"
                ).href = `/#/2X-Ecosystem/2X-Certification/sub-dimension?q=?${_self.getLink(
                    point[0]._datasetIndex, _self.axes[point[0]._index].toString().replace(",", " ")
                )}`;
                document.getElementById("intersection-theme-text").innerText =
                  "Sub-dimension";

                document.getElementById(
                  "div-intersection-validity-power"
                ).style.display = "none";
                document.getElementById("intersection-country").innerText =
                  "Score: " +
                  (_self.dimensionScores[point[0]._index] == 'NA' ? 'NA' : (Math.round(_self.dimensionScores[point[0]._index]) +"%")) +
                  "\n" +
                  "Year: " +
                  new Date().getFullYear();
                document.getElementById(
                  "div-intersection-region"
                ).style.display = "none";
                document.getElementById("intersection-income").innerText = "";
                document.getElementById("intersection-income").style.display =
                  "block";
              }
            } else {
              document.getElementById("intersection-info").style.display =
                "none";
              e.target.style.cursor = "default";
            }
          },
        },
      }
    );
    const _self2 = this;
    window.addEventListener("beforeprint", function () {
      const Chart = _self2.$data._chart;
      Chart.resize();
    });
    setTimeout(function () {
      _self2.$data._chart.update();
    }, 1000);
  },
};
</script>

<style scoped>
.chartjs-render-monitor {
  margin-left: -33px;
  height: 900px !important;
}
</style>
