<template>
  <button
    class="w-48 px-1 py-1 bg-gray-100 hover:bg-white flex items-center focus:shadow-outline-indigo justify-center rounded-lg transition duration-200 shadow border border-gray-300 focus:outline-none ml-2"
    :class="{ 'bg-[#201747]': isChatSidebar }"
    @click='$emit("open-chat")'
  >
    <div class="rounded-full h-5 w-5 overflow-hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-5 h-5"
        :class="isChatSidebar ? 'text-white' : 'text-[#201747]'"
      >
        <path
          :fill="isChatSidebar ? 'white' : 'black'"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
        />
      </svg>
    </div>
    <div class="px-1 text-lg sm:text-xs font-semibold w-max">
      Chat with Jessie
    </div>
  </button>
</template>
<script>
export default {
  name: "chatWithJessieButton",
  props: {
    isChatSidebar: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["open-chat"],
};
</script>
