<template>
  <ImmCard :loading="loading">
    <template>
      <div class="flex justify-between">
        <div class="font-bold text-xl text-[#2c2e35]">
          2X Certification Self-Assessment
        </div>
        <div>{{ createdAt?.split("T")[0] || null }}</div>
        <FrequencyLabel :frequency="frequency" />
      </div>
      <div class="flex gap-4">
        <elegible-badge
          v-if="elegible"
          :status="status"
          :elegible="elegible"
          text="2X Certified"
          alt-text="2X Eligible"
        ></elegible-badge>
        <not-elegible-badge
          v-else
          :status="status"
          :elegible="elegible"
          text="Not 2X Certified"
          alt-text="Not 2X Eligible"
          :noValue="!elegible"
        ></not-elegible-badge>
        <elegible-badge
          v-if="governanceScore >= 70"
          :status="status"
          :elegible="governanceScore >= 70"
          text="2X Governance"
          alt-text="2X Governance"
          :noValue="governanceScore < 70"
        ></elegible-badge>
        <not-elegible-badge
          v-else
          :status="status"
          :elegible="false"
          text="2X Governance"
          alt-text="2X Governance"
          :noValue="governanceScore < 70"
        ></not-elegible-badge>
      </div>

      <div
        v-if="status === 'completed'"
        class="grid grid-cols-2 gap-4 text-[#717686] h-52 mb-20"
      >
        <div class="flex gap-2">
          <div class="font-bold text-base text-[#4DC9BE]">
            {{ leadershipScore }}%
          </div>
          <div>Leadership</div>
        </div>
        <div class="flex gap-2">
          <div class="font-bold text-base text-[#FF501C]">
            {{ entrepreneurshipScore }}%
          </div>
          <div>Entrepreneurship</div>
        </div>
        <div class="flex gap-2">
          <div class="font-bold text-base text-[#FF501C]">
            {{ employmentScore }}%
          </div>
          <div>Employment</div>
        </div>
        <div class="flex gap-2">
          <div class="font-bold text-base text-[#FF501C]">
            {{ productsAndServicesScore }}%
          </div>
          <div>Products & Services</div>
        </div>
        <div class="flex gap-2">
          <div class="font-bold text-base text-[#FF501C]">
            {{ supplyChainScore }}%
          </div>
          <div>Supply Chain</div>
        </div>
        <div class="flex gap-2">
          <div class="font-bold text-base text-[#FF501C]">
            {{ portfolioScore }}%
          </div>
          <div>Portfolio</div>
        </div>
      </div>
      <div
        v-else-if="status === 'in-progress'"
        class="flex items-center justify-between h-24 mb-10"
      >
        <div class="w-3/5">
          <div class="text-right mb-1">
          <span class="text-[#4DC9BE] font-bold"
          >{{ completionPercentage || 0 }}%</span
          >
            Completed
          </div>
          <k-progress
            style="width: 110%"
            :percent="completionPercentage"
            color="#4DC9BE"
            :line-height="16"
            :show-text="false"
          ></k-progress>
        </div>
        <router-link
          to="/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment"
          class="flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#191b45] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center"
        >Continue
        </router-link
        >
      </div>
      <div v-else class="mt-10 h-52">
        Evaluate your company's eligibility for 2X Certification, identifying key
        areas of progress and improvement.
      </div>
      <div class="flex justify-between absolute bottom-0 pb-5 pr-12 w-full">
        <div
          class="flex justify-left items-end gap-4 w-1/2 text-[#2177C1] font-bold text-sm"
        >
          <div>&lt; &gt; API integration</div>
          <div>
            <img src="@/assets/images/dashboard/info.svg" />
            Methodology
          </div>
        </div>
        <div v-if="status === 'completed'" class="flex gap-4">
          <router-link
            :class="[
            'flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-white text-[#191b45] font-bold min-w-[140px] font-arial text-base leading-6 text-center',
            { 'opacity-50 cursor-not-allowed': investeeId },
          ]"
            to="/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment"
            :disabled="investeeId"
          >Repeat
          </router-link
          >
          <router-link
            class="flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#4dc9be] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center"
            to="/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results"
          >View results
          </router-link
          >
        </div>
        <div v-else-if="status === 'in-progress'"></div>
        <div v-else class="flex gap-4">
          <router-link
            v-if="!investeeId"
            :class="[
            'flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#191b45] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center',
            { 'opacity-50 cursor-not-allowed': investeeId },
          ]"
            to="/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment"
            :disabled="investeeId"
          >Launch
          </router-link
          >
          <span v-else class="text-gray-500">
          This user has no 2X certification data yet.
        </span>
        </div>
      </div>
    </template>
  </ImmCard>
</template>

<script>
import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";
import ElegibleBadge from "./ElegibleBadge.vue";
import NotElegibleBadge from "./NotElegibleBadge.vue";
import apiService from "@/helpers/apiServices.js";
import FrequencyLabel from "./FrequencyLabel.vue";
import ImmCard from "@/components/imm-components/dashboard/ImmCard.vue";

export default {
  name: "TwoXAssessmentCard",
  components: {
    ElegibleBadge,
    FrequencyLabel,
    ImmCard,
    NotElegibleBadge,
  },
  props: {
    user: {
      type: Object,
    },
    investeeId: {
      type: String || null,
    },
    status2xCertification: {
      type: String || null,
    },
    frequency: {
      type: String,
    },
    aggregatedView: {
      type: Boolean,
    },
    aggregatedUrl: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      status: null,
      elegible: false,
      leadershipScore: 0,
      entrepreneurshipScore: 0,
      employmentScore: 0,
      productsAndServicesScore: 0,
      supplyChainScore: 0,
      governanceScore: 0,
      portfolioScore: 0,
      createdAt: "",
      completionPercentage: 0,
      userSurveyData: [],
    };
  },
  async mounted() {
    if (this.aggregatedView) {
      this.loading = false;
    } else {
      this.initializeData(this.user);
    }
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        if (newUser) {
          this.loading = true;
          this.initializeData(newUser);
          this.loading = false;
        }
      },
    },
  },
  methods: {
    async fetchSurveyData() {
      await apiService.fetchUserSurveys("All").then(async (response) => {
        this.userSurveyData = await response.data.data;
      });
    },
    async initializeData(user) {
      // 2X DATA
      await this.fetchSurveyData(user);
      await this.fetch2XUserData();
      // TODO Verify this string
      this.status =
        this.status2xCertification === "Completed" ||
        this.status2xCertification === "Verification in Process" ||
        this.status2xCertification === "Certification Awarded"
          ? "completed"
          : this.status2xCertification === "In Progress"
          ? "in-progress"
          : "not-started";
      if (this.status === "completed") {
        await this.fetch2XCertificationResults();
      }
    },
    async fetch2XUserData() {
      try {
        if (this.userSurveyData) {
          // find the most recent createdAt from this.userSurveyData
          const mostRecentSurvey = this.userSurveyData.reduce(
            (latest, survey) =>
              new Date(survey.createdAt) > new Date(latest.createdAt)
                ? survey
                : latest,
            this.userSurveyData[0] || {}
          );

          // count the number of incomplete surveys
          const incompleteSurveys = this.userSurveyData.reduce(
            (count, survey) => (!survey.isComplete ? count + 1 : count),
            0
          );

          // calculate the percentage of completion based on 10 surveys total
          const completionPercentage = ((10 - incompleteSurveys) / 10) * 100;

          const survey = this.userSurveyData.find(
            (survey) => survey.surveyId === "2x-intake"
          );

          if (!survey) {
            throw new Error("2x-intake survey not found");
          }

          const entityMap = {
            "financial-institution": "Financial Institution",
            "fund-manager": "Fund Manager",
            "start-up": "Start-up",
            sme: "SME",
            corporate: "Corporate",
          };

          this.entityType = entityMap[survey.tag] || survey.tag;
          this.createdAt = mostRecentSurvey.createdAt;
          this.completionPercentage = completionPercentage;
        }
      } catch (error) {
        console.error("Error fetching 2X user data:", error);
      }
    },
    async fetch2XCertificationResults() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `${env.apiUrl}/certify2x/2x-certification-result?entityType=${this.entityType}&assessmentType=Full`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );

        this.governanceScore =
          Math.round(Number(data["GOVACC_2X_104"])) || null;
        this.entrepreneurshipScore = Math.round(Number(data["ENTOWN_2X_104"]));
        this.employmentScore = Math.round(Number(data["EMPLOY_2X_104"]));
        this.productsAndServicesScore = Math.round(Number(data["PROD_2X_104"]));
        this.supplyChainScore = Math.round(Number(data["SUPCH_2X_104"]));
        this.leadershipScore = Math.round(Number(data["LEAD_2X_104"]));
        this.portfolioScore = Math.round(Number(data["PORT_2X_12901"]));
        const elegible =
          data["2X_2X_Score_Overall_300"] ||
          data["2X_2X_Score_Overall_301"] ||
          data["2X_2X_Score_Overall_302"];

        this.elegible = elegible === "Yes";
        this.loading = false;
      } catch (error) {
        console.error("Error fetching 2X certification result:", error);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
