import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import store from "@/helpers/store";

class BaseAPI {
  static instance = null;

  constructor() {
    if (!BaseAPI.instance) {
      BaseAPI.instance = axios.create({
        baseURL: `${env.apiUrl}`,
        headers: {
          Authorization: `Bearer ${Cookies.get("session")}`,
        },
      });

      // Add response interceptor to handle 401 unauthorized responses
      BaseAPI.instance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          // Handle 401 unauthorized responses
          if (error.response && error.response.status === 401) {
            // Session expired, logout the user
            console.log("Session expired, logging out...");
            // Use store action to logout
            store.commit("logout");
          }
          return Promise.reject(error);
        }
      );
    }
    return BaseAPI.instance;
  }

  static getInstance() {
    if (!BaseAPI.instance) {
      new BaseAPI();
    }
    return BaseAPI.instance;
  }
}

export default BaseAPI;
