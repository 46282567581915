<template>
  <ImmCard :loading="loading">
    <template>
      <div class="flex justify-between">
        <div class="font-bold text-xl text-[#2c2e35]">Gender ROI™</div>
        <div>{{ createdAt?.split("T")[0] || null }}</div>
        <FrequencyLabel :frequency="frequency" />
      </div>
      <!--Completed-->
      <div v-if="status === 'completed'" class="mt-5">
        <div class="flex justify-between mb-5">
          <div class="flex flex-col">
            <div>
            <span class="font-bold text-2xl">{{ score }}</span
            ><span>/5</span>
            </div>
            <span>Score</span>
          </div>
          <div>{{ createdAt?.split("T")[0] || null }}</div>
        </div>
        <div class="flex justify-between mb-5">
          <div class="flex flex-col mb-5" v-if="financeScore">
            <span class="font-bold text-2xl">{{ financeScore }}</span>
            <span> Financial Processes, Products & Services </span>
          </div>
          <div v-if="user?.investor" class="flex flex-col mb-5">
            <span class="font-bold text-2xl"> 0 </span>
            <span> Portfolios aligned with goal </span>
          </div>
        </div>
        <!--Score results modal-->
        <div
          v-if="roiModal"
          class="fixed inset-0 flex items-center justify-center z-50 p-4 shadow-2xl"
        >
          <div
            class="bg-white rounded-lg shadow-lg p-6 w-1/2 h-3/4 overflow-y-auto"
          >
            <div class="flex justify-between items-center mb-4">
              <h2 class="text-2xl font-bold mb-10">Gender ROI™ Details</h2>
              <button
                @click="roiModal = false"
                class="text-gray-500 hover:text-gray-700 mb-5"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div v-for="(item, index) in scoreData[0]" :key="index" class="mb-5">
              <div
                v-for="(score, index) in item"
                :key="index"
                class="flex justify-between mb-2"
              >
                <div>{{ score.parameter }}</div>
                <span class="font-bold text-xl">{{ score.thresholdScore }}</span>
              </div>
            </div>
          </div>
        </div>
        <button
          class="bg-[#4DC9BE] text-white py-3 px-5 rounded-md mt-4 mb-10"
          @click="roiModal = true"
        >
          Show more details
        </button>
      </div>
      <!--In Progress-->
      <div
        v-else-if="status === 'in-progress'"
        class="flex items-center justify-between mt-5 pb-5"
      >
        <div class="w-3/5">
          <div class="text-right mb-1">
            <span class="text-[#4DC9BE] font-bold"> {{ percentage || 0 }}% </span>
            Completed
          </div>
          <k-progress
            style="width: 110%"
            :percent="percentage"
            color="#4DC9BE"
            :line-height="16"
            :show-text="false"
          ></k-progress>
        </div>
        <router-link
          class="flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#191b45] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center"
          to="/roi"
        >Continue</router-link
        >
      </div>
      <div v-else class="w-full mt-10 h-52">
        Track key performance metrics related to gender equality, driving
        data-driven decision-making.
      </div>
      <!--Links-->
      <div
        class="mt-8 flex gap-12 justify-between absolute bottom-0 pb-5 pr-12 w-full"
      >
        <div
          class="flex justify-left items-center gap-4 w-1/2 text-[#2177C1] font-bold text-sm"
        >
          <div><span class="mr-2">&lt; &gt;</span>API integration</div>
          <div class="flex items-center">
            <img src="@/assets/images/dashboard/info.svg" class="mr-2"/>Methodology
          </div>
        </div>
        <div v-if="status === 'completed'" class="flex justify-end gap-4 w-1/2">
          <router-link
            v-if="!investeeId"
            :class="[
            'flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-white text-[#191b45] font-bold min-w-[140px] font-arial text-base leading-6 text-center',
            { 'opacity-50 cursor-not-allowed': investeeId },
          ]"
            to="/roi"
            :disabled="investeeId"
          >
            Repeat
          </router-link>
          <router-link
            class="flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#4dc9be] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center"
            :to="
            !investeeId
              ? '/roi'
              : `/roi/roiAssessment?recordId=${user.roiSurveyRecordId}`
          "
          >
            View results
          </router-link>
        </div>
        <div v-else-if="status === 'in-progress'"></div>
        <div v-else class="flex justify-end gap-4 w-1/2 items-end mt-40">
          <router-link
            v-if="!investeeId"
            :class="[
            'flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#191b45] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center',
            { 'opacity-50 cursor-not-allowed': investeeId },
          ]"
            to="/roi"
            :disabled="investeeId"
          >
            Launch
          </router-link>
          <span v-else class="text-gray-500">
          This user has no gender ROI data yet.
        </span>
        </div>
      </div>
    </template>
  </ImmCard>
</template>

<script>
import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";
import FrequencyLabel from "./FrequencyLabel.vue";
import ImmCard from "@/components/imm-components/dashboard/ImmCard.vue";

export default {
  name: "GenderROICard",
  components: {
    FrequencyLabel,
    ImmCard,
  },
  props: {
    user: {
      type: Object,
    },
    investeeId: {
      type: String || null,
    },
    frequency: {
      type: String,
    },
    aggregatedView: {
      type: Boolean,
    },
    aggregatedUrl: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      percentage: 0,
      status: "not-started",
      score: 0,
      createdAt: "",
      scoreData: [],
      roiModal: false,
      financeScore: 0,
    };
  },
  async mounted() {
    this.loading = true;
    if (this.aggregatedView) {
      this.loading = false;
    }
  },
  async created() {
    await this.fetchGenderROIData();
    this.loading = false;
  },
  methods: {
    async fetchGenderROIData() {
      try {
        const url = !this.investeeId
          ? `${env.apiUrl}/roi/flags`
          : `${env.apiUrl}/roi/flags?recordId=${this.user.roiSurveyRecordId}`;
        const {
          data: { data },
        } = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });
        this.status =
          data?.certifiedThatAllResponsesTrueAndAccurate === "Yes"
            ? "completed"
            : data?.certifiedThatAllResponsesTrueAndAccurate === "No"
            ? "not-started"
            : "in-progress";
        this.percentage = data?.percentage;
        this.score = data?.genderRoiScore;
        this.createdAt = data?.created;
        this.scoreData = data?.scoreData;
        this.financeScore = data?.averageFinanceScore;
      } catch (error) {
        console.error("Error fetching gender ROI data:", error);
      }
    },
  },
};
</script>
