<template>
  <div
    v-if="loading"
    class="h-96 p-6 gap-6 rounded-[2em] bg-[#f4f6f9] flex flex-col justify-between relative"
  >
    <local-loader></local-loader>
  </div>
  <div v-else class="h-96 p-6 gap-6 rounded-[2em] bg-[#f4f6f9] flex flex-col justify-between relative">
    <slot></slot>
  </div>
</template>

<script>
import localLoader from "@/components/local-loader.vue";

export default {
  components: {
    localLoader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
</style>
