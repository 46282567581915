<template>
  <div class="mt-2 mb-2">
    <div class="flex space-x-2">
      <LikeButton
        :like="like"
        @click="
          (val) =>
            $emit('feedback', {
              like: val,
              dislike: val ? false : dislike,
              message,
            })
        "
      />
      <DislikeButton
        :dislike="dislike"
        @click="
          (val) =>
            $emit('feedback', {
              like: val ? false : like,
              dislike: val,
              message,
            })
        "
      />
      <a
        class="text-sm underline leading-[16px] pl-2 block"
        @click="showMessage = !showMessage"
        >Tell us more</a
      >
    </div>
    <div v-if="showMessage" class="mt-2">

      <textarea
        ref="textarea"
        class="w-full p-2 border border-gray-300 rounded-md text-sm min-w-[200px]"
        placeholder="Share your feedback! Let us know what you think or how we can improve."
        v-model="editedMessage"
        @keydown.enter.prevent="handleMessage"
      />

      <div class="w-full flex justify-end">
        <div class="flex gap-2">
          <EquiloButton
            classes=""
            text="Cancel"
            type="button"
            bg-color="cancel"
            @on-click="showMessage = false"
          />
          <EquiloButton
            classes=""
            text="Send feedback"
            type="button"
            bg-color="primary"
            @on-click="handleMessage"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LikeButton from "./feedback/likeButton.vue";
import DislikeButton from "./feedback/dislikeButton.vue";
import EquiloButton from "@/components/basics/EquiloButton.vue";

export default {
  name: "MessageFeedback",
  props: {
    like: {
      type: Boolean,
      required: false,
      default: null,
    },
    dislike: {
      type: Boolean,
      required: false,
      default: null,
    },
    message: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showMessage: false,
      editedMessage: this.message,
    };
  },
  methods: {
    handleMessage() {
      this.$emit("feedback", {
        like: this.like,
        dislike: this.dislike,
        message: this.editedMessage,
      });
      this.showMessage = false;
    },
    getState() {
      return {
        like: this.like,
        dislike: this.dislike,
        message: this.editedMessage,
      };
    },
  },
  components: {
    LikeButton,
    DislikeButton,
    EquiloButton,
  },
  emits: ["feedback"],
  watch: {
    showMessage(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.textarea.focus();
        });
      }
    },
  },
};
</script>
