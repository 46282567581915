<template>
  <div class="spiderchartContent" id="chart" v-if="!noInfo">
    <div class="z-20 relative">
      <SpiderchartGoverance
        :paddingL="40"
        :paddingR="40"
        :paddingT="40"
        :paddingB="40"
        :governanceColors="governanceColors"
        :chartData="govChartData"
        :assessmentYear="assessmentYear"
        :key="govData"
        class="z-10 overflow-visible"
        style="position: relative; height: 100%; width: 100%"
      ></SpiderchartGoverance>
      <div
        v-if="
          this.labels && Array.isArray(this.labels) && this.labels.length > 0
        "
        class="flex items-center"
        :style="
          'color: ' +
          governanceColors[0] +
          '; position: absolute; left: 35%; top: 5%;'
        "
      >
        <svg
          width="26"
          height="24"
          viewBox="0 0 30 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        ></svg>
        <span style="font-weight: bold"> {{ labels[0] }}</span>
      </div>
      <div
        v-if="
          this.labels && Array.isArray(this.labels) && this.labels.length > 1
        "
        class="flex items-center"
        :style="
          'color: ' +
          governanceColors[1] +
          '; position: absolute; left: 71%; top: 62%;'
        "
      >
        <svg
          width="26"
          height="24"
          viewBox="0 0 30 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        ></svg>
        <span class="pt-5" style="font-weight: bold"> {{ labels[1] }}</span>
      </div>
      <div
        v-if="
          this.labels && Array.isArray(this.labels) && this.labels.length > 2
        "
        class="flex items-center"
        :style="
          'color: ' +
          governanceColors[2] +
          '; position: absolute; right: 80%; top: 66%;'
        "
      >
        <svg
          width="26"
          height="24"
          viewBox="0 0 30 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        ></svg>
        <span style="font-weight: bold"> {{ labels[2] }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import SpiderchartGoverance from "@/components/charts/my2xCertificationGov-SpiderChart.vue";

export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
    certificationScoreData: {
      type: [Object, Array],
      default: null,
    },
    assessmentYear: {
      type: [String, null],
      default: null,
    },
  },
  components: {
    SpiderchartGoverance,
  },
  data() {
    return {
      govChartData: [],
      govData: "",
      title: "",
      labels: [],
      governanceColors: [
        "#395AFF",
        "#4C9F38",
        "#DDA63A",
        "#A21942",
        "#19486A",
        "#666461",
      ],
      noInfo: false,
    };
  },
  mounted() {
    this.title = this.info.title;

    if (Array.isArray(this.info?.questions) && this.info?.questions.length) {
      this.info.questions.forEach((question) => {
        this.labels.push(question.surveyQuestion);
        if (question.value && question.value !== "NA")
          this.govChartData.push(question.value);
      });
    }
    if (!this.govChartData.length) {
      this.noInfo = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.spiderchartContent {
  width: 70%;
  .entrepreneurship-fund {
    position: absolute;
    left: 32%;
    top: 5%;
  }
  .entrepreneurship-company {
    position: absolute;
    left: 35%;
    top: 5%;
  }
  .leadership-fund {
    position: absolute;
    left: 68%;
    top: 25%;
  }
  .leadership-company {
    position: absolute;
    left: 68%;
    top: 30%;
  }
  .employment-fund {
    position: absolute;
    left: 68%;
    top: 58%;
  }
  .employment-company {
    position: absolute;
    left: 60%;
    bottom: 20%;
  }
  .product-service-fund {
    position: absolute;
    left: 31%;
    top: 79%;
  }
  .product-service-company {
    position: absolute;
    left: 10%;
    bottom: 20%;
  }
  .supplychain-fund {
    position: absolute;
    right: 77%;
    top: 58%;
  }
  .supplychain-company {
    position: absolute;
    right: 76%;
    top: 30%;
  }
}
</style>
