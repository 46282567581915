<template>
  <div class="markdown-message" v-html="messageHtml" />
</template>
<script>
import markdownit from "markdown-it";
import markdownItFootnote from "markdown-it-footnote";
import mila from "markdown-it-link-attributes";

const md = markdownit()
  .use(markdownItFootnote)
  .use(mila, {
    attrs: {
      target: "_blank",
      rel: "noopener noreferrer",
    },
  });

export default {
  name: "MessageMarkdown",
  props: {
    message: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      markdown: null,
      md,
    };
  },
  computed: {
    messageHtml() {
      if (!this.message) {
        return this.message;
      }
      return this.md.render(this.message);
    },
  },
};
</script>
<style lang="scss">
.markdown-message {
  @apply text-lg sm:text-sm break-words text-white;
  li {
    @apply list-item;
  }
  ul {
    @apply list-disc list-inside;
  }
  ol {
    @apply list-decimal list-inside;
    & > li {
      // change style of the p element going after ::marker
      & > p:first-child {
        display: inline;
      }
      @apply mb-2;
    }
    & > li.footnote-item {
      // change style of the p element going after ::marker
      & > p:first-child {
        display: inline;
      }
      @apply mb-2;
    }
  }
  .footnotes-sep {
    @apply mt-16;
    @apply mb-4;
  }
  section.footnotes {
    @apply mb-0;
    @apply text-xs;
    ol {
      li {
        @apply mb-1;
      }
    }
  }
  p,
  ul,
  ol {
    @apply mb-4;
  }
  blockquote {
    @apply border-l-4 border-gray-300 pl-4;
  }
  code {
    @apply px-4 mb-4 block;
  }
  pre {
    @apply whitespace-pre-wrap;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold mb-2 text-white;
  }
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  h4 {
    @apply text-base;
  }
  h5 {
    @apply text-sm;
  }
  h6 {
    @apply text-xs;
  }
}
</style>
