import { render, staticRenderFns } from "./2XCard.vue?vue&type=template&id=572d6836&scoped=true"
import script from "./2XCard.vue?vue&type=script&lang=js"
export * from "./2XCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572d6836",
  null
  
)

export default component.exports