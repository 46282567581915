<template>
  <div class="flex flex-col">
    <span class="title">{{ info?.title }}</span>
    <barChartVertical
      :chartLabels="[String(assessmentYear)]"
      style="margin-left: -33px"
      :width="300"
      :height="400"
      :label1="info?.surveyQuestion"
      :totalValue="[100]"
      :filledValue="[info?.value]"
    >
    </barChartVertical>
  </div>
</template>

<script>
import barChartVertical from "./baseBar.vue";

export default {
  name: "barChartVertical",
  props: {
    info: {
      type: Object,
      default: null,
    },
    certificationScoreData: {
      type: [Object, Array],
      default: null,
    },
    assessmentYear: {
      type: [String, null],
      default: null,
    },
  },
  components: {
    barChartVertical,
  },
  data() {
    return {};
  },
};
</script>

<style>
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #2c2e35;
  margin-bottom: 1.25rem;
}
</style>
