import axios from "axios";
import store from "@/helpers/store";

// Add global axios interceptor to handle 401 unauthorized responses
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle 401 unauthorized responses
    if (error.response && error.response.status === 401) {
      // Session expired, logout the user
      console.log("Session expired, logging out...");
      // Use store action to logout
      store.commit("logout");
    }
    return Promise.reject(error);
  }
);

export default axios;
