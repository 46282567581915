<template>
  <div>
    <label class="flex gap-2 items-center mb-4">
      <input type="checkbox" v-model="hideEmptyRows" />
      Hide empty rows
    </label>
    <div class="table-container">
      <table class="inner-table">
        <thead>
          <tr class="table-heading-row">
            <th v-for="(head, index) in heading" :key="`${tableType}-${index}-${head}`"> {{ head }}</th>
            <th v-if="displayComment">Comment</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="table-content-row"
            v-for="(data, index) in newTableData"
            :key="`${tableType}-${index}-${data.rowHeading}`"
            v-show="!isRowEmpty(data) || !hideEmptyRows"
          >
            <td>
              <div
                class="flex justify-between items-center"
                :class="{'font-bold': isBoldItem(data.rowHeading), 'italic': isItalicItem(data.rowHeading)}"
              >
                {{ data.rowHeading }}
<!--                <img-->
<!--                  v-if="data.isVisible"-->
<!--                  @click="data.isVisible = !data.isVisible"-->
<!--                  class="cursor-pointer" alt="arrow-up"-->
<!--                  src="@/assets/images/arrow-up.svg"-->
<!--                />-->
<!--                <img-->
<!--                  v-if="!data.isVisible"-->
<!--                  @click="data.isVisible = !data.isVisible"-->
<!--                  class="cursor-pointer" alt="arrow-down"-->
<!--                  src="@/assets/images/arrow-down.svg"-->
<!--                />-->
              </div>
<!--              <div class="difference-data" v-if="data.isVisible">-->
<!--                <div>Difference</div>-->
<!--                <div class="mt-3">%</div>-->
<!--              </div>-->
            </td>
            <td class="text-right" v-for="(data , index) in data.data" :key="index">
              <div>{{ formatToCurrency(data.value) }}</div>
              <div class="difference-data" v-if="data.isVisible">
                <div :class="data.class">+{{ data.diffPercent }}</div>
                <div class="mt-3" :class="data.class">{{ data.diffAmount }}</div>
              </div>
            </td>
            <td v-if="displayComment" class="w-96">
              <div class="flex justify-between">
                <template v-if="data.comments.length > 0">
                  <div class="flex flex-col gap-2">
                    <span class="text-tiny text-wrap">{{ lastComment(data.comments) }}</span>
                    <button
                      type="button"
                      class="[ h-[28px] w-auto ][ py-1 ][ text-sm text-[#2177C1] font-bold ][ flex items-center gap-2 ]"
                      @click="openCommentModal(data.rowHeading, data.comments)"
                    >
                      Read more
                      <img class="w-[13px]" src="@/assets/images/IMM/vector-navy.svg" alt="edit-icon" />
                    </button>
                  </div>
                </template>
                <template v-else>
                  <button
                    class="[ h-[28px] w-[163px] ][ py-1 px-3 ][ border-black border-2 rounded-lg ][ text-sm font-bold ][ flex justify-center items-center gap-2 ]"
                    @click="openCommentModal(data.rowHeading, [])"
                  >
                    Make a comment
                    <img class="w-[13px]" src="@/assets/images/IMM/vector-black.svg" alt="edit-icon" />
                  </button>
                </template>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Modal v-if="showCommentModal" @on-close="showCommentModal = false">
        <AddCommentForm
          :initialComments="currentComments"
          @save-comment="saveComment"
          @cancel-comment="cancelComment"
        />
      </Modal>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import AddCommentForm from "@/components/imm-components/AddCommentForm.vue";
import { formatToCurrency } from "@/helpers/utils";

const ROW_HEADERS = {
  "IS - Actual": {
    "Net revenue": { bold: true, italic: true },
    "Gross Profit": { bold: true, italic: true },
    "Total Operating Expenses": { bold: true, italic: true },
    "Net Other Operating Income/Expenses": { bold: true, italic: true },
    "Adjusted EBITDA for Unusual Items": { bold: true, italic: true },
    "Net Income from Operating Activities (EBITDA)": { bold: true, italic: true },
    "Depreciation & Amortization": { bold: true, italic: true },
    "Net Income from Operating Activities after D&A (EBIT)": { bold: true, italic: true },
    "Net Interest Income": { bold: true, italic: true },
    "Net Other Financial Income": { bold: true, italic: true },
    "Profit Before Tax from Continued Operations": { bold: true, italic: true },
    "Profit from Discontinued Operations": { bold: true, italic: true },
    "Total Profit Before Tax from Continued Operations": { bold: true, italic: true },
    "Net Income": { bold: true, italic: true },
    "Exchange Differences on Translating Foreign Operations": { bold: true, italic: true },
    "Other Comprehensive Income": { bold: true, italic: true },
    "Total Comprehensive Income": { bold: true, italic: true }
  },
  "BS - Actual": {
    "Trade Receivable, Net of Allowance": { bold: true, italic: true },
    "Trade & Other Accounts Receivable": { bold: true, italic: true },
    "Inventories, Net of Provision": { bold: true, italic: true },
    "Short-Term Investments, Net of Provisions": { bold: true, italic: true },
    "Total Other Current Assets": { bold: true, italic: true },
    "Total Current Assets": { bold: true, italic: true },
    "Net Property, Plant & Equipment & Intangible Assets": { bold: true, italic: true },
    "Biological Assets": { bold: false, italic: false },
    "Total Other Non-Current Assets": { bold: true, italic: true },
    "TOTAL NON-CURRENT ASSETS": { bold: true, italic: true },
    "TOTAL ASSETS": { bold: true, italic: true },
    "TOTAL SHORT-TERM LIABILITIES": { bold: true, italic: true },
    "TOTAL LONG-TERM LIABILITIES": { bold: true, italic: true },
    "Capital Sources & Funds": { bold: true, italic: true },
    "Retained Earnings (roll-forward)": { bold: true, italic: true },
    "Retained Earnings": { bold: true, italic: true },
    "Other Comprehensive Income": { bold: true, italic: true },
    "TOTAL OWNERS' EQUITY": { bold: true, italic: true },
    "TOTAL LIABILITIES & OWNERS' EQUITY": { bold: true, italic: true }
  },
  "CF - Actual": {
    "Prior Period Reference to BS": { bold: true, italic: true },
    "BEGINNING CASH": { bold: true, italic: true },
    "Net Income (inc. Discontinued Operations)": { bold: true, italic: true },
    "+/- Change in Other Comprehensive Income": { bold: true, italic: true },
    "Operating Profit Before Working Capital Charges": { bold: true, italic: true },
    "NET CASH FLOW FROM OPERATING ACTIVITIES": { bold: true, italic: true },
    "NET CASH FLOW FROM INVESTING ACTIVITIES": { bold: true, italic: true },
    "NET CASH FLOW FROM FINANCING ACTIVITIES": { bold: true, italic: true },
    "NET CASH FLOW FOR THE PERIOD": { bold: true, italic: true },
    "Exchange Rate Gain/Losses on Cash & Cash Equivalents": { bold: true, italic: true },
    "ENDING CASH": { bold: true, italic: true }
  }
};

export default {
  name: "SalesTable",
  components: { Modal, AddCommentForm },
  props: {
    tableType: {
      type: String,
      required: true
    },
    heading: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    isInvestor: {
      type: Boolean,
      default: false
    },
    displayComment: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      newTableData: [],
      showCommentModal: false,
      currentRowHeading: "",
      currentComments: [],
      hideEmptyRows: true,
    };
  },
  methods: {
    formatToCurrency,
    isRowEmpty(rowData) {
      const sumReducer = (accumulator, currentData) => accumulator + currentData?.value ?? 0;
      return rowData.data.reduce(sumReducer, 0) === 0;
    },
    isBoldItem(item) {
      const tableRowHeaders = ROW_HEADERS[this.tableType];
      return tableRowHeaders[item]?.bold;
    },
    isItalicItem(item) {
      const tableRowHeaders = ROW_HEADERS[this.tableType];
      return tableRowHeaders[item]?.italic;
    },
    openCommentModal(rowHeading, comments) {
      this.showCommentModal = true;
      this.currentRowHeading = rowHeading;
      this.currentComments = comments;
    },
    cancelComment() {
      this.showCommentModal = false;
      this.currentRowHeading = "";
      this.currentComments = [];
    },
    saveComment(comment) {
      this.$emit("save-comment", this.tableType, this.currentRowHeading, comment);
      this.cancelComment();
    },
    truncate(value) {
      if (!value) return "";
      return value.length > 100 ? value.substring(0, 100) + "..." : value;
    },
    lastComment(comments) {
      if (comments.length > 0) {
        return this.truncate(comments[0].comment);
      }
      return null;
    }
  },
  watch: {
    tableData(newVal) {
      this.newTableData = [...newVal];
    }
  }
};
</script>
<style lang="scss" scoped>
.table-container {
  gap: 20px;
  border-radius: 16px;
  border: 1px solid #E1E3E9;
  overflow-x: auto;

  .inner-table {
    width: 100%;

    th, td {
      border: 1px solid #E1E3E9;
    }

    .table-heading-row {
      th {
        padding: 12px;
        gap: 32px;
        border-top: none;
        background: linear-gradient(0deg, #F4F6F9, #F4F6F9), linear-gradient(0deg, #E1E3E9, #E1E3E9);
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #2C2E35;
      }

      th:first-child {
        border-top-left-radius: 16px;
        border-left: none;
        min-width: 200px;
      }

      th:last-child {
        border-top-right-radius: 16px;
        border-right: none;
      }
    }

    .table-content-row {
      td {
        border-bottom: none;
        height: 34px;
        padding: 12px;
        gap: 1px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2C2E35;

        .difference-data {
          background: linear-gradient(0deg, #F4F6F9, #F4F6F9), linear-gradient(0deg, #E1E3E9, #E1E3E9);
          border-top: 1px solid #E1E3E9;
        }
      }

      td:first-child {
        border-left: none;
        min-width: 200px;
      }

      td:last-child {
        border-right: none;
      }

      .unaudit-section {
        span {
          gap: 8px;
        }
      }
    }
  }

  .red {
    color: #CB2E00;
  }

  .green {
    color: #13A094;
  }
}
</style>
