<template>
  <div v-if="suggestions && suggestions.length > 0">
    <div class="mt-4">
      <div
        class="py-2 border-2 px-4 rounded-2xl border-gray-300 cursor-pointer mb-2 mr-2 inline-block transition-all ease-in-out hover:border-[#201747] group"
        v-for="(suggestion, index) in suggestions"
        :key="index"
        @click="selectSuggestion(suggestion)"
      >
        <div class="font-semibold text-black text-lg sm:text-sm break-words">
          {{ suggestion.title }}
          <span class="text-gray-500 font-normal">{{ suggestion.subTitle }}</span>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "messageSuggestions",
  emits: ["selected-chat-suggestion"],
  props: {
    suggestions: {
      type: Array,
      required: false,
    },
  },
  methods: {
    selectSuggestion(suggestion) {
      this.$emit("selected-chat-suggestion", suggestion);
    },
  },
};
</script>
