<template>
  <div class="flex flex-col items-center">
    <component
      class="border mt-8 p-8"
      :is="currentComponent"
      :info="input"
      :certificationScoreData="certificationScoreData"
      :oldCertificationScoreData="oldCertificationScoreData"
      :oldInfo="oldInfo"
      :clustered="clustered"
      @openEditModalFromCustomComponent="openingRequestEditModal"
      :assessmentYear="assessmentYear"
      :oldAssessmentYear="oldAssessmentYear"
    ></component>
    <span
      v-if="info.textForChartInfo"
      class="rounded-md p-2 text-md text-center max-w-[25rem]"
      v-html="formattedTextForChartInfo"
    ></span>
  </div>
</template>

<script>
import donut from "./donut.vue";
import table from "./table.vue";
import list from "./list.vue";
import spiderChart from "./spiderChart.vue";
import barChart from "./barChart.vue";
import openText from "./openText.vue";
import number from "./number.vue";
import clustered from "./ClusteredChart.vue";

export default {
  props: {
    type: {
      type: String,
      default: null,
    },
    info: {
      type: Object,
      default: null,
    },
    certificationScoreData: {
      type: [Object, Array],
      default: null,
    },
    clustered: {
      type: Boolean,
      default: false,
    },
    oldInfo: {
      type: Object,
      default: null,
    },
    oldCertificationScoreData: {
      type: [Object, Array],
      default: null,
    },
    assessmentYear: {
      type: [String, null],
      default: null,
    },
    oldAssessmentYear: {
      type: [String, null],
      default: null,
    },
  },
  components: {
    donut: donut,
    "custom-table": table,
    list: list,
    spiderChart: spiderChart,
    barChart,
    openText: openText,
    number: number,
    clustered: clustered,
  },
  data() {
    return {};
  },
  computed: {
    currentComponent() {
      switch (this.type.trim()) {
        case "Donut chart":
          return "donut";
        case "CustomTable":
          return "custom-table";
        case "List":
          return "list";
        case "CustomSpiderChart":
          return "spiderChart";
        case "Bar graph":
          return "barChart";
        case "Open Text":
          return "openText";
        case "Single select":
          return "openText";
        case "Number":
          return "number";
        case "Text":
          return "openText";
        case "Clustered":
          return "clustered";
        default:
          return "donut";
      }
    },
    input() {
      switch (this.type) {
        case "Donut chart":
          return this.info;
        case "CustomTable":
          return this.info;
        case "List":
          return this.info;
        case "CustomSpiderChart":
          return this.info;
        case "Bar graph":
          return this.info;
        case "Open Text":
          return this.info;
        case "Single select":
          return this.info;
        case "Number":
          return this.info;
        case "Text":
          return this.info;
        case "Clustered":
          return this.info;
        default:
          return this.info;
      }
    },
    formattedTextForChartInfo() {
      if (!this.info || !this.info.textForChartInfo) {
        return "";
      }
      return this.info.textForChartInfo.replace(
        /(\d+)/g,
        "<strong>$1</strong>"
      );
    },
  },
  methods: {
    openingRequestEditModal(info) {
      this.$emit("openEditModal", info);
      this.$emit("openEditModalFromCustomComponent", info); // for clustured charts only
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  border-radius: 16px;
  border: 1px solid #e1e3e9;
}
</style>
