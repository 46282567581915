<template>
  <div>
    <div
      class="text-black-90 font-arial tracking-normal text-[20px] font-bold leading-[28px] flex w-full"
      v-if="indicator.title !== 'Portfolio'"
    >
      <div class="flex flex-1 w-full items-center justify-between mr-5">
        <h4 class="text-xl pb-6">
          {{
            indicator.title == "General"
              ? "General Information"
              : indicator.title
          }}
        </h4>
      </div>
      <router-link
        v-if="
          !redirectionNotAllowed?.includes(
            indicator?.title?.toLowerCase().trim()
          )
        "
        class="pr-5 ml-auto"
        :to="
          !query
            ? `${subDimensionPath}?q=${encodeURIComponent(
                indicator.title
              )}&fromVerifier=true`
            : `${subDimensionPath}?q=${encodeURIComponent(
                indicator.title
              )}&teamId=${query}&entity=${entityChosen}&fromVerifier=true`
        "
      >
        <div class="tooltip">
          <img
            class="cursor-pointer"
            src="@/assets/images/2x_certify/Button.svg"
          />
          <span class="tooltiptext"> Click to learn more </span>
        </div>
      </router-link>
    </div>
    <div class="flex flex-col w-full">
      <div class="w-full">
        <div v-if="indicator.values && indicator.values.length > 0">
          <div v-for="(group, index) in groups" :key="index">
            <div v-if="group.type == 'Donut chart'">
              <DonutIndicatorView
                :values="group.values"
                :certificationScoreData="certificationScoreData"
                :oldValues="oldGroups?.[index]?.values ? oldGroups?.[index]?.values : []"
                :oldCertificationScoreData="oldCertificationScoreData"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
                :assessmentYear="assessmentYear"
                :oldAssessmentYear="oldAssessmentYear"
              />
            </div>
            <div
              v-else-if="group.type == 'Text' || group.type == 'Single select'"
            >
              <OpenTextView
                :values="group.values"
                :badges="badges"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
                :assessmentYear="assessmentYear"
                :oldAssessmentYear="oldAssessmentYear"
              />
            </div>
            <div v-else-if="group.type == 'CustomTable'">
              <CustomTableView
                :values="group.values"
                :certificationScoreData="certificationScoreData"
                :oldValues="oldGroups?.[index]?.values ? oldGroups?.[index]?.values : []"
                :oldCertificationScoreData="oldCertificationScoreData"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
                :assessmentYear="assessmentYear"
                :oldAssessmentYear="oldAssessmentYear"
              />
            </div>
            <div v-else-if="group.type == 'List'">
              <ListIndicatorView
                :values="group.values"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
                :assessmentYear="assessmentYear"
                :oldAssessmentYear="oldAssessmentYear"
              />
            </div>
            <div v-else-if="group.type == 'Clustered'">
              <div v-for="(value, k) in group.values" :key="k">
                <ClusteredChart
                  :info="value"
                  :certificationScoreData="certificationScoreData"
                  :assessmentYear="assessmentYear"
                  :oldAssessmentYear="oldAssessmentYear"
                />
              </div>
            </div>
            <div
              v-if="group.type === 'Number' || group.type === 'Bar graph'"
              class="w-full justify-between"
              :class="{ flex: group.type != 'Number' }"
            >
              <div
                v-for="(value, k) in group.values"
                :key="k"
                :class="{ 'flex justify-between': value.chartType == 'Number' }"
              >
                <dynamic-render
                  :type="value.chartType"
                  :info="value"
                  :certificationScoreData="certificationScoreData"
                  :oldCertificationScoreData="oldCertificationScoreData"
                  :assessmentYear="assessmentYear"
                  :oldAssessmentYear="oldAssessmentYear"
                />
                <div
                  class="flex gap-2 items-start"
                  :class="{ ' justify-end': value.chartType == 'Number' }"
                >
                  <div
                    class="flex items-center gap-3 justify-end px-2"
                    v-if="
                      $route.path.includes('/admin') &&
                      value?.surveyQType != 'Calculated'
                    "
                  >
                    <img
                      src="@/assets/images/edit-dark.svg"
                      alt="edit-icon"
                      class="h-6 w-6 cursor-pointer"
                      @click="openEditModal(value)"
                    />
                    <verification-dropdown :value="value" />
                    <MLVerifierValue
                      :value="value"
                      :teamId="$route.query?.teamId"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dynamicRender from "./dynamic-render.vue";
import DonutIndicatorView from "./DonutIndicatorView.vue";
import OpenTextView from "./OpenTextView.vue";
import CustomTableView from "./CustomTableView.vue";
import ListIndicatorView from "./ListIndicatorView.vue";
import MLVerifierValue from "./MLVerifierValue.vue";
import VerificationDropdown from "./verificationDropdown.vue";
import ClusteredChart from "./ClusteredChart.vue";

export default {
  name: "IndicatorView",
  data: function () {
    return {
      groups: [],
      oldGroups: [],
      redirectionNotAllowed: [
        "general",
        "2x score overall",
        "thematic scores",
        "justice, equity, diversity, and inclusion (jedi)",
        "health & well-being",
        "care",
        "gender-based violence and harassment (gbvh)",
        "pay",
      ],
    };
  },
  props: {
    indicator: Object,
    oldIndicator: [Array, Object],
    query: String,
    entityChosen: String,
    certificationScoreData: [Array, Object],
    oldCertificationScoreData: [Array, Object],
    subDimensionPath: String,
    MLverifiedData: Array,
    assessmentYear: String,
    oldAssessmentYear: String,
    badges: Object,
    dimensionsCode: Object,
  },
  components: {
    "dynamic-render": dynamicRender,
    DonutIndicatorView,
    OpenTextView,
    CustomTableView,
    ListIndicatorView,
    MLVerifierValue,
    VerificationDropdown,
    ClusteredChart,
  },
  methods: {
    openEditModal(item) {
      this.$emit("openEditModal", item);
    },
    setVerifiedStatus(value, status) {
      this.$emit("setVerifiedStatus", value, status);
    },
    processOldIndicatorsData() {
      let clone = JSON.parse(JSON.stringify(this.oldIndicator));
      if (clone?.values?.length > 0) {
        let lastvalue = clone.values.shift();
        let groups = [{ type: lastvalue.chartType, values: [lastvalue] }];
        for (let index in clone.values) {
          let value = clone.values[index];
          let lastType = groups[groups.length - 1].type;
          if (value.chartType != lastType) {
            groups.push({ type: value.chartType, values: [value] });
          } else {
            groups[groups.length - 1].values.push(value);
          }
        }
        this.oldGroups = groups;
      }
    },
  },
  async mounted() {
    let clone = JSON.parse(JSON.stringify(this.indicator));
    if (clone.values.length > 0) {
      let lastvalue = clone.values.shift();
      let groups = [{ type: lastvalue.chartType, values: [lastvalue] }];
      for (let index in clone.values) {
        let value = clone.values[index];
        let lastType = groups[groups.length - 1].type;
        if (value.chartType != lastType) {
          groups.push({ type: value.chartType, values: [value] });
        } else {
          groups[groups.length - 1].values.push(value);
        }
      }
      this.groups = groups;
    }
    if(this.oldIndicator) {
      this.processOldIndicatorsData();
    }
  },
};
</script>
<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  font-size: 18px;
  font-weight: bold;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  letter-spacing: normal;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 30px;
  top: 30px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  right: 95%;
}
</style>
