<template>
    <div class="circle-chart tooltip">
        <div class="blue-circle">
            <svg class="m-auto" width="150" height="150" viewBox="-18.75 -18.75 187.5 187.5" version="1.1"
                xmlns="http://www.w3.org/2000/svg" style="transform:rotate(-90deg)">
                <circle r="65" cx="75" cy="75" fill="transparent" stroke="#f4f6f9" stroke-width="27"
                    stroke-dasharray="408.2px" stroke-dashoffset="0"></circle>
                <circle r="65" cx="75" cy="75" stroke="#19486A" stroke-width="27" stroke-linecap="round"
                    :stroke-dashoffset="blueChartStrokeValue" fill="transparent" stroke-dasharray="408.2px"></circle>
                <text x="70px" y="80px" fill="#2c2e35" font-size="14px" font-weight="bold"
                    style="transform:rotate(90deg) translate(0px, -146px)">{{ chartData }}%</text>
            </svg>
        </div>
        <div class="yellow-circle relative " style="bottom: 200px;">
            <svg class="m-auto" width="250" height="250" viewBox="-31.25 -31.25 312.5 312.5" version="1.1"
                xmlns="http://www.w3.org/2000/svg" style="transform:rotate(-90deg)">
                <circle r="115" cx="125" cy="125" fill="transparent" stroke="#f4f6f9" stroke-width="27"
                    stroke-dasharray="722.2px" stroke-dashoffset="0"></circle>
                <circle r="115" cx="125" cy="125" stroke="#FF3A21" stroke-width="27" stroke-linecap="round"
                    :stroke-dashoffset="yellowChartStrokeValue" fill="transparent" stroke-dasharray="722.2px"></circle>
                <text x="120px" y="170px" fill="#2c2e35" font-size="14px" font-weight="bold"
                    style="transform:rotate(90deg) translate(0px, -146px)">{{ OldchartData }}%</text>
            </svg>
        </div>
        <span class="tooltip-text bg-white rounded-lg shadow-xl border text-sm border-gray-200 font-medium">
            <div style="color: #19486A">{{
                created[0]
            }} - {{ chartData }}% </div>
            <br />
            <br />
            <div style="color: #FF3A21">{{
                created[1]
            }} - {{ OldchartData }}%</div>
        </span>
    </div>
</template>
<script>
export default {
    name: 'MultipleDonutChart',
    props: ['chartData', 'created', 'color', 'OldchartData'],
    data: function () {
        return {
            labels: [],
            datasets: [],
        }
    },
    methods: {
    },
    mounted() { },
    computed: {
        blueChartStrokeValue() {
            let value;
            const stroke = 408.2;
            value = (stroke / 100) * (100 - this.chartData);
            return value;
        },
        yellowChartStrokeValue() {
            let value;
            const stroke = 722.2;
            value = (stroke / 100) * (100 - this.OldchartData)
            return value;
        },
    }
}
</script>
<style lang="scss" scoped>
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltip-text {
    padding: 20px;
    visibility: hidden;
    background-color: #fff;
    text-align: left;
    position: absolute;
    z-index: 99;
    left: 28%;
    top: 30%;
    width: auto;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}

.tooltip .tooltip-text::after {
    right: 95%;
}
</style>
