<template>
  <div>
    <!-- Add custom navigation at the botton -->
    <!-- TODO: we could refactor this into it's own component and make it more dynamic -->
    <div
      v-if="survey && !showPopup && showCustomNavigation"
      class="ml-[25em] absolute bottom-[10em] w-1/2 bg-transparent z-50 mt-10"
    >
      <!--  <select
        class="mb-4 p-2 border border-gray-200 rounded-md w-full sd-input sd-dropdown dropdown-chevron"
        v-model="currentPageNo"
        @change="jumpToPage($event.target.value)"
      >
        <option
          v-for="(_, index) in survey.visiblePages"
          :key="index"
          :value="index"
        >
          Page {{ index + 1 }}
        </option>
      </select> -->

      <div class="flex justify-between items-center">
        <div class="flex gap-2">
          <!-- Previous button -->
          <button
            v-if="currentPageNo !== 0 && !showCompleteButton"
            class="px-4 py-2 bg-[#0077C8] text-white rounded-md hover:bg-[#005a96] transition-colors"
            @click="prevPage"
          >
            Previous Page
          </button>
          <!-- Next button -->
          <button
            v-if="
              currentPageNo !== survey.visiblePages.length - 1 &&
              !showCompleteButton
            "
            class="px-4 py-2 bg-[#0077C8] text-white rounded-md hover:bg-[#005a96] transition-colors"
            @click="nextPage"
          >
            Next Page
          </button>
          <!-- Preview button -->
          <button
            v-if="showPreviewButton"
            class="px-4 py-2 bg-[#0077C8] text-white rounded-md hover:bg-[#005a96] transition-colors"
            @click="previewSurvey"
          >
            Preview
          </button>
          <!-- Complete button -->
          <button
            v-if="showCompleteButton"
            class="px-4 py-2 bg-[#0077C8] text-white rounded-md hover:bg-[#005a96] transition-colors"
            @click="completeSurvey"
          >
            Complete
          </button>
          <!-- Custom component button -->
          <button
            v-if="showCustomComponentButton"
            class="px-4 py-2 bg-[#0077C8] text-white rounded-md hover:bg-[#005a96] transition-colors"
            @click="customComponentButtonAction"
          >
            Next Page
          </button>
        </div>
        <div class="text-gray-600">
          <span>
            Page {{ currentPageNo + 1 }} of {{ survey?.visiblePages?.length }}
          </span>
        </div>
      </div>
    </div>
    <div class="my-10 mx-10 p-5">
      <div class="flex justify-center">
        <button
          v-if="localAnswerAgain"
          class="mt-2 mb-5 bg-red-500 hover:bg-red-700 text-white text-2xl font-bold py-2 px-4 rounded-lg"
          @click="deleteSurveyData"
        >
          Delete previous answers
        </button>
      </div>
      <SaveProgressModal
        v-if="showSaveProgressModal"
        @close="showSaveProgressModal = false"
      />
      <transition
        name="fade"
        mode="out-in"
        enter-active-class="transition ease-out duration-500 transform"
        leave-active-class="transition ease-in duration-500 transform"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="toastVisible && error"
          class="toast"
          style="background-color: red"
        >
          An error occurred
        </div>
        <div v-else-if="toastVisible" class="fixed top-1/3 right-1/2 toast">
          Changes have been saved!
        </div>
      </transition>
      <!-- Survey component -->
      <div v-if="!showPopup">
        <div v-if="!loading">
          <!-- Tabs section -->
          <div
            v-if="displayPageTabs"
            class="flex gap-4 border-b border-gray-200 mt-4"
          >
            <TabView
              v-for="(pageTab, index) in pageTabs"
              :key="pageTab.title"
              :tab-view="currentPageNo?.toString() ?? '0'"
              :tab="index.toString()"
              :label="pageTab?.title ?? ''"
              @click="jumpToPage(index)"
            />
          </div>

          <Survey
            v-if="!showCustomComponent || showSinglePagePreview"
            class="survey"
            :survey="survey"
          />
        </div>
        <div v-else class="flex items-center justify-center">
          <div class="flex space-x-2 animate-pulse">
            <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
            <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
            <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
          </div>
        </div>
      </div>
      <div v-else>
        <popup
          v-if="surveyId == 'investor-profile'"
          :text="survey?.completedHtml"
          :surveyId="surveyId"
          @surveyCompleteFromPopup="surveyCompleteFromPopup"
        />
        <popup
          v-else
          :surveyId="surveyId"
          text="Your survey responses have been successfully saved. You will be redirected to the landing page to complete the rest of the modules. You may return to edit these responses before submitting your final responses."
        />
      </div>
      <div v-if="showCustomComponent">
        <ImpactMetrics
          :saveCustomMetrics="triggerCustomComponent"
          @update:modelValue="triggerCustomComponent = $event"
          @saveComplete="handleSaveComplete"
          @saveError="handleSaveError"
          :investeeUserId="investeeUserId"
          :metrics="impactMetricsData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "survey-core/defaultV2.min.css";
import { PlainLightPanelless } from "survey-core/themes/plain-light-panelless";
import { Model } from "survey-core";
import { Survey } from "survey-vue-ui";
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import tippy from "tippy.js";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away.css";
import popup from "./popup.vue";
import { formatter } from "@/helpers/utils";
import SaveProgressModal from "./saveProgressModal.vue";
import ImpactMetrics from "@/components/imm-components/impact-metrics/ImpactMetrics.vue";
import TabView from "@/components/imm-components/dashboard/TabView.vue";

export default {
  components: {
    TabView,
    Survey,
    popup,
    SaveProgressModal,
    ImpactMetrics,
  },
  props: {
    surveyData: {
      type: [Object, Array],
      default: () => ({}),
    },
    surveyJson: {
      type: Object,
      default: () => ({}),
    },
    surveyId: {
      type: String,
    },
    answerAgain: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    isComplete: {
      type: Boolean,
    },
    userType: {
      type: [String, null],
    },
    entityType: {
      type: [String, null],
    },
    COM_Commitment: {
      type: [Array, Object, null],
    },
    isEmploymentEligible: {
      type: [String, Boolean, null],
    },
    skipFlags: {
      type: [Array, null],
    },
    surveyType: {
      type: [Array, Object, null],
    },
    uuid: {
      type: [Number, String, null],
    },
    entityTypeForOldUsers: {
      type: [String, null],
    },
    financialQuarter: {
      type: String,
      default: null,
    },
    dealId: {
      type: String,
      default: null,
    },
    investeeUserId: {
      type: String,
      default: null,
    },
    whiteLabel: {
      type: Object,
      default: null,
    },
    showCustomComponentBeforePreview: {
      type: Boolean,
      default: false,
    },
    displayPageTabs: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideNavigationButtons: {
      type: Boolean,
      default: false,
    },
    customNavigationItems: {
      type: Array,
      required: false,
    },
    impactMetricsData: {
      type: Array,
      default: null,
    },
    previewButtonText: {
      type: String,
      default: "Preview",
    }
  },
  data() {
    return {
      survey: null,
      doAnimation: true,
      loading: true,
      error: false,
      localAnswerAgain: this.answerAgain,
      localIsComplete: this.isComplete,
      toastVisible: false,
      isCurrentProgressVisile: true,
      modifiedId: this.id,
      previousValues: {},
      showPopup: false,
      showSaveProgressModal: false,
      currentPageNo: 0,
      showCompleteButton: false,
      showCustomNavigation: false,
      showPreviewButton: false,
      showCustomComponentButton: false,
      showCustomComponent: false,
      showSinglePagePreview: false,
      triggerCustomComponent: false,
      customComponentSaved: false,
      pageTabs: [],
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(newValue) {
        this.modifiedId = newValue;
      },
    },
    surveyJson: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          const refinedSurvey = this.skipFlags
            ? this.refineSurvey(newValue)
            : newValue;

          // this is for impact compass
          if (this.whiteLabel) {
            refinedSurvey.logo = this.whiteLabel.logo
              ? refinedSurvey.logo
              : null;
          }

          // remove 2x logo from company profile survey
          if (this.$route.path.toLowerCase().includes("company-profile")) {
            refinedSurvey.logo = null;
          }

          this.removeUnusedSkipLogic(refinedSurvey);
          this.redefineQuestions(refinedSurvey);
          this.survey = new Model(refinedSurvey);
          this.survey.applyTheme(PlainLightPanelless);
          this.survey.onComplete.add(this.surveyComplete);
          this.survey.previewText = this.previewButtonText ? this.previewButtonText : this.survey.previewText;

          // TOOLTIP code
          this.survey.onAfterRenderQuestion.add((_survey, options) => {
            if (
              options?.question?.choices?.some((element) =>
                element.text.includes("[Varies]")
              ) &&
              this.COM_Commitment
            ) {
              options.question.choices = options.question.choices.map(
                (choice) => {
                  if (
                    choice.value.includes("women in senior management") ||
                    choice.value.includes(
                      "women partners and managing partners"
                    )
                  ) {
                    choice.text = choice.text.replace(
                      "[Varies]",
                      this.COM_Commitment[1]
                    );
                  }
                  if (
                    choice.value.includes("women on IC") ||
                    choice.value.includes("women on Board")
                  ) {
                    choice.text = choice.text.replace(
                      "[Varies]",
                      this.COM_Commitment[2]
                    );
                  }
                  if (choice.value.includes("women in workforce")) {
                    choice.text = choice.text.replace(
                      "[Varies]",
                      this.COM_Commitment[0]
                    );
                  }

                  return choice;
                }
              );
            }
            if (
              options.question.name == "EMPLOY_Workplace health & well-being_9"
            ) {
              if (this.isEmploymentEligible) {
                options.question.visibleIf = `{EMPLOY_Workplace health & well-being_1} contains 'Employer-sponsored health insurance options'`;
              } else {
                options.question.visibleIf = "false";
              }
            }
            let questionTitle = options.htmlElement.querySelector("h5");
            if (questionTitle && options?.question?.jsonObj?.tooltip) {
              tippy(questionTitle, {
                content: options.question.jsonObj.tooltip,
                placement: "top-start",
                animation: "shift-away",
                theme: "dark",
                arrow: true,
              });
            }
          });

          // Trigger DOM when anything changes in Survey
          this.survey.onValueChanged.add((_, options) => {
            this.$emit("survyeEdited", Object.keys(this?.survey?.data).length);

            // ADVANCED SKIP LOGIC
            // `sender` is the survey instance, and `options` contains details about the change
            const changedQuestionName = options.name;
            const newValue = options.value;
            const previousValue = this.previousValues[changedQuestionName];

            this.handleAnswerChange(
              changedQuestionName,
              newValue,
              previousValue
            );

            this.previousValues[changedQuestionName] = newValue;
          });

          // TOOLTIP code for answers
          this.survey.onAfterRenderQuestion.add((_survey, options) => {
            // answer tooltips
            if (options.question.jsonObj && options.question.jsonObj.choices) {
              options.question.jsonObj.choices.forEach((tooltip, index) => {
                let answerElements = options.htmlElement.querySelectorAll(
                  ".sd-item__control-label"
                );

                if (tooltip.answerTooltips) {
                  tippy(answerElements[index], {
                    content: tooltip.answerTooltips,
                    placement: "top-start",
                    animation: "shift-away",
                    theme: "dark",
                    arrow: true,
                  });
                }
              });
            }
            this.textAreasEnhancement();
            this.fileButtonEnhancement();
          });

          this.survey.data = this.surveyData;

          // progress bar
          this.survey.onProgressText.add((_survey, options) => {
            options.text = `Answered: ${Math.round(
              (100 * options.answeredQuestionCount) / options.questionCount
            ).toFixed(0)}%`;
          });

          // hide navigation buttons only if showCustomComponentBeforePreview is true
          if (this.showCustomComponentBeforePreview) {
            this.showCustomNavigation = true;
            this.survey.showNavigationButtons = false;
            // Add page change handler
            this.survey.onCurrentPageChanged.add((_, options) => {
              this.currentPageNo = options.newCurrentPage.visibleIndex;
            });

            // Hide default navigation
            this.survey.showNavigationButtons = false;
          }

          if (this.hideHeader) {
            this.survey.showTitle = false;
            this.survey.showDescription = false;
            this.survey.showProgressBar = "off";
          }

          if (this.hideNavigationButtons) {
            this.survey.showPreviewButton = false;
            this.survey.showNavigationButtons = false;
          }

          // Load tabs based on the pages
          this.pageTabs = this.surveyJson?.pages?.map((page) => page) ?? [];

          // Add custom navigation items
          if (this.customNavigationItems) {
            this.customNavigationItems.forEach((item) => {
              this.survey.addNavigationItem({
                id: item.id,
                title: item.title,
                action: item.callback,
                css: "nav-button",
                innerCss: "sd-btn nav-input",
              });
            });
          }

          this.loading = false;
        }
      },
    },
    currentPageNo: {
      immediate: true,
      handler(newValue) {
        this.showCustomComponentButton =
          newValue === this.survey.visiblePages.length - 1;
      },
    },
  },
  mounted() {
    let containerOne = document.querySelector(
      ".sd-progress-buttons__container"
    );
    containerOne?.remove();
    this.$root.$on("saveCurrentProgressFromParent", async () => {
      await this.saveCurrentProgress();
    });

    // Text Areas elements
    this.textAreasEnhancement();

    // File Buttons elements
    this.fileButtonEnhancement();

    if (this.surveyJson?.pages) {
      this.surveyJson?.pages?.forEach((page) => {
        page?.elements?.forEach((question) => {
          this.previousValues[question.name] = null;
        });
      });
    }
  },
  beforeDestroy() {
    this.$root.$off("saveCurrentProgressFromParent");
  },
  methods: {
    surveyCompleteFromPopup() {
      this.$emit("redirectToInvestorProfile");
    },
    async surveyComplete(sender) {
      this.loading = true;
      try {
        let surveyResponse = [];
        for (let key in sender.data) {
          surveyResponse.push({ questionId: key, answer: sender.data[key] });
        }
        if (
          this.surveyId === "2x-intake" &&
          this.$route.query.twoxRepeatAsses
        ) {
          if (
            surveyResponse.find((obj) => obj.questionId === "GEN_General_17")
              ?.answer === this.surveyData["GEN_General_17"]
          ) {
            this.$swal
              .fire({
                icon: "error",
                text: "Your team already has submitted data for this reporting period.",
                showConfirmButton: true,
              })
              .then(() => {
                window.location.reload();
              });
            return;
          }
        } else if (this.surveyId == "roi-one" && this.$route.query.repeat) {
          if (
            surveyResponse.find((obj) => obj.questionId === "General2")
              ?.answer === this.financialQuarter
          ) {
            this.$swal
              .fire({
                icon: "error",
                text: "Your team already has submitted data for this reporting period.",
                showConfirmButton: true,
              })
              .then(() => {
                window.location.reload();
              });
            return;
          }
        }
        if (
          this.surveyId == "2x-intake" &&
          (this.entityType == "fund-manager" ||
            this.entityType == "financial-institution")
        ) {
          let exists = surveyResponse.some(
            (obj) => obj.questionId === "GEN_General_13"
          );
          if (exists) {
            surveyResponse.forEach((data) => {
              if (data?.questionId == "GEN_General_13") {
                data.answer = "Financial and Insurance Activities";
              }
            });
          } else {
            surveyResponse.push({
              questionId: "GEN_General_13",
              answer: "Financial and Insurance Activities",
            });
          }
        }
        if (this.surveyId == "roi-one" && this.$route.path.includes("/ggsf")) {
          let type = "";
          const finInstType = surveyResponse.filter(
            (obj) => obj.questionId === "General300"
          )?.[0]?.answer;
          if (
            finInstType == "Bank" ||
            finInstType == "MFI" ||
            finInstType == "NBFI/NBFC" ||
            finInstType == "Leasing/Factoring" ||
            finInstType == "Payroll Lending" ||
            finInstType == "Cooperative" ||
            finInstType == "NGO"
          ) {
            type = "On-lending facility";
          }
          if (finInstType == "Fund" || finInstType == "Other") {
            type = "Fund";
          }
          let General3Exist = surveyResponse.some(
            (obj) => obj.questionId === "General3"
          );
          if (General3Exist) {
            surveyResponse.forEach((data) => {
              if (data?.questionId == "General3") {
                data.answer = type;
              }
            });
          }
          if (!General3Exist) {
            surveyResponse.push({ questionId: "General3", answer: type });
            this.userType =
              type == "On-lending facility" ? "on-lending-facility" : "fund";
          }
        }
        if (this.surveyId == "roi-one" && this.$route.path.includes("/roi")) {
          let type = "";
          let roiEntityType;
          if (this.entityTypeForOldUsers) {
            // for old roi users only
            let General3Exist = surveyResponse.some(
              (obj) => obj.questionId === "General3"
            );
            roiEntityType = this.entityTypeForOldUsers;
            if (General3Exist) {
              surveyResponse.forEach((data) => {
                if (data?.questionId == "General3") {
                  data.answer = this.entityTypeForOldUsers;
                }
              });
            } else {
              surveyResponse.push({
                questionId: "General3",
                answer: this.entityTypeForOldUsers,
              });
            }
          } else {
            roiEntityType = surveyResponse.filter(
              (obj) => obj.questionId === "General3"
            )?.[0]?.answer;
          }
          if (roiEntityType == "Institutional Investor") {
            type = "institutional-investor";
          }
          if (roiEntityType == "Fund") {
            type = "fund";
          }
          if (roiEntityType == "Micro-enterprise") {
            type = "micro";
          }
          if (roiEntityType == "Small or medium enterprise") {
            type = "sme";
          }
          if (roiEntityType == "Corporate (large)") {
            type = "large";
          }
          if (roiEntityType == "On-lending facility") {
            type = "on-lending-facility";
          }
          this.userType = type;
        }
        // first time survey submission
        if (!this.answerAgain && !this.modifiedId) {
          const { status } = await axios.post(
            `${env.apiUrl}/survey-answers/`,
            {
              surveyId: this.surveyId,
              surveyResponse,
              isComplete: true,
              tag: this.userType,
              metadata: this.surveyType,
              uuid: String(this.uuid),
              dealId: this.dealId,
              investeeUserId: this.investeeUserId,
            },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          );
          if (status === 200) {
            this.error = false;
            this.localIsComplete = true;
            if (
              this.surveyId.toLowerCase().includes("2x") ||
              this.surveyId.toLowerCase().includes("roi") ||
              this.surveyId == "investor-profile"
            ) {
              this.showPopup = true;
            }
            setTimeout(() => {
              this.$emit("surveyComplete");
            }, 4000);
          }
          // survey update
        } else {
          const {
            data: { data },
            status,
          } = await axios.put(
            `${env.apiUrl}/survey-answers/${this.modifiedId}`,
            {
              surveyId: this.surveyId,
              surveyResponse,
              isComplete: true,
              metadata: this.surveyType,
              uuid: String(this.uuid),
              dealId: this.dealId,
              investeeUserId: this.investeeUserId,
            },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          );
          console.log(data);
          if (status === 200) {
            this.error = false;
            this.localAnswerAgain = false;
            this.localIsComplete = true;
            if (
              this.surveyId.toLowerCase().includes("2x") ||
              this.surveyId.toLowerCase().includes("roi") ||
              this.surveyId == "investor-profile"
            ) {
              this.showPopup = true;
            }
            setTimeout(() => {
              this.$emit("surveyComplete");
            }, 4000);
          }
        }
      } catch (error) {
        this.error = true;
        this.$swal.fire({
          icon: "error",
          text: `An error occurred while processing your request. Please try again later. If the issue persists, please contact support. Error code: ${
            error?.response?.data?.error || error?.response?.data?.message
          }`,
          showConfirmButton: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async saveCurrentProgress() {
      let surveyResponse = [];
      for (let key in this.survey.data) {
        surveyResponse.push({ questionId: key, answer: this.survey.data[key] });
      }
      try {
        if (!this.modifiedId) {
          const {
            data: { data },
            status,
          } = await axios.post(
            `${env.apiUrl}/survey-answers/`,
            {
              surveyId: this.surveyId,
              surveyResponse,
              isComplete: false,
              tag: this.userType,
              metadata: this.surveyType,
              uuid: String(this.uuid),
              dealId: this.dealId,
            },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          );
          console.log(data);
          if (status === 200) {
            this.modifiedId = data._id;
            this.$emit("currentProgressSaved");
            this.toastVisible = true;
            this.error = false;
            this.showSaveProgressModal = true;
          }
        } else {
          const {
            data: { data },
            status,
          } = await axios.put(
            `${env.apiUrl}/survey-answers/${this.modifiedId}`,
            {
              surveyId: this.surveyId,
              surveyResponse,
              isComplete: false,
              metadata: this.surveyType,
              uuid: String(this.uuid),
              dealId: this.dealId,
            },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          );
          console.log(data);
          if (status === 200) {
            this.error = false;
            this.toastVisible = true;
            this.showSaveProgressModal = true;
            this.$emit("currentProgressSaved");
          }
        }
      } catch (error) {
        this.error = true;
        this.$swal.fire({
          icon: "error",
          text: `Something went wrong when saving your progress. Please contact support.
          Error code: ${
            error?.response?.data?.error || error?.response?.data?.message
          }`,
          showConfirmButton: true,
        });
      } finally {
        setTimeout(() => {
          this.toastVisible = false;
        }, 3000);
      }
    },
    deleteSurveyData() {
      this.survey.data = {};
    },
    refineSurvey(survey) {
      const newPages = survey?.pages?.map((page) => {
        const newElements = page?.elements?.filter(
          (question) =>
            Array.isArray(question.tags) &&
            question.tags.some((tag) => this.skipFlags.includes(tag.trim()))
        );
        return { ...page, elements: newElements };
      });
      const newSurvey = { ...survey, pages: newPages };

      return newSurvey;
    },
    redefineQuestions(survey) {
      if (this.skipFlags?.includes("2x_challenge")) {
        survey.description = survey?.twoXChallengeDescription;
      }
      survey?.pages?.forEach((page) => {
        page?.elements?.forEach((element) => {
          // Replacing intro
          if (element.type === "intro_text") {
            page.description = element.title;
          }

          // Replacing header
          if (element.type === "heading") {
            page.title = element.title;
          }

          // Be careful here. Changing question type to "comment" only those with type="text" and inputType not defined
          if (element?.type === "text" && !element?.inputType) {
            element.type = "comment";
          }

          // Question types "number" have type="text" and inputType="number"
          if (element?.type === "text" && element?.inputType === "number") {
            element.placeholder = `Please enter numbers without any separators, like "1234${formatter
              .format(567.89)
              .toString()}"`;
          }

          // Allow multiple files
          if (element?.type === "file") {
            element.allowMultiple = true;
            element.filePlaceholder =
              "Drag and drop files here or click the button below to select files to upload.";
            element.maxSize = 2048000;
          }
        });
      });
    },
    textAreasEnhancement() {
      const textAreas = document.querySelectorAll(".sd-input.sd-comment");
      textAreas.forEach((textArea) => {
        textArea.rows = 1;
        textArea.addEventListener("input", function () {
          this.style.height = "auto";
          this.style.height = `${this.scrollHeight}px`;
        });
      });
    },
    fileButtonEnhancement() {
      const fileButtons = document.querySelectorAll(
        ".sd-file__choose-btn.sd-action.sd-file__choose-btn--text span"
      );
      fileButtons.forEach((fileButton) => {
        fileButton.innerHTML = "Select File(s)";
      });
    },
    // ADVANCED SKIP LOGIC starts here
    removeUnusedSkipLogic(survey) {
      // extracting all question names from the survey
      const questions = survey?.pages?.flatMap((page) => {
        return page?.elements?.map((element) => {
          return element.name;
        });
      });

      // regular expression to match all occurrences between {}
      const regex = /{([^}]+)}/g;
      // iterating over each page and element in the survey
      survey?.pages?.forEach((page) => {
        page?.elements?.forEach((element) => {
          // check if element.visibleIf exists
          if (element?.visibleIf) {
            // extracting all occurrences between {} from element.visibleIf
            const matches = [...element.visibleIf.matchAll(regex)].map(
              (match) => match[1]
            );

            // checking if at least one of these occurrences is not inside the questions array
            const isInvalidReference = !matches.every((match) =>
              questions.includes(match)
            );

            const placeholder = "___";
            const regexSingleQuotesContent = /'([^']*)'/g;
            let stringWithoutQuotes = element.visibleIf.replace(
              regexSingleQuotesContent,
              () => placeholder
            );

            // regex to check for "and" & "or" outside of single quotes
            const hasOr = /\bor\b/.test(stringWithoutQuotes);

            // if there's at least one invalid reference or if "and" is present, set visibleIf to null
            // keep visibleIf if "or" is present and there are no invalid references
            if (isInvalidReference && !hasOr) {
              element.visibleIf = null;
            }
          }
        });
      });
    },
    extractDependencies(visibleIf) {
      const regex =
        /{([\w\s]+)} (contains|equals|=|<>|notcontains|>|<|>=|<=) ('[^']+'|\d+)/g;
      let matches,
        dependencies = [];
      while ((matches = regex.exec(visibleIf)) !== null) {
        dependencies.push({
          questionName: matches[1],
          answers: [matches[3].replace(/'/g, "")],
        });
      }
      return dependencies;
    },
    findQuestionByName(name) {
      // find and return the question object by its name
      for (let page of this.survey.pages) {
        for (let question of page.elements) {
          if (question.name === name) {
            return question;
          }
        }
      }
      return null;
    },
    // Call this method whenever an answer changes
    handleAnswerChange(changedQuestionName, newValue, previousValue) {
      const dependencies = this.dependencyMap[changedQuestionName];

      if (dependencies) {
        dependencies.forEach((dependency) => {
          // check if the current answer matches any of the dependency criteria
          let answersToRemove = [];
          if (Array.isArray(previousValue) && Array.isArray(newValue)) {
            // find answers that were removed
            answersToRemove = previousValue.filter(
              (x) => !newValue.includes(x)
            );
          } else if (previousValue !== newValue && previousValue !== null) {
            // for non-array values, if the value has changed and is not null, consider it as removed
            answersToRemove.push(previousValue);
          }

          answersToRemove.forEach((removedAnswer) => {
            if (dependency.answers.includes(removedAnswer)) {
              this.resetQuestionAnswer(dependency.dependentQuestionName);
            }
          });
        });
      }
    },
    resetQuestionAnswer(questionName) {
      let question = this.survey.getQuestionByName(questionName);
      question.value = null;
    },
    prevPage() {
      this.survey.prevPage();
      this.currentPageNo = this.survey.currentPageNo;
    },
    nextPage() {
      if (this.survey.currentPage.validate()) {
        this.survey.nextPage();
        this.currentPageNo = this.survey.currentPageNo;
      }
    },
    previewSurvey() {
      if (this.survey.currentPage.validate()) {
        this.survey.mode = "display";
        this.survey.questionsOnPageMode = "singlePage";
        this.showCompleteButton = true;
        this.showPreviewButton = false;
        this.showSinglePagePreview = true;
      }
    },
    async completeSurvey() {
      if (this.survey.currentPage.validate()) {
        try {
          this.triggerCustomComponent = true;
          this.survey.completeLastPage();
          this.showCustomNavigation = false;
        } catch (error) {
          console.error("Failed to complete survey:", error);
          this.$swal.fire({
            icon: "error",
            text: "Failed to save custom metrics. Please try again.",
          });
        }
      }
    },
    customComponentButtonAction() {
      this.showCustomComponentButton = false;
      this.showCustomComponent = true;
      this.showPreviewButton = true;
    },
    jumpToPage(pageNo) {
      pageNo = parseInt(pageNo);
      if (this.survey.currentPage.validate()) {
        this.survey.currentPageNo = pageNo;
        this.currentPageNo = pageNo;
      }
    },
    handleSaveComplete(result) {
      // handle successful save
      console.log("Metrics saved successfully:", result);
      this.$swal.fire({
        icon: "success",
        text: "Custom metrics saved successfully",
      });

      this.showCustomComponent = false;
    },

    handleSaveError(error) {
      // handle save error
      console.error("Failed to save metrics:", error);
      // show error message to user
      this.$swal.fire({
        icon: "error",
        text: "Failed to save custom metrics. Please try again.",
      });
    },
  },
  computed: {
    dependencyMap() {
      let map = {};
      this.survey.pages.forEach((page, pageIndex) => {
        page.elements.forEach((question) => {
          if (question.visibleIf) {
            const dependencies = this.extractDependencies(question.visibleIf);
            dependencies.forEach((dependency) => {
              if (!map[dependency.questionName]) {
                map[dependency.questionName] = [];
              }
              map[dependency.questionName].push({
                dependentQuestionName: question.name,
                answers: dependency.answers,
                pageIndex: pageIndex,
              });
            });
          }
        });
      });
      return map;
    },
  },
};
</script>

<style>
.survey {
  text-align: left;
}

.toast {
  background: green;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  z-index: 99;
}

.tippy-box[data-theme~="dark"] {
  background-color: rgb(33, 33, 33);
  color: white;
  padding: 1em;
}

div.sd-description {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #717686 !important;
}

div.sd-dropdown__value {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #2c2e35 !important;
}
span.sv-string-viewer {
  position: relative !important;
  z-index: 1 !important;
}

div.sv-list__item-body {
  font-family: Arial !important;
}

input.sd-dropdown__filter-string-input {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #717686 !important;
}

h3.sd-title {
  font-family: "Montserrat" !important;
  color: #191b45 !important;
  font-weight: bold !important;
}

.sd-description {
  color: #2c2e35 !important;
  font-family: Arial !important;
}

h4.sd-title {
  font-family: "Montserrat" !important;
  color: #191b45 !important;
  font-weight: bold !important;
}

.sd-question__required-text {
  font-family: Arial !important;
  color: #cb2e00 !important;
  font-weight: bold !important;
}

h5.sd-title {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #2c2e35 !important;
}

div.sv-tagbox__item {
  background-color: #2177c1 !important;
}

span.sd-item__control-label {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #2c2e35 !important;
}

input.sd-input {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #2c2e35 !important;
}

span.sd-element__num {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #717686 !important;
}

input.sd-btn {
  color: #2177c1 !important;
}

input.sd-btn--action {
  background-color: #2177c1 !important;
  color: white !important;
}

button[title="Edit"] {
  margin-left: 1em;
  color: #2177c1 !important;
}

button[title="Edit"]:hover {
  color: white !important;
  background-color: #2177c1 !important;
  border-radius: 5px;
}

div.sd-progress {
  background-color: #dbe3e9 !important;
  height: 1em;
}

div.sd-progress__bar {
  background-color: #2177c1 !important;
  height: 1em;
}
.sd-element__title.sd-element__title--disabled {
  opacity: 0.5;
}
.sd-input.sd-input--disabled,
.sd-input.sd-input--disabled::placeholder {
  opacity: 0.5;
}

.sd-element__num {
  font-size: 15px !important;
}

.sv-string-viewer.sv-string-viewer--multiline {
  line-height: 1.4 !important;
}

.sd-navigation {
  display: flex !important;
  align-items: center !important;
  gap: 0.5rem !important;
}

.sd-navigation__button {
  font-family: Arial, Helvetica, sans-serif !important;
}

.navigation-block {
  margin-bottom: 1.5rem;
}

.navigation-page-selector {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
}

.navigation-progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-buttons-container {
  display: flex;
  gap: 0.5rem;
}

.navigation-button {
  padding: 0.5rem 1rem;
  background-color: #0077c8;
  color: white;
  border-radius: 0.25rem;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.navigation-button:hover {
  background-color: #005a96;
}

.navigation-text-container {
  color: #4b5563;
}
</style>
