<template>
  <div class="twoxEligible_button flex twoxEligible_button_tooltip mb-2">
    <div class="flex items-center">
      <div>
        <img
          src="@/assets/images/Rightcircle.svg"
          alt="background-image"
          style="margin-top: 3px; margin-left: -3px !important; width: 30px"
          v-if="roiData.twoXEligible === 'Eligible'"
        />
        <img
          style="margin-top: 3px; border-radius: 0; width: 30px"
          src="@/assets/images/3rdPartyVerified.svg"
          alt="background-image"
          v-if="roiData.twoXEligible === 'Not Eligible'"
        />
        <img
          v-if="roiData.twoXEligible === 'Unknown'"
          style="margin-left: -3px !important; width: 30px"
          src="@/assets/images/help_white.svg"
          alt="background-image"
        />
      </div>
      <h2
        class="font-bold text-2xl ml-4 cursor-pointer"
        v-if="roiData.twoXEligible"
        @click="
          roiData?.twoXEligible === 'Unknown'
            ? redirectToDashboardPage()
            : redirectToTwoXAssess()
        "
      >
        2X Aligned
      </h2>
      <h2
        class="font-bold text-2xl ml-4 cursor-pointer"
        v-else
        @click="redirectToDashboardPage()"
      >
        Not 2X Aligned
      </h2>
    </div>
    <span
      class="twoxEligible_button_hover_text"
      v-if="roiData?.twoXEligible == 'Unknown'"
    >
      Your 2X alignment status is unknown because there is data missing. You may
      complete this missing data in the 2X Assessment tool to discover your 2X
      status
    </span>
  </div>
</template>
<script>
import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";

export default {
  name: "TwoXAlignedSectionGgsfRoi",
  data() {
    return {};
  },
  props: ["roiData", "teamId"],
  mounted() {
    this.fetch2XeligibleStatus();
  },
  methods: {
    redirectToTwoXAssess() {
      let routeData = this.$router.resolve({
        path: "/2X-Ecosystem/2X-Certification/IndicativeResults",
      });
      window.open(routeData.href, "_blank");
    },
    redirectToDashboardPage() {
      let routeData = this.$router.resolve({
        path: "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment",
      });
      window.open(routeData.href, "_blank");
    },
    async fetch2XeligibleStatus() {
      if (!this.teamId) return;
      try {
        const {
          data: { data },
        } = await axios.get(
          env.apiUrl + "/certify2x/2x-eligible?teamId=" + this.teamId,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );

        this.roiData.twoXEligible = data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.twoxEligible_button_tooltip {
  position: relative;
  display: inline-block;
}

.twoxEligible_button_tooltip .twoxEligible_button_hover_text {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 180px;
  top: -40px;
}

.twoxEligible_button_tooltip:hover .twoxEligible_button_hover_text {
  visibility: visible;
}

.twoxEligible_button_tooltip .twoxEligible_button_hover_text::after {
  right: 95%;
}
</style>
