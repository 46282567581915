import { defineStore } from "pinia";

export const ChatState = Object.freeze({
  closed: "closed",
  sidebar: "sidebar",
  fullscreen: "fullscreen",
});

export const useChatStore = defineStore("formdata", {
  state: () => ({
    chatState: ChatState.closed,
    previousPage: null,
    message: "",
    chatId: null,
  }),
  getters: {
    isChatClosed() {
      return this.chatState === ChatState.closed;
    },
    isChatSidebar() {
      return this.chatState === ChatState.sidebar;
    },
    isChatFullscreen() {
      return this.chatState === ChatState.fullscreen;
    },
  },
  actions: {
    maximizeChat() {
      if (
        this.chatState !== ChatState.closed &&
        this.chatState !== ChatState.sidebar
      ) {
        return;
      }
      this.previousPage = this.router.currentRoute.fullPath;
      this.chatState = ChatState.fullscreen;
      this.router.push({ name: "chatbot" });
    },
    minimizeChat() {
      if (this.chatState !== ChatState.fullscreen) {
        return;
      }
      this.chatState = ChatState.closed;
      if (this.previousPage) {
        this.router.push({ path: this.previousPage });
      } else {
        this.router.push({ name: "homePage" });
      }
    },
    closeChat() {
      if (this.chatState === ChatState.closed) {
        return;
      }
      this.chatState = ChatState.closed;
    },
    openChat() {
      if (this.chatState !== ChatState.closed) {
        return;
      }
      this.chatState = ChatState.sidebar;
    },
    setChatId(id) {
      this.chatId = id;
    },
  },
});
