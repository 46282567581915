import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";

/**
 *
 * @param {String} surveyId - string id of the survey
 * @param {String} investee - optional user id of the investee. If not set, the signed in user's id will be used
 * @param {String} period - optional period of the survey formatted as YYYY, or YYYY-Q1, or YYYY-MM. If not set, the latest survey answers will returned
 * @returns
 */
export const getSurveyData = async function (
  surveyId,
  investee = null,
  period = null
) {
  if (!surveyId) {
    console.error("No survey ID provided");
    return {
      isComplete: false,
      surveyResponse: [],
    };
  }
  try {
    const params = new URLSearchParams();
    if (investee) {
      params.append("investee", investee);
    }
    if (period) {
      params.append("period", period);
    }
    const url = `${
      env.apiUrl
    }/survey-answers/survey/${surveyId}?${params.toString()}`;
    const {
      data: { data },
      status,
    } = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });

    if (
      status === 200 &&
      Array.isArray(data.surveyResponse) &&
      data.surveyResponse.length
    ) {
      const { isComplete, surveyResponse, progressPercentage, totalQuestions } =
        data;
      return {
        isComplete: isComplete || false,
        surveyResponse: Array.isArray(surveyResponse) ? surveyResponse : [],
        progressPercentage,
        totalQuestions,
      };
    }
  } catch (error) {
    console.error("Error fetching survey data:", error);
  }

  return {
    isComplete: false,
    surveyResponse: [],
  };
};

export const getDashboardData = async (teamId, surveyId, frequency) => {
  try {
    const { data } = await axios.get(
      `${env.apiUrl}/imm/dashboard?teamId=${teamId}&surveyId=${surveyId}&frequency=${frequency}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("session")}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    return [];
  }
};

export const getSurveyStatus = async (surveys, userId) => {
  try {
    const { data } = await axios.post(
      `${env.apiUrl}/imm/portfolio-survey-status`,
      {
        surveys,
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("session")}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error fetching survey status:", error);
    return [];
  }
};

export const validateRepeatSurvey = async (surveyId, frequency) => {
  try {
    const { data } = await axios.get(
      `${env.apiUrl}/imm/validate-repeat-survey?surveyId=${surveyId}&frequency=${frequency}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("session")}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error validating repeat survey:", error);
    return false;
  }
};

export const moduleDescriptions = {
  Climate:
    "Evaluate your company's preparedness for climate risks and identify key areas for improvement.",
  "Environmental and Social Risks and Impacts":
    "Assess your company's environmental and social impact and identify opportunities for improvement.",
  "Environmental & Social Exclusions":
    "Identify and manage environmental and social exclusions in your portfolio.",
  Finance:
    "Assess your company's financial performance and identify opportunities for improvement.",
  Impact:
    "Evaluate your company's impact on society and the environment and identify key areas for improvement.",
  "Gender ROI™":
    "Assess your company's gender-related practices and identify opportunities for improvement.",
  "2X Certification":
    "Evaluate your company's eligibility for 2X Certification, identifying key areas of progress and improvement.",
  ESG: "Assess your company's ESG practices and identify opportunities for improvement.",
  "Energy Consumption & GHG Emissions":
    "Evaluate your company's energy and GHG emissions and identify opportunities for improvement.",
};

export const surveyIds = {
  Climate: "climate-dd",
  "Environmental and Social Risks and Impacts": "e&s",
  "Environmental & Social Exclusions": "exclusions",
  Impact: "sweef-impact",
  ESG: "esg",
  "Energy Consumption & GHG Emissions": "energy-ghg",
};

export const getAggregatedUrl = (title, portfolioName) => {
  const baseUrl = "/impact-compass/aggregate-portfolio/";
  const formattedTitle = title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[™&]/g, "");
  return `${baseUrl}${formattedTitle}${
    portfolioName ? `?portfolioName=${portfolioName}` : ""
  }`;
};

export const getDashboardDataExportUrl = ({
  surveyId,
  teamId,
  portfolioName,
  aggregation,
  frequency,
}) => {
  const token = Cookies.get("session");
  const params = {
    surveyId,
    teamId,
    portfolioName,
    aggregation,
    frequency,
    token,
  };
  const definedParams = Object.keys(params).reduce((acc, key) => {
    if (params[key] !== undefined && params[key] !== null) {
      acc[key] = params[key];
    }
    return acc;
  }, {});

  return `${env.apiUrl}/imm/dashboard-export?${new URLSearchParams(
    definedParams
  ).toString()}`;
};
