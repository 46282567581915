var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ImmCard',{attrs:{"loading":_vm.loading}},[[_c('div',{staticClass:"flex justify-between h-10"},[_c('div',{staticClass:"font-bold text-xl text-[#2c2e35]"},[_vm._v("Financials")]),_c('FrequencyLabel',{attrs:{"frequency":_vm.frequency}})],1),_c('div',{staticClass:"h-full flex flex-col justify-between"},[(!_vm.displayViewResultsButton && !_vm.aggregatedView)?_c('div',{staticClass:"w-full h-24"},[_vm._v(" Evaluate the overall financial stability of your company, identifying strengths and areas for improvement. ")]):_vm._e(),(_vm.displayViewResultsButton || _vm.aggregatedView)?_c('FinanceModuleCardContent',{attrs:{"revenue":_vm.revenue,"revenueIncrease":_vm.revenueIncrease,"net-profit":_vm.netProfit,"ebitda":_vm.ebitda}}):_vm._e(),_c('div',{staticClass:"flex w-full justify-end"},[_c('div',{staticClass:"flex gap-4 items-end"},[(_vm.displayViewResultsButton)?_c('router-link',{staticClass:"flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#4dc9be] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center",attrs:{"to":_vm.teamId ? `/impact-compass/${_vm.teamId}/finance` : '/impact-compass/dashboard/finance'}},[_vm._v(" View Results ")]):_vm._e(),(_vm.displayRepeatButton)?_c('router-link',{class:[
            'flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-white text-[#191b45] `font-arial text-base font-bold leading-6 text-center min-w-[140px]',
            { 'opacity-50 cursor-not-allowed': _vm.teamId },
          ],attrs:{"to":"/impact-compass/finance-survey","disabled":_vm.teamId}},[_vm._v(" Repeat ")]):_vm._e(),(_vm.displayLaunchButton)?_c('router-link',{class:[
            'flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#191b45] text-white font-arial text-base font-bold leading-6 text-center min-w-[140px]',
            { 'opacity-50 cursor-not-allowed': _vm.teamId },
          ],attrs:{"to":_vm.aggregatedUrl ? _vm.aggregatedUrl : '/impact-compass/finance-survey',"disabled":_vm.teamId}},[_vm._v(" "+_vm._s(_vm.aggregatedView ? "View aggregated dashboard" : "Launch")+" ")]):_vm._e()],1)])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }