<template>
  <ImmCard :loading="loading">
    <template>
      <div class="flex justify-between h-10">
        <div class="font-bold text-xl text-[#2c2e35]">Financials</div>
        <FrequencyLabel :frequency="frequency" />
      </div>
      <div class="h-full flex flex-col justify-between">
        <div
          class="w-full h-24"
          v-if="!displayViewResultsButton && !aggregatedView"
        >
          Evaluate the overall financial stability of your company, identifying
          strengths and areas for improvement.
        </div>
        <FinanceModuleCardContent
          v-if="displayViewResultsButton || aggregatedView"
          :revenue="revenue"
          :revenueIncrease="revenueIncrease"
          :net-profit="netProfit"
          :ebitda="ebitda"
        />
        <!--  <span v-if="!hasSubmittedSurvey && !aggregatedView" class="text-gray-500">
          This user has no financial data yet.
        </span> -->
        <!--Buttons-->
        <div class="flex w-full justify-end">
          <!--Links-->
          <!--  <div
            class="w-1/2 h-12 flex justify-left items-center gap-4 text-[#2177C1] font-bold text-sm"
          >
            <a class="cursor-pointer" href="#">&lt; &gt; API integration</a>
            <a class="cursor-pointer flex items-center justify-center" href="#">
              <img src="@/assets/images/dashboard/info.svg" alt="info" />
              Methodology
            </a>
          </div> -->

          <div class="flex gap-4 items-end">
            <router-link
              v-if="displayViewResultsButton"
              class="flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#4dc9be] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center"
              :to="teamId ? `/impact-compass/${teamId}/finance` : '/impact-compass/dashboard/finance'"
            >
              View Results
            </router-link>
            <router-link
              v-if="displayRepeatButton"
              :class="[
              'flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-white text-[#191b45] `font-arial text-base font-bold leading-6 text-center min-w-[140px]',
              { 'opacity-50 cursor-not-allowed': teamId },
            ]"
              to="/impact-compass/finance-survey"
              :disabled="teamId"
            >
              Repeat
            </router-link>
            <router-link
              v-if="displayLaunchButton"
              :class="[
              'flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#191b45] text-white font-arial text-base font-bold leading-6 text-center min-w-[140px]',
              { 'opacity-50 cursor-not-allowed': teamId },
            ]"
              :to="aggregatedUrl ? aggregatedUrl : '/impact-compass/finance-survey'"
              :disabled="teamId"
            >
              {{ aggregatedView ? "View aggregated dashboard" : "Launch" }}
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </ImmCard>
</template>

<script>
import ImmAPI from "@/api/ImmAPI";
import FrequencyLabel from "./FrequencyLabel.vue";
import FinanceModuleCardContent from "./module-cards-content/FinanceModuleCardContent.vue";
import ImmCard from "@/components/imm-components/dashboard/ImmCard.vue";

export default {
  name: "FinanceCard",
  setup() {
    const immAPI = new ImmAPI();
    return { immAPI };
  },
  components: {
    FrequencyLabel,
    FinanceModuleCardContent,
    ImmCard,
  },
  props: {
    user: {
      type: Object,
    },
    frequency: {
      type: String,
    },
    aggregatedView: {
      type: Boolean,
    },
    aggregatedUrl: {
      type: String,
    },
  },
  data() {
    return {
      teamId: null,
      loading: true,
      organizationId: null,
      hasSubmittedSurvey: false,
      revenue: 0,
      revenueIncrease: 0,
      netProfit: 0,
      ebitda: 0,
    };
  },
  async mounted() {
    // company data view
    if (this.aggregatedView) {
      this.loading = false;
    } else {
      this.teamId = this.$route.query.teamId;
      this.organizationId = this.teamId
        ? this.teamId
        : this.user.organization._id;

      // Checking if the portfolio company has already submitted the survey
      try {
        const response = await this.immAPI.getIMMFinancePeriods(
          this.organizationId
        );
        const financePeriods = response.data;
        this.hasSubmittedSurvey = financePeriods?.length > 0;

        if (this.hasSubmittedSurvey) {
          const lastPeriod = financePeriods[financePeriods.length - 1];
          const currentYear = lastPeriod.year;
          const currentMonth = lastPeriod.month;
          const financeInformationResponse = await this.immAPI.getImmFinanceTableData(
            currentYear,
            currentMonth,
            this.user?.organization?._id,
            this.user?.organization?.currencyPreference
          );
          const financeInformationData = financeInformationResponse?.data?.data || {};
          this.revenue = financeInformationData?.YTD?.totalRevenueYTD?.Actual || 0;
          this.revenueIncrease = financeInformationData?.YTD?.totalRevenueYTD?.["Variance (%)\n"] || 0;
          this.netProfit = financeInformationData?.YTD?.grossProfitYTD?.Actual || 0;
          this.ebitda = financeInformationData?.YTD?.operatingActivitiesNetIncomeYTD?.Actual || 0;
        }
      } catch (error) {
        console.error("Error fetching finance survey:", error);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    displayViewResultsButton() {
      return this.hasSubmittedSurvey;
    },
    displayRepeatButton() {
      return this.hasSubmittedSurvey && !this.teamId;
    },
    displayLaunchButton() {
      return !this.hasSubmittedSurvey && !this.teamId;
    },
  },
};
</script>
