<template>
  <div class="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-10">
    <h2 class="text-2xl font-bold">Custom Impact Metrics</h2>
    <div class="mb-8">
      <label for="metricCount" class="block text-sm font-medium text-gray-700"
        >How many custom impact metrics would you like to report?</label
      >
      <select
        v-model="metricCount"
        id="metricCount"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        @change="updateMetrics"
      >
        <option v-for="count in 10" :key="count" :value="count">
          {{ count }}
        </option>
      </select>
    </div>

    <div
      v-for="(metric, index) in metrics"
      :key="index"
      class="mb-8 p-6 bg-gray-50 rounded-xl border border-gray-200 hover:border-blue-400 transition-colors"
    >
      <!-- Content -->
      <div class="flex items-center mb-4">
        <span
          class="flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 text-white text-sm font-medium"
        >
          {{ index + 1 }}
        </span>
        <h3 class="ml-3 text-lg font-medium text-gray-900">Impact Metric</h3>
      </div>
      <div class="space-y-4">
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-1"
            >What is the name of the custom impact metric?</label
          >
          <input
            v-model="metric.irisMetric"
            placeholder="Enter IRIS metric"
            class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700 mb-1"
            >Which of the United Nations (UN) Sustainable Development Goals
            (SDGs) is this impact metric aligned with?</label
          >
          <select
            v-model="metric.unSdg"
            class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option disabled>Select an SDG</option>
            <option v-for="sdg in unSdgs" :key="sdg" :value="sdg">
              {{ sdg }}
            </option>
          </select>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700 mb-1"
            >Which of the UN targets is this impact metric aligned with?</label
          >
          <select
            :disabled="metric.unSdg === ''"
            v-model="metric.unTarget"
            class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            :class="metric.unSdg === '' ? 'bg-gray-200 cursor-not-allowed' : ''"
          >
            <option disabled>Select a target</option>
            <option
              v-for="target in unTargets[metric.unSdg]"
              :key="target"
              :value="target"
            >
              {{ target }}
            </option>
          </select>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700 mb-1"
            >What type of impact data will you report for this metric?</label>
            <label class="mr-4">
              <input type="radio" :name="`dataType_${index}`" v-model="metric.dataType" value="quantitative"/>
              <span class="px-2">Quantitative (Numerical values)</span>
            </label>
            <label>
              <input type="radio" :name="`dataType_${index}`" v-model="metric.dataType" value="qualitative"/>
              <span class="px-2">Qualitative (Descriptive text)</span>
            </label>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700 mb-1"
            >Which IRIS metric code is this impact metric aligned with?</label
          >
          <input
            v-model="metric.irisCodeSearch"
            type="search"
            placeholder="Search IRIS codes..."
            class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            @input="searchIrisCodes"
          />
          <div v-if="showIrisCodeResults" class="relative mt-1">
            <ul
              class="absolute z-10 w-full bg-white border rounded-md shadow-lg max-h-60 overflow-auto"
            >
              <li
                v-for="code in filteredIrisCodes"
                :key="code.id"
                @click="selectIrisCode(metric, code)"
                class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                :class="{
                  'bg-blue-500 text-white': metric.irisCode.includes(
                    code.irisCode
                  ),
                  'hover:bg-blue-300': metric.irisCode.includes(code.irisCode),
                }"
              >
                {{ code.irisCode }} - {{ code.irisMetricName }}
              </li>
            </ul>
          </div>
          <div
            v-if="metric.irisCodeDetails.length > 0"
            class="mt-2 text-sm text-gray-600"
          >
            <div
              v-for="(details, index) in metric.irisCodeDetails"
              :key="index"
              class="mb-1 p-1 bg-gray-50 rounded flex justify-between items-start"
            >
              <div class="flex-1 bg-blue-500 text-white rounded-md p-2">
                <div class="font-medium">{{ details.irisMetricName }}</div>
                <div class="text-xs text-gray-500">
                  {{ details.displayDescription }}
                </div>
              </div>
              <button
                v-if="!isSweefCompany"
                @click="selectIrisCode(metric, details)"
                class="bg-red-500 text-white rounded-full px-2 py-1 text-xs mt-1 ml-2"
              >
                X
              </button>
            </div>
          </div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700 mb-1"
            >Reporting Frequency</label
          >
          <select
            v-model="metric.reportingFrequency"
            class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            >
            <option value="quarterly">Quarterly</option>
            <option value="annually">Annually</option>
          </select>
        </div>

        <!-- Add years and impacts> -->
        <div>
          <h4 class="text-lg font-medium text-gray-900 mb-3">
            Actual Impact Achieved
          </h4>

          <div class="mb-4">
            <div class="flex flex-wrap gap-2 mb-4">
              <div
                v-for="year in availableYears"
                :key="year"
                @click="toggleYear(metric, year)"
                class="px-3 py-1 rounded-full cursor-pointer text-sm"
                :class="
                  metric.selectedYears.includes(year)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                "
              >
                {{ year }}
              </div>
            </div>

            <div :class="metric.dataType === 'qualitative' ? 'grid grid-cols-1 gap-4' : 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'">
              <template v-if="metric.reportingFrequency === 'annually'">
                <div
                  v-for="year in metric.selectedYears"
                  :key="year"
                  class="relative"
                >
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    Impact Value for {{ year }}
                  </label>
                  <textarea
                    v-if="metric.dataType === 'qualitative'"
                    v-model="metric.qualitativeImpacts[year]"
                    placeholder="Enter impact description"
                    class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <input
                    v-else
                    type="number"
                    v-model="metric.impacts[year]"
                    placeholder="Enter impact value"
                    class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              </template>

              <template v-if="metric.reportingFrequency === 'quarterly'">
                <div
                  v-for="year in metric.selectedYears"
                  :key="year"
                  class="col-span-full"
                >
                  <div
                    v-for="quarter in 4"
                    :key="`${year}-Q${quarter}`"
                    class="relative mb-4"
                  >
                    <label class="block text-sm font-medium text-gray-700 mb-1">
                      {{ year }} - Q{{ quarter }}
                    </label>
                    <textarea
                      v-if="metric.dataType === 'qualitative'"
                      v-model="metric.qualitativeImpacts[`${year}-Q${quarter}`]"
                      placeholder="Enter impact description"
                      class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                    <input
                      v-else
                      type="number"
                      v-model="metric.impacts[`${year}-Q${quarter}`]"
                      placeholder="Enter impact value"
                      class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>

          <h4 class="text-lg font-medium text-gray-900 mb-3 mt-6">
            Projected Impact (Next 3 Years)
          </h4>

          <div class="mb-4">
            <div class="flex flex-wrap gap-2 mb-4">
              <div
                v-for="year in projectedYears"
                :key="year"
                @click="toggleProjectedYear(metric, year)"
                class="px-3 py-1 rounded-full cursor-pointer text-sm"
                :class="
                  metric.selectedProjectedYears.includes(year)
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                "
              >
                {{ year }}
              </div>
            </div>

            <div :class="metric.dataType === 'qualitative' ? 'grid grid-cols-1 gap-4' : 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'">
              <template v-if="metric.reportingFrequency === 'annually'">
                <div
                  v-for="year in metric.selectedProjectedYears"
                  :key="year"
                  class="relative"
                >
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    Projected Impact for {{ year }}
                  </label>
                  <textarea
                    v-if="metric.dataType === 'qualitative'"
                    v-model="metric.qualitativeProjectedImpacts[year]"
                    placeholder="Enter projected description"
                    class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <input
                    v-else
                    type="number"
                    v-model="metric.projectedImpacts[year]"
                    placeholder="Enter projected value"
                    class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              </template>

              <template v-if="metric.reportingFrequency === 'quarterly'">
                <div
                  v-for="year in metric.selectedProjectedYears"
                  :key="year"
                  class="col-span-full"
                >
                  <div
                    v-for="quarter in 4"
                    :key="`${year}-Q${quarter}`"
                    class="relative mb-4"
                  >
                    <label class="block text-sm font-medium text-gray-700 mb-1">
                      {{ year }} - Q{{ quarter }} (Projected)
                    </label>
                    <textarea
                      v-if="metric.dataType === 'qualitative'"
                      v-model="metric.qualitativeProjectedImpacts[`${year}-Q${quarter}`]"
                      placeholder="Enter projected impact description"
                      class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                    <input
                      v-else
                      type="number"
                      v-model="metric.projectedImpacts[`${year}-Q${quarter}`]"
                      placeholder="Enter projected value"
                      class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700 mb-1"
            >Would you like to upload any supporting materials related to this impact metric? (e.g., reports, customer testimonials, case studies)</label>
          <FileInput
            module="IMM_CUSTOM_IMPACT_METRICS"
            accept=".xls,.xlsx,.pdf,.docx,.txt"
            @select-files="(files) => onSelectFiles(index, files)"
          />
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div v-if="pageView" class="flex justify-end">
      <button
        class="bg-blue-500 px-3 py-2 rounded-md text-white font-semibold"
        @click="saveMetrics"
        :disabled="loading"
      >
        {{ loading ? "Submitting..." : "Submit" }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";
import { unSdgs, unTargets } from "@/helpers/impactMetrics";
import FileInput from "./FileInput.vue";


export default {
  emits: ["update:modelValue", "saveComplete", "saveError"],

  data() {
    return {
      filesMap: {},
      metricCount: 1,
      irisCodes: [],
      showIrisCodeResults: false,
      filteredIrisCodes: [],
      unSdgs: unSdgs,
      unTargets: unTargets,
      availableYears: [],
      projectedYears: [],
      loading: false,
    };
  },
  async created() {
    const currentYear = new Date().getFullYear();
    // Past to present years
    this.availableYears = Array.from(
      { length: 6 },
      (_, i) => currentYear - 5 + i
    );
    // Future years (next 3 years)
    this.projectedYears = Array.from(
      { length: 3 },
      (_, i) => currentYear + 1 + i
    );
    this.irisCodes = await this.fetchIrisCodes();
  },
  mounted() {
    this.updateMetrics();
    this.initMetrics();
  },
  methods: {
    newMetric() {
      return {
        irisMetric: "",
        irisCode: [],
        irisCodeDetails: [],
        dataType: "quantitative",
        unSdg: "",
        unTarget: "",
        reportingFrequency: "annually",
        years: "",
        impacts: {},
        qualitativeImpacts: {},
        projectedYears: "",
        projectedImpacts: {},
        qualitativeProjectedImpacts: {},
        selectedYears: [],
        selectedProjectedYears: [],
        filledAfterSurvey: this.investeeUserId ? false : true,
      }
    },
    initMetrics() {
      // init metric defaults in case if the metric was created before the new fields were added
      if (!this.metrics) {
        return;
      }
      this.metrics.forEach((metric) => {
        const emptyMetric = this.newMetric();
        for (let field in emptyMetric) {
          if (metric[field] === undefined) {
            metric[field] = emptyMetric[field];
          }
        }
      });
    },
    updateMetrics() {
      const currentLength = this.metrics.length;
      const newLength = this.metricCount;

      if (newLength > currentLength) {
        for (let i = currentLength; i < newLength; i++) {
          this.metrics.push(this.newMetric());
        }
      } else if (newLength < currentLength) {
        this.metrics.splice(newLength);
      }
    },

    filterSelected(obj, selectedYears, reportingFrequency) {
      // keeps just the selected years in the object
      const filtered = {};
      for (let year of selectedYears) {
        if (reportingFrequency === "quarterly") {
          for (let q = 1; q <= 4; q++) {
            const key = `${year}-Q${q}`;
            filtered[key] = obj[key] ?? null;
          }
        } else {
          filtered[year] = obj[year] ?? null;
        }
      }
      return filtered;
    },

    async saveMetrics() {
      this.loading = true;
      try {
        const url = !this.investeeUserId
          ? `${env.apiUrl}/imm-custom-impact-metrics`
          : `${env.apiUrl}/imm-custom-impact-metrics?investeeUserId=${this.investeeUserId}`;

        this.metrics.forEach((metric) => {
          // ensure we pass either qualitative or quantitative impacts depending on the data type
          if (metric.dataType === "qualitative") {
            metric.impacts = {};
            metric.projectedImpacts = {};
            metric.qualitativeImpacts = this.filterSelected(metric.qualitativeImpacts, metric.selectedYears, metric.reportingFrequency);
            metric.qualitativeProjectedImpacts = this.filterSelected(metric.qualitativeProjectedImpacts, metric.selectedProjectedYears, metric.reportingFrequency);
          } else {
            metric.qualitativeImpacts = {};
            metric.qualitativeProjectedImpacts = {};
            metric.impacts = this.filterSelected(metric.impacts, metric.selectedYears, metric.reportingFrequency);
            metric.projectedImpacts = this.filterSelected(metric.projectedImpacts, metric.selectedProjectedYears, metric.reportingFrequency);
          }

          // this is for the sweef impact survey to check if the custom component was filled after the survey
          // if filled by sweef initially, set to false
          // if filled by the user, set to true
          metric.filledAfterSurvey = this.investeeUserId ? false : true;
        });

        try {
          const response = await axios.post(url, this.metrics, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          });

          await this.uploadFilesMap(this.filesMap);

          // this is for when the component is embedded in the surveys
          this.$emit("update:modelValue", true);
          this.$emit("saveComplete", response.data);

          // this is for when the component atcs like a survey itself
          this.$swal.fire({
            icon: "success",
            text: "Custom metrics saved successfully",
          });
          this.$emit("surveyComplete");

          return response.data;
        } catch (error) {
          console.error("error saving metrics", error);
          this.$emit("update:modelValue", false);
          this.$emit("saveError", error);
          throw error;
        }
      } finally {
        this.loading = false;
      }
    },


    async uploadFilesMap(filesMap) {
      await Promise.all(Object.values(filesMap).map(async (files) => {
        await Promise.all(files.map(async ({file, presigned}) => {
          return this.uploadFile(presigned, file).catch((error) => {
            console.error("error uploading file", error);
            return null;
          })
        }));
      }));
    },

    async uploadFile(presigned, file) {
      if (!presigned) {
        throw new Error(`Error uploading file ${file.name} due to missing pre-signed URL`);
      }
      const response = await axios.put(presigned.url, file, {
        headers: {
          "Content-Type": file.type,
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (!response || response.status !== 200) {
        throw new Error(`Error uploading file ${file.name}`);
      }
      return {
        key: presigned.key, name: presigned.fileName,
      }
    },

    async fetchIrisCodes() {
      try {
        const response = await axios.get(
          `${env.apiUrl}/imm-custom-impact-metrics/airtable`
        );
        return response.data;
      } catch (error) {
        console.error("error fetching iris codes", error);
      }
    },

    searchIrisCodes(event) {
      const search = event.target.value.toLowerCase();
      this.filteredIrisCodes = this.irisCodes.filter(
        (code) =>
          code.irisCode?.toLowerCase().includes(search) ||
          code.irisMetricName?.toLowerCase().includes(search) ||
          code.irisMetricDescription?.toLowerCase().includes(search) ||
          (Array.isArray(code.irisImpactCategories) &&
            code.irisImpactCategories.length > 0 &&
            code.irisImpactCategories.some((category) =>
              category.toLowerCase().includes(search)
            )) ||
          (Array.isArray(code.organizationTypeAsked) &&
            code.organizationTypeAsked.length > 0 &&
            code.organizationTypeAsked.some((type) =>
              type.toLowerCase().includes(search)
            )) ||
          code.irisSection?.toLowerCase().includes(search) ||
          code.irisSubSection?.toLowerCase().includes(search) ||
          code.displayDescription?.toLowerCase().includes(search)
      );
      this.showIrisCodeResults = true;
    },

    selectIrisCode(metric, code) {
      const existingIndex = metric.irisCodeDetails.findIndex(
        (c) => c.irisCode === code.irisCode
      );

      if (existingIndex === -1) {
        metric.irisCode.push(code.irisCode);
        metric.irisCodeDetails.push(code);
      } else {
        metric.irisCode.splice(existingIndex, 1);
        metric.irisCodeDetails.splice(existingIndex, 1);
      }
      this.showIrisCodeResults = false;
    },

    toggleYear(metric, year) {
      const index = metric.selectedYears.indexOf(year);
      if (index === -1) {
        metric.selectedYears.push(year);
        // sort years in ascending order
        metric.selectedYears.sort((a, b) => a - b);
      } else {
        metric.selectedYears.splice(index, 1);
        // remove the impact value when year is deselected
        delete metric.impacts[year];
      }
    },

    toggleProjectedYear(metric, year) {
      const index = metric.selectedProjectedYears.indexOf(year);
      const maxProjectedYears = this.isSweefCompany ? 4 : 3;
      if (index === -1) {
        if (metric.selectedProjectedYears.length < maxProjectedYears) {
          metric.selectedProjectedYears.push(year);
          metric.selectedProjectedYears.sort((a, b) => a - b);
        }
      } else {
        metric.selectedProjectedYears.splice(index, 1);
        delete metric.projectedImpacts[year];
        // also delete quarterly data if exists
        if (metric.reportingFrequency === "quarterly") {
          for (let q = 1; q <= 4; q++) {
            delete metric.projectedImpacts[`${year}-Q${q}`];
          }
        }
      }
    },
    onSelectFiles(index, files) {
      this.filesMap[index] = files;
      this.metrics[index].files = files.map(({ presigned }) => ({
        key: presigned.key, name: presigned.fileName,
      }));
    },
  },

  watch: {
    async saveCustomMetrics(newVal) {
      if (newVal) {
        try {
          const result = await this.saveMetrics();
          this.$emit("saveComplete", result);
        } catch (error) {
          this.$emit("saveError", error);
        }
      }
    },
  },

  props: {
    saveCustomMetrics: {
      type: Boolean,
      default: false,
    },
    investeeUserId: {
      type: String,
      default: "",
    },
    pageView: {
      type: Boolean,
      default: false,
    },
    isSweefCompany: {
      type: Boolean,
      default: false,
    },
    metrics: {
      type: Array,
      default: () => [
        this.newMetric(),
      ],
    },
  },
  components: {
    FileInput,
  }
};
</script>

<style scoped>
.metric {
  margin-bottom: 20px;
}
</style>
