<template>
  <div class="h-full">
    <div class="h-full relative">
      <div class="block lg:hidden">
        <div class="flex justify-end ml-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            @click="handleModal()"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="currentColor"
            class="w-9 h-9 text-white"
          >
            7
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
      <div class="chat-section px-9 pt-9">
        <div class="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-8 h-8 sm:w-6 sm:h-6 text-white"
          >
            <path
              fill-rule="evenodd"
              d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"
              clip-rule="evenodd"
            />
          </svg>
          <h1 class="font-bold ml-2 text-3xl sm:text-xl text-white">
            Chat with Jessie
          </h1>
        </div>
        <div class="text-white mt-3 text-lg sm:text-xs">
          Ask me anything. I'll always respond with a gender lens.
        </div>
        <div class="mx-4 mt-3">
          <div
            class="w-full bg-white py-2 rounded-lg flex items-center justify-center text-[#201747] cursor-pointer"
            @click="handleNewConversation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="4.5"
              stroke="currentColor"
              class="w-8 h-8 sm:w-5 sm:h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            <span class="text-lg sm:text-sm ml-2 font-medium"
            >New conversation</span
            >
          </div>
        </div>
      </div>
      <div class="history-section h-custom-height pt-16 px-2">
        <div class="flex flex-col h-full">
          <h1 class="text-white text-lg sm:text-sm px-9">
            RECENT CONVERSATIONS
          </h1>
          <div class="overflow-auto custom-scrollbar h-full mt-2">
            <div
              class="text-white cursor-pointer"
              v-for="(history, index) in historyList"
              :key="index"
            >
              <div
                :class="{ 'bg-white': selectedChatIndex === history.id }"
                @click="selectChatHistory(history)"
              >
                <div class="px-9 py-3">
                  <div
                    class="text-gray-300 custom-text"
                    :class="{
                      '!text-gray-400': selectedChatIndex === history.id,
                    }"
                  >
                    <span class="text-lg sm:text-xs">{{ history.date }}</span>
                  </div>
                  <div
                    class="font-bold text-lg sm:text-sm whitespace-nowrap overflow-hidden overflow-ellipsis"
                    :class="{
                      'text-[#201747]': selectedChatIndex === history.id,
                    }"
                  >
                    {{
                      history.title.length > 100
                        ? history.title.slice(0, 100) + "..."
                        : history.title
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="resource-section h-44 mb-9 absolute left-0 w-full mt-5">
        <div class="flex justify-center items-center">
          <hr class="w-[80%]" />
        </div>
        <div class="flex flex-col items-start text-white pt-8 px-2">
          <div class="w-full">
            <div
              class="py-2 cursor-pointer hover:bg-white hover:text-[#201747]"
            >
              <button class="flex items-center px-9" @click="$emit('minimize-chat')">
                <commonSvg
                  path1="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                  fill="none"
                  classes="w-7 h-7 sm:w-5 sm:h-5"
                  strokeWidth="2"
                  stroke="currentColor"
                />
                <span class="pl-2 font-semibold text-xl sm:text-sm">
                  Back To Dashboard
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ChatbotAuthorType } from "../../../helpers/commonVars";
import commonSvg from "../common/svg.vue";
import chat from "@/api/chat";

export default {
  name: "sideBar",
  components: {
    commonSvg
  },
  data() {
    return {
      ...ChatbotAuthorType,
      historyList: [],
      helperOptions: [
        {
          id: 1,
          name: "Upload Source",
          icon: {
            path1:
              "M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75",
            class: "w-7 h-7 sm:w-5 sm:h-5",
            fill: "none",
            stroke: "currentColor",
            strokeWidth: "2"
          }
        },
        {
          id: 2,
          name: "Terms and Conditions",
          icon: {
            path1:
              "M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z",
            class: "w-7 h-7 sm:w-5 sm:h-5",
            fill: "none",
            stroke: "currentColor",
            strokeWidth: "2"
          }
        },
        {
          id: 3,
          name: "Help",
          icon: {
            path1:
              "M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z",
            class: "w-7 h-7 sm:w-5 sm:h-5",
            fill: "none",
            stroke: "currentColor",
            strokeWidth: "2"
          }
        }
      ],
      selectedChatIndex: 0,
      selectedUserActionId: 0
    };
  },
  emits: ["selected-chat-history", "new-chat", "close-modal", "minimize-chat"],
  methods: {
    async selectChatHistory(history) {
      this.$emit("close-modal");
      this.selectedChatIndex = history.id;
      const isActive = this.historyList.length > 0 && history.id === this.historyList[0].id
      const response = await chat.getChatHistoryDetails(history.id)
      console.log('fetched history details', response.data);
      this.$emit("selected-chat-history", [response.data, isActive]);
      this.selectedUserActionId = 0;
    },
    handleNewConversation() {
      this.$emit("close-modal");
      this.selectedChatIndex = 0;
      this.selectedUserActionId = 0;
      this.$emit("new-chat");
      this.isNewConversation = true;
    },
    handleModal() {
      this.$emit("close-modal");
    },
    addNewMessageToHistory(userMessage, response) {
      if (this.historyList.length > 0 && response.conversationId !== this.historyList[0].id) {
        this.historyList.unshift({
          id: response.conversationId,
          date: new Date(Date.parse(response.conversationDate)).toDateString() ,
          title: response.response,
          messages: [{
            chatbotResponse: response.response,
            userMessage:userMessage,
          }]
        })
      } else {
        if (!this.historyList[0].messages) {
          this.historyList[0].messages = []
        }
        this.historyList[0].messages.push({
          chatbotResponse: response.response,
          userMessage:userMessage,
        })
      }
    }
  },
  async mounted() {
    let response = await chat.getChatHistoryTitles();
    this.historyList = response.data.chatHistory
      .sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
      .map((conversation) => {
        return {
          id: conversation._id,
          date: new Date(Date.parse(conversation.createdAt)).toDateString(),
          title: (
            conversation.messages && conversation.messages.length > 0 ? conversation.messages[0].userMessage : null
          ) || "New Conversation",
        };
      });
    this.selectChatHistory(this.historyList[0]);
  }
};
</script>
<style scoped>
.h-custom-height {
  height: calc(100vh - (9rem + 18rem)) !important;
}

.custom-text {
  font-size: x-small;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #e4e4e4;
}

@media (max-width: 1024px) {
  .h-custom-height {
    height: calc(100vh - (9rem + 22rem)) !important;
  }
}

@media (max-width: 640px) {
  .h-custom-height {
    height: calc(100vh - (9rem + 30rem)) !important;
  }
}
</style>
