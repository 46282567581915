<template>
  <div class="mt-4">
    <div
      class="file-upload-container p-5 text-center group border border-dashed text-sm"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
      :class="{ 'border-dark-blue': hovered, 'border-light-blue': !hovered }"
    >
    <label class="block cursor-pointer pointer-events-none">
        <input
          ref="file"
          class="hidden"
          type="file"
          title="Upload"
          multiple
          :accept="accept"
          @change="handleFileChange"
        />
        <p class="center-span-modal">
          <span v-if="hovered"> Drop files here </span>
          <span v-else>
            Drag and drop files here or
            <a class="underline pointer-events-auto">click to select</a>.
            <br />
            Accepted formats: {{ accept }}, {{ maxSizeMb }}Mb max size.
          </span>
        </p>
      </label>
    </div>
    <div v-if="files.length > 0" class="flex flex-wrap gap-1 mt-4">
      <span class="py-1 pr-4 text-sm text-gray-700"> Selected files: </span>
      <span
        v-for="file in files"
        :key="file.name"
        class="bg-blue-500 text-white px-2 py-1 rounded-full border-0 text-sm"
      >
        {{ file.name }}
        <img
          src="@/assets/images/trash-white-icon.svg"
          alt="background-image"
          class="w-5 h-5 cursor-pointer"
          @click="files = files.filter((f) => f.name !== file.name)"
        />
      </span>
    </div>
  </div>
</template>
<script>
import { apiExecute } from '../../../helpers/api';

export default {
  props: {
    module: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
    },
    maxSizeMb: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      hovered: false,
      files: [],
    };
  },
  methods: {
    dragover(event) {
      event.preventDefault();
      this.hovered = true;
    },
    dragleave(event) {
      event.preventDefault();
      this.hovered = false;
    },
    async drop(event) {
      this.hovered = false;
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      const [files, errors] = this.validateFiles(event.dataTransfer.files);
      this.showErrors(errors);
      this.appendOrReplaceFiles(files);
      this.$emit("select-files", await this.presignFiles());
    },
    async handleFileChange(event) {
      const [files, errors] = this.validateFiles(event.target.files);
      this.showErrors(errors);
      this.appendOrReplaceFiles(files);
      this.$emit("select-files", await this.presignFiles());
    },
    async presignFiles() {
      const fileNames = this.files.map((file) => file.name);
      const presignedUrls = await this.createPresignedUrls(fileNames);
      return this.files.map((file) => ({
        file,
        presigned: presignedUrls.find((url) => url.fileName === file.name),
      }))
    },
    showErrors(errors) {
      const uniqueErrors = [...new Set(errors.map((error) => error[1].message))].join(" ");
      if (uniqueErrors) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: `${errors.length} ${errors.length > 1 ? 'files' : 'file'} cannot be uploaded due to errors: ${uniqueErrors}`,
        });
      }
    },
    appendOrReplaceFiles(files) {
      this.files = files.reduce((acc, file) => {
        if (!this.files.some((f) => f.name === file.name)) {
          acc.push(file);
        } else {
          acc.map((f) => (f.name === file.name ? file : f));
        }
        return acc;
      }, this.files);
    },
    validateFiles(files) {
      const errors = [];
      const validFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          this.validateOrRaise(file);
          validFiles.push(file);
        } catch (error) {
          errors.push([file.name, error]);
        }
      }
      return [validFiles, errors];
    },
    validateOrRaise(file) {
      if (file.size > this.maxSizeMb * 1024 * 1024) {
        throw new Error(`File size exceeds ${this.maxSizeMb}MB.`);
      }
      const acceptedExtensions = this.accept
        .split(",")
        .map((ext) => ext.trim())
        .filter((ext) => ext != "");
      const fileExt = file.name.split(".").pop();
      if (
        !acceptedExtensions.includes(file.type) &&
        !acceptedExtensions.includes("." + fileExt)
      ) {
        throw new Error(`File type not accepted.`);
      }
      return true;
    },
    async createPresignedUrls(fileNames) {
      if (!fileNames || fileNames.length === 0) {
        return [];
      }
      const input = {
        files: fileNames,
        urlType: "upload",
        module: this.module,
      };

      const response = await apiExecute("post", "/other/presignedUrl", input);
      return response.data;
    },
  },
  emits: ["select-files"],
};
</script>

<style lang="scss" scoped>
.file-upload-container {
  border-radius: 16px;
  padding: 32px, 0px, 32px, 0px;
  background: #eaf6ff;
  height: 100px;
  // center the child vertically
  display: flex;
  justify-content: center;
  align-items: center;

  .center-span-modal {
    line-height: 20px;
    font-family: arial;
    color: #191b45;
  }
}
</style>
