<template>
  <button class="group" @click="$emit('click', like === null ? true : !like)">
    <svg
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h48v48h-48z" fill="none" />
      <path
        class="fill-[rgba(0,0,0,0)] stroke-2 stroke-[#201747] group-hover:stroke-[#0077c8]"
        :class="{
          'fill-[#201747]': like,
          'group-hover:stroke-[#201747]': like,
        }"
        d="M2
      42h8v-24h-8v24zm44-22c0-2.21-1.79-4-4-4h-12.63l1.91-9.14c.04-.2.07-.41.07-.63
      0-.83-.34-1.58-.88-2.12l-2.13-2.11-13.17 13.17c-.72.73-1.17 1.73-1.17
      2.83v20c0 2.21 1.79 4 4 4h18c1.66 0 3.08-1.01
      3.68-2.44l6.03-14.1c.18-.46.29-.95.29-1.46v-3.83l-.02-.02.02-.15z"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "LikeButton",
  props: {
    like: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  emits: ["click"],
};
</script>
<style scoped>
svg {
  width: 16px;
  height: 16px;
}
</style>
