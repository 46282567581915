<template>
  <button
    class="tooltip px-1 py-1 bg-gray-100 hover:bg-white flex flex-row items-center focus:shadow-outline-indigo justify-center rounded-lg transition duration-200 shadow border border-gray-300 focus:outline-none ml-2"
    v-if="isChatFullscreen"
    @click="$emit('minimize-chat')"
  >
    <span class="lang-tooltiptext notranslate w-[150px]"
      >Back to Dashboard</span
    >
    <div class="rounded-full h-7 w-7 sm:h-5 sm:w-5 overflow-hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2.5"
        stroke="currentColor"
        class="w-7 h-7 sm:w-5 sm:h-5 text-[#201747]"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
        />
      </svg>
    </div>
  </button>
</template>
<script>
export default {
  name: "chatBackToDashButton",
  emits: ["minimize-chat"],
  props: {
    isChatFullscreen: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 62px;
  top: -45px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  right: 95%;
}

.tooltip .lang-tooltiptext {
  visibility: hidden;
  /* width: 180px; */
  background-color: #6b787f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 20px;
  position: absolute;
  z-index: 9999;
  top: 34px;
  left: -34px;
}

.tooltip:hover .lang-tooltiptext {
  visibility: visible;
}

.tooltip .lang-tooltiptext::after {
  right: 95%;
}
</style>