var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.noInfo)?_c('div',{staticClass:"spiderchartContent",attrs:{"id":"chart"}},[_c('div',{staticClass:"z-20 relative"},[_c('SpiderchartGoverance',{key:_vm.govData,staticClass:"z-10 overflow-visible",staticStyle:{"position":"relative","height":"100%","width":"100%"},attrs:{"paddingL":40,"paddingR":40,"paddingT":40,"paddingB":40,"governanceColors":_vm.governanceColors,"chartData":_vm.govChartData,"assessmentYear":_vm.assessmentYear}}),(
        this.labels && Array.isArray(this.labels) && this.labels.length > 0
      )?_c('div',{staticClass:"flex items-center",style:('color: ' +
        _vm.governanceColors[0] +
        '; position: absolute; left: 35%; top: 5%;')},[_c('svg',{attrs:{"width":"26","height":"24","viewBox":"0 0 30 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.labels[0]))])]):_vm._e(),(
        this.labels && Array.isArray(this.labels) && this.labels.length > 1
      )?_c('div',{staticClass:"flex items-center",style:('color: ' +
        _vm.governanceColors[1] +
        '; position: absolute; left: 71%; top: 62%;')},[_c('svg',{attrs:{"width":"26","height":"24","viewBox":"0 0 30 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}}),_c('span',{staticClass:"pt-5",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.labels[1]))])]):_vm._e(),(
        this.labels && Array.isArray(this.labels) && this.labels.length > 2
      )?_c('div',{staticClass:"flex items-center",style:('color: ' +
        _vm.governanceColors[2] +
        '; position: absolute; right: 80%; top: 66%;')},[_c('svg',{attrs:{"width":"26","height":"24","viewBox":"0 0 30 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.labels[2]))])]):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }