<template>
  <div class="flex flex-col">
    <div class="flex">
      <div class="flex flex-col" style="flex: 0 0 21%">
        <div
          class="flex items-center h-[6rem]"
          v-for="(item, index) in chartData"
          :key="item.sub"
        >
          <progress-bar
            :options="progressData(index, Math.floor(item.value))"
            :value="Math.floor(item.value)"
            style="width: 60px; height: 60px; line-height: 0"
          />
          <div class="pl-5 font-bold text-sm" :style="{ color: item.color }">
            <p>{{ item.sub }}</p>
          </div>
        </div>
      </div>
      <spiderChart-multiple
        v-if="oldCertificationScoreData"
        class="z-10 overflow-visible"
        style="height: 100%; width: 100%"
        :paddingL="paddingL"
        :paddingR="paddingR"
        :paddingT="paddingT"
        :paddingB="paddingB"
        :info="chartData"
        :certificationScoreData="certificationScoreData"
        :oldInfo="oldChartData"
        :oldCertificationScoreData="oldCertificationScoreData"
        :assessmentYear="[assessmentYear, oldAssessmentYear]"
        :key="chartData?.length+'multiple'"
      />
      <spiderChart
        v-else
        class="z-10 overflow-visible"
        style="height: 100%; width: 100%"
        :paddingL="paddingL"
        :paddingR="paddingR"
        :paddingT="paddingT"
        :paddingB="paddingB"
        :info="chartData"
        :certificationScoreData="certificationScoreData"
        :assessmentYear="assessmentYear"
        :key="chartData?.length+'single'"
      />
    </div>
    <div class="flex mt-[-5%] mb-[2.5rem]" v-if="!oldCertificationScoreData">
      <div style="flex: 0 0 21%"></div>
      <div
        class="flex gap-1 text-sm font-normal items-center justify-center"
        style="flex: 1 0 0"
      >
        <div style="width: 24px; height: 4px; background: #dda63a"></div>
        {{ assessmentYear }}
      </div>
    </div>
  </div>
</template>

<script>
import spiderChart from "./spiderChartMod.vue";
import spiderChartMultiple from "./spiderChartModMultiple.vue";

export default {
  name: "TriangleChart",
  props: [
    "paddingL",
    "paddingR",
    "paddingT",
    "paddingB",
    "info",
    "certificationScoreData",
    "oldInfo",
    "oldCertificationScoreData",
    "assessmentYear",
    "oldAssessmentYear",
  ],
  components: {
    spiderChart,
    spiderChartMultiple
  },
  data() {
    return {
      triangleColors: ["#3F7E44", "#395AFF", "#FF3A21"],
      chartData: [],
      oldChartData: [],
    };
  },
  mounted() {
    const triangle = this.info;
    for (let i = 0; i < triangle.length; i++) {
      triangle[i].color = this.triangleColors[i];
    }
    this.chartData = triangle;

    if(this.oldCertificationScoreData) {
      const oldTriangle = this.oldInfo;
      for (let i = 0; i < oldTriangle?.length; i++) {
        oldTriangle[i].color = this.triangleColors[i];
      }
      this.oldChartData = oldTriangle;
    }
  },
  methods: {
    progressData(index, value) {
      return {
        text: {
          color: this.triangleColors[index],
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 12,
          fontFamily: "Montserrat",
          dynamicPosition: false,
          hideText: false,
          fontWeight: "bold",
        },
        progress: {
          color: this.triangleColors[index],
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 60,
          width: 60,
          verticalTextAlign: 30,
          horizontalTextAlign: value < 10 ? 20 : 16,
          zeroOffset: 0,
          strokeWidth: 12,
          progressPadding: 2,
          type: "circle",
        },
      };
    },
  },
};
</script>
