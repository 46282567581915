<template>
  <div class="spiderchartContent" id="chart">
    <div class="z-20 relative">
      <spiderchart-multiple
        v-if="oldCertificationScoreData"
        :paddingL="40"
        :paddingR="40"
        :paddingT="40"
        :paddingB="40"
        :entityType="entityChosen"
        :chartData="[domainChartData, oldDomainChartData]"
        :srManagerThreshold="srManagerThreshold"
        :workforceThreshold="workforceThreshold"
        :boardThreshold="boardThreshold"
        :assessmentYear="[assessmentYear, oldAssessmentYear]"
        class="z-10 overflow-visible"
        :key="supplyChainPercent"
        style="position: relative; height: 100%; width: 100%"
      >
      </spiderchart-multiple>
      <spiderchart
        v-else
        :paddingL="40"
        :paddingR="40"
        :paddingT="40"
        :paddingB="40"
        :entityType="entityChosen"
        :chartData="domainChartData"
        :srManagerThreshold="srManagerThreshold"
        :workforceThreshold="workforceThreshold"
        :boardThreshold="boardThreshold"
        :assessmentYear="assessmentYear"
        class="z-10 overflow-visible"
        :key="'latest'+supplyChainPercent"
        style="position: relative; height: 100%; width: 100%"
      >
      </spiderchart>
      <div v-if="!$store.state.certPageAccess.isVerificationRecommendsCertification && ($route.path.includes('IndicativeResults') || $route.path.includes('2X-Challenge-Self-Assessment-Results'))" class="absolute inset-0 -z-10">
      <p class="text-gray-300 text-[2.6rem] mt-36 ml-52 text-center max-w-sm font-medium">Pending Verification and Certification</p>
    </div>
      <div
        class="flex items-center"
        :class="
          isPortfolioVisible && (entityChosen == 'fund-manager' || entityChosen == 'financial-institution')
            ? 'entrepreneurship-fund'
            : 'entrepreneurship-company'
        "
        :style="'color: ' + dimensions.Entrepreneurship.color"
      >
        <img src="@/assets/images/2x_certify/2x_challenge/entrepreneurship.svg" />
        <span style="font-weight: bold">Entrepreneurship</span>
      </div>
      <div
        class="flex items-center"
        :class="
          isPortfolioVisible && (entityChosen == 'fund-manager' || entityChosen == 'financial-institution')
            ? 'leadership-fund'
            : 'leadership-company'
        "
        :style="'color: ' + dimensions.Leadership.color"
      >
        <img src="@/assets/images/2x_certify/leadership.svg" />
        <span style="font-weight: bold">Leadership</span>
      </div>
      <div
        class="flex items-center"
        :class="
          isPortfolioVisible && (entityChosen == 'fund-manager' || entityChosen == 'financial-institution')
            ? 'employment-fund'
            : 'employment-company'
        "
        :style="'color: ' + dimensions.Employment.color"
      >
        <img src="@/assets/images/2x_certify/employment.svg" />
        <span style="font-weight: bold">Employment</span>
      </div>
      <div
        class="flex items-center"
        :class="
          isPortfolioVisible && (entityChosen == 'fund-manager' || entityChosen == 'financial-institution')
            ? 'product-service-fund'
            : 'product-service-company'
        "
        :style="'color: ' + dimensions.Products.color"
      >
        <img src="@/assets/images/2x_certify/2x_challenge/products.svg" />
        <span style="font-weight: bold">Products & Services</span>
      </div>
      <div
        class="flex items-center"
        :class="
          isPortfolioVisible && (entityChosen == 'fund-manager' || entityChosen == 'financial-institution')
            ? 'supplychain-fund'
            : 'supplychain-company'
        "
        :style="'color: ' + dimensions.SupplyChain.color"
      >
        <img src="@/assets/images/2x_certify/2x_challenge/supplychain.svg" />
        <span style="font-weight: bold">Supply Chain</span>
      </div>
      <div
        class="flex items-center"
        v-if="isPortfolioVisible && (entityChosen == 'fund-manager' || entityChosen == 'financial-institution')"
        :style="
          'color: ' +
          dimensions.Portfolio.color +
          '; position: absolute; right: 77%; top: 25%;'
        "
      >
        <img src="@/assets/images/2x_certify/portfolio.svg" />
        <span style="font-weight: bold">Portfolio</span>
      </div>
    </div>
  </div>
</template>
<script>
import spiderchart from "./../charts/my2xCertification-SpiderChart.vue";
import spiderchartMultiple from "./../charts/my2xCertification-SpiderChartMultiple.vue";

export default {
  props: {
    dimensions: {
      type: [Object, Array],
    },
    srManagerThreshold: {
      type: [String, Number],
    },
    entityChosen: {
      type: [String],
    },
    workforceThreshold: {
      type: [String, Number],
    },
    boardThreshold: {
      type: [String, Number],
    },
    domainChartData: {
      type: [Array, Object],
    },
    assessmentYear: {
      type: [Number, String],
    },
    supplyChainPercent: {
      type: [String, Number],
    },
    isPortfolioVisible: {
      type: [Boolean],
    },
    oldCertificationScoreData: {
      type: [Array, Object],
    },
    oldDomainChartData: {
      type: [Array, Object, String],
    },
    oldAssessmentYear: {
      type: [Number, String],
    },
  },
  components: {
    spiderchart,
    spiderchartMultiple
  },
}
</script>
<style lang="scss" scoped>
.spiderchartContent {
      width: 70%;
      .entrepreneurship-fund {
        position: absolute;
        left: 32%;
        top: 5%;
      }
      .entrepreneurship-company {
        position: absolute;
        left: 35%;
        top: 5%;
      }
      .leadership-fund {
        position: absolute;
        left: 68%;
        top: 25%;
      }
      .leadership-company {
        position: absolute;
        left: 68%;
        top: 30%;
      }
      .employment-fund {
        position: absolute;
        left: 68%;
        top: 58%;
      }
      .employment-company {
        position: absolute;
        left: 60%;
        bottom: 20%;
      }
      .product-service-fund {
        position: absolute;
        left: 31%;
        top: 79%;
      }
      .product-service-company {
        position: absolute;
        left: 10%;
        bottom: 20%;
      }
      .supplychain-fund {
        position: absolute;
        right: 77%;
        top: 58%;
      }
      .supplychain-company {
        position: absolute;
        right: 76%;
        top: 30%;
      }
    }
</style>
